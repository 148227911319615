import React, { Component } from "react";
import { googlePlaceDetailsRequestForPhotos } from "../../../apiRequests/googlePlaceDetailsRequest";
import { Draggable } from "react-beautiful-dnd";
import "rc-time-picker/assets/index.css";
import "./placeCell.css";
import { NavLink } from "react-router-dom";
import { ReactComponent as EditSVG } from "./../../../assets/edit-24px.svg";
import { ReactComponent as DestinationSVG } from "./../../../assets/icons/location_on_black_24dp.svg";
import defaultTripImage from "../../../assets/defaultTripImage.jpeg";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import EditPlaceModal from "./editPlaceModal";
import NotesBlock from "./notesBlock";
import { getBestAspectPhoto } from "../../../utils/photo-utils";

const PHOTO_ASPECT_RATIO = 1.3;

class PlaceCell extends Component {
  state = {
    photoURL: undefined,
    placeDetails: undefined,
    name: undefined,
    queryTries: 0,
    showModal: false,
  };

  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onMouseEnter() {
    this.props.onMouseEnter(this.props.placeId);
  }

  onMouseLeave() {
    this.props.onMouseLeave(this.props.placeId);
  }

  onClick() {
    this.props.onClick(this.props.placeId, this.state.placeDetails);
  }

  placeDetailsCallback = (place, status) => {
    if (status == "OVER_QUERY_LIMIT" && this.state.queryTries < 4) {
      // retry after 2 seconds to see if it's just the short term query limit
      this.setState({ queryTries: this.state.queryTries + 1 });
      setTimeout(() => {
        console.log("retrying placeDetailsRequest in placeCell");
        googlePlaceDetailsRequestForPhotos(
          this.props.placeId,
          this.placeDetailsCallback,
        );
      }, 3000);
    } else if (place) {
      if (place.photos) {
        const photoURL = getBestAspectPhoto(
          place.photos,
          PHOTO_ASPECT_RATIO,
        ).getUrl();
        this.setState({
          photoURL: photoURL,
        });
        this.props.updatePlaceClicked(this.props.hash, this.props.notes, null, photoURL);
      }
    }
  };

  getTime = (date) => {
    if (date.getUTCMinutes() == 0) {
      if (date.getUTCHours() == 0) {
        return "12 AM";
      }
      if (date.getUTCHours() < 12) {
        return date.getUTCHours() + " AM";
      }
      if (date.getUTCHours() == 12) {
        return "12 PM";
      }
      return date.getUTCHours() - 12 + " PM";
    }
    if (date.getUTCMinutes() < 10) {
      if (date.getUTCHours() == 0) {
        return "12:0" + date.getUTCMinutes() + " AM";
      }
      if (date.getUTCHours() < 12) {
        return date.getUTCHours() + ":0" + date.getUTCMinutes() + " AM";
      }
      if (date.getUTCHours() == 12) {
        return "12:0" + date.getUTCMinutes() + " PM";
      }
      return date.getUTCHours() - 12 + ":0" + date.getUTCMinutes() + " PM";
    }
    if (date.getUTCHours() == 0) {
      return "12:" + date.getUTCMinutes() + " AM";
    }
    if (date.getUTCHours() < 12) {
      return date.getUTCHours() + ":" + date.getUTCMinutes() + " AM";
    }
    if (date.getUTCHours() == 12) {
      return "12:" + date.getUTCMinutes() + " PM";
    }
    return date.getUTCHours() - 12 + ":" + date.getUTCMinutes() + " PM";
  };

  onTimeChange = (value) => {
    this.props.setPlaceTime(this.props.index, value);
  };

  editButtonClicked = () => {
    this.setState({ showModal: true });
  };

  updatePlaceClicked = (notes, date) => {
    this.setState({ showModal: false });
    this.props.updatePlaceClicked(this.props.hash, notes, date);
  };

  componentDidMount() {
    if (this.props.placeId) {
      let now = new Date();
      const twoDaysFromNow = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000);
      if (!this.props.placePhoto?.photoURL || (this.props.placePhoto?.expirationDate && (this.props.placePhoto?.expirationDate.toDate() < now || this.props.placePhoto.expirationDate.toDate() > twoDaysFromNow))) {
        console.log('fetching photoURL in placeCell for placeId: ', this.props.placeId);
        googlePlaceDetailsRequestForPhotos(this.props.placeId, this.placeDetailsCallback);
      } else {
        console.log('using cached photoURL for placeId: ', this.props.placeId);
        this.setState({ photoURL: this.props.placePhoto?.photoURL });
      }
    }
  }

  render() {
    return (
      <>
        <EditPlaceModal
          show={this.state.showModal}
          onHide={() =>
            this.setState({
              showModal: false,
            })
          }
          name={this.props.name}
          deletePlaceClicked={() =>
            this.props.deletePlaceClicked(this.props.hash)
          }
          savePlace={this.updatePlaceClicked}
          onTimeChange={this.onTimeChange}
          time={this.props.time}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          date={this.props.date}
          notes={this.props.notes}
          currentAuthUserId={this.props.currentAuthUserId}
        />
        <Draggable
          draggableId={this.props.hash}
          index={this.props.index}
          isDragDisabled={
            this.props.hideEdit || !this.props.isCurrentUserOnTrip
          }
        >
          {(provided) => (
            <div
              className={
                this.props.hoveredCell == this.props.placeId
                  ? "placeCell placeCell-hover"
                  : "placeCell"
              }
              scope="row"
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className="PlaceCell--row">
                <span className="" onClick={this.onClick}>
                  <div style={{}}>
                    <img
                      className="card-img"
                      src={
                        this.state.photoURL
                          ? this.state.photoURL
                          : defaultTripImage
                      }
                      alt="Card image"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                </span>
                <span className="col-7" onClick={this.onClick}>
                  {this.props.name} <br />
                  {this.props.type && (
                    <div
                      style={{
                        color: "grey",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {this.props.type.replace(/_/g, " ")}
                    </div>
                  )}
                  {this.props.time &&
                    typeof this.props.time.toDate === "function" && (
                      <div
                        className="PlaceCell--time"
                        onClick={this.editButtonClicked}
                      >
                        <DefaultButton onClick={this.editButtonClicked}>
                          <DestinationSVG className="editSVG" />
                          {" " + this.getTime(this.props.time?.toDate())}
                        </DefaultButton>
                      </div>
                    )}
                </span>
                <div className="" style={{ fontSize: "14px" }}>
                  {!this.props.hideEdit && this.props.isCurrentUserOnTrip && (
                    <DefaultButton onClick={this.editButtonClicked}>
                      <EditSVG className="editSVG editButtonContainer" />
                    </DefaultButton>
                  )}
                </div>
              </div>
              {this.props.creatorName && (
                <NavLink
                  to={"/user/" + this.props.creatorId}
                  style={{ paddingTop: "8px" }}
                >
                  <div className="searchTripCardSubtitle">
                    added by {this.props.creatorName}
                  </div>
                </NavLink>
              )}
              {this.props.description && (
                <div className="PlaceCell--description">
                  {this.props.description}
                </div>
              )}
              {Object.entries(this.props.notes).map(([userId, note]) => (
                <NotesBlock userId={userId} note={note} />
              ))}
            </div>
          )}
        </Draggable>
      </>
    );
  }
}

export default PlaceCell;
