import React from "react";
import { NavLink } from "react-router-dom";
import './notificationsItem.css';
import clsx from 'clsx';

const NotificationsItem = ({
  currentUserData = { notifications: [] },
  isOpen = false,
  onMouseEnter = {},
  onMouseLeave = {},
  onClick = {},
  notificationClicked,
  notificationAccepted,
  notificationDeleted,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{display: 'inline-block'}}
    >
      <svg
        className="bi bi-bell"
        width="32"
        height="32"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "2px 20px" }}
        className="notificationsBell"
        className={clsx(
          "notificationsBell",
          isOpen && "notificationsBell--isOpen",
        )}
      >
        <path d="M10 18a2 2 0 002-2H8a2 2 0 002 2z" />
        <path
          fillRule="evenodd"
          d="M10 3.918l-.797.161A4.002 4.002 0 006 8c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C14.134 10.197 14 8.628 14 8a4.002 4.002 0 00-3.203-3.92L10 3.917zM16.22 14c.223.447.482.801.78 1H3c.299-.199.557-.553.78-1C4.68 12.2 5 8.88 5 8c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0115 8c0 .88.32 4.2 1.22 6z"
          clipRule="evenodd"
        />
      </svg>
      <span
        className="badge badge-pill badge-success"
        style={{
          position: "absolute",
          textAlign: "left",
          marginLeft: "-14px",
          marginTop: "10px",
        }}
      >
        { currentUserData?.unseenNotificationCount > 0 &&
          currentUserData.unseenNotificationCount}
      </span>
      {isOpen && (
        <div
         className="notificationsDropdown"
        >
          <h4 className='notificationsHeader'>Notifications</h4>
          {currentUserData?.notifications && 
          currentUserData?.notifications?.[0] &&
            currentUserData.notifications.map((notification, index) => (
              <NavLink
                to={notification.link}
                className="dropdown-item"
                key={index}
                onClick={() => notificationClicked()}
                style={{
                  borderWidth: "1px",
                  borderColor: "lightgrey",
                  whiteSpace: "normal",
                }}
              >
                {notification?.text}
                {notification?.requiresResponse && (
                  <div>
                    <button
                      style={{ zIndex: "1000" }}
                      onClick={() => notificationAccepted(notification)}
                      className="btn btn-success btn-sm"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => notificationDeleted(notification)}
                      className="btn btn-light btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </NavLink>
            ))}
          {!currentUserData.notifications[0] && (
            <div>You have no new notifications!</div>
          )}
        </div>
      )}
    </div>
  );
};
NotificationsItem.defaultProps = {
  currentUserData: {
    notifications: [],
  },
};
export default NotificationsItem;
