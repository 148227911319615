import React from "react";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import PlaceCell from "../placeCell/placeCell";
import SavedPlaceCell from "../savedPlaceCell";
import "./savedPlacesList.css";
import { Droppable } from "react-beautiful-dnd";

const UnscheduledList = (props) => {
  const onMouseEnter = (placeId) => {
    props.onCellMouseEnter(placeId);
  };

  const onMouseLeave = (placeId) => {
    props.onCellMouseLeave(placeId);
  };

  return (
    <div className="SavedPlacesList" >
      <h5>Unscheduled</h5>
      <Droppable  droppableId={'Unscheduled'}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{minHeight:"46px"}}
          >
            {props.savedPlaces?.map((place, index) => (
              <div key={place.locationData?.placeId}>
                <PlaceCell
                  key={place.key}
                  hash={place.key}
                  placeId={place.locationData?.placeId}
                  index={index}
                  notes={place.notes}
                  type={place.type}
                  name={place.locationData?.name}
                  className="container"
                  address={place.locationData?.address}
                  onMouseEnter={onMouseEnter}
                  description={place.description}
                  onMouseLeave={onMouseLeave}
                  onClick={props.onCellClick}
                  hoveredCell={props.hoveredCell}
                  creatorName={place.creatorName}
                  creatorId={place.creatorId}
                  updatePlaceClicked={props.updatePlaceClicked}
                  deletePlaceClicked={props.deletePlaceClicked}
                  currentAuthUserId={props.currentAuthUserId}
                  isCurrentUserOnTrip={props.isCurrentUserOnTrip}
                  startDate={props.startDate}
                  endDate={props.endDate}
                ></PlaceCell>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default UnscheduledList;
