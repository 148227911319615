import React, { Component } from "react";
import "./profileCard.css";
import { NavLink } from "react-router-dom";
import { getProfileImage, getUserProfile } from "../login/util";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { addFollower, removeFollower } from "./util";
import { addNotificationToUser } from "../../apiRequests/firestoreRequests/addNotificationToUser";
import { auth } from "../../index";

class ProfileCard extends Component {
  state = {
    profileImage: defaultProfPic,
    user: {},
    userId: "",
    currentUser: null,
    currentUserUid: null,
  };

  setStateCallback = (url) => {
    this.setState({ profileImage: url });
  };

  componentDidMount() {
    getUserProfile(
      this.props.user ? this.props.user.uid : this.props.userId
    ).then((user) => {
      getProfileImage(user, this.setStateCallback);
      this.setState({
        user: user,
        userId: this.props.user ? this.props.user.uid : this.props.userId,
      });
    });
    // fetch the current user for the follower button
    if (auth.currentUser) {
      getUserProfile(auth.currentUser?.uid).then((currentUser) => {
        this.setState({
          currentUser: currentUser,
          currentUserUid: auth.currentUser.uid,
        });
      });
    }
  }

  followButtonClicked = async () => {
    if (this.state.currentUser) {
      // then unfollow
      if (this.state.currentUser?.following?.includes(this.state.userId)) {
        const index = this.state.currentUser.following.indexOf(
          this.props.user.uid
        );
        let newFollowingArray = this.state.currentUser.following;
        newFollowingArray.splice(index, 1);

        const userRef = firebase.firestore().collection('userProfiles').doc(this.state.currentUserUid);
        await userRef.update({
          ...this.state.currentUser,
          following: newFollowingArray,
        });
        
        // removes uid from follower array
        removeFollower(this.props.user.uid);
        // make sure state reflects new state
        getUserProfile(auth.currentUser?.uid).then((currentUser) => {
          this.setState({
            currentUser: currentUser,
          });
        });
      } else {
        // follow them
        // add the uid to the currentUser's following list
        const userRef = firebase.firestore().collection('userProfiles').doc(this.state.currentUserUid);
        
        if (this.state.currentUser?.following) {
          await userRef.update({
            ...this.state.currentUser,
            following: [
              ...this.state.currentUser.following,
              this.props.user.uid,
            ],
          });
        } else {
          await userRef.update({
            ...this.state.currentUser,
            following: [this.props.user.uid],
          });
        }
        // adds uid to follower array
        addFollower(this.props.user.uid);

        // send notification to followed user
        const notificationData = {
          text: this.state.currentUser.username + " followed you",
          link: "/user/" + this.state.currentUserUid,
          seen: false,
        };
        addNotificationToUser(this.props.user.uid, notificationData);

        // make sure state reflects new state
        getUserProfile(auth.currentUser?.uid).then((currentUser) => {
          this.setState({
            currentUser: currentUser,
          });
        });
      }
    } else {
      // TODO: Prompt user to sign in/sign up
    }
  };

  render() {
    return (
      <div className="profile-card-container">
        <NavLink
          className="profileCardWrapper"
          to={"/user/" + this.state.userId}
        >
          <div style={{ paddingRight: "4%" }}>
            <img
              alt="profile picture"
              src={this.state.profileImage}
              style={{ height: "50px" }}
              className="rounded-circle"
            />
          </div>

          <div
            style={{
              color: "black",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {this.state.user?.username || this.props.user?.username}
          </div>
        </NavLink>
        {this.state.currentUserUid &&
          this.state.currentUserUid !== this.props.user?.uid &&
          this.state.currentUserUid !== this.props.userId && (
            <div className="follow-button-container">
              <button
                className="follow-button"
                onClick={this.followButtonClicked}
              >
                {this.state.currentUser?.following?.includes(this.state.userId)
                  ? "Following"
                  : "Follow"}
              </button>
            </div>
          )}
      </div>
    );
  }
}

export default ProfileCard;
