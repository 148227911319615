import React, { Component } from "react";
import FollowListModal from "../../profileContainer/followListModal";
import { withRouter } from "react-router-dom";
import firebase from "firebase/compat/app";
import { doc, FieldValue } from "firebase/firestore";
import { addNotificationToUser } from "../../../apiRequests/firestoreRequests/addNotificationToUser";
import ProfileCard from "../../profileContainer/profileCard";
import LikesItem from "./likesItem";
import "./tripHeader.css";
import SmallUserLink from "../../sharedComponents/userLink/smallUserLink";
import ShareTripButton from "./shareTripButton";
import ShareTripModal from "./shareTripModal";
import { auth, functions } from "../../../index";
import { httpsCallable } from "firebase/functions";

class TripHeader extends Component {
  state = {
    showFollowListModal: false,
    sharing: false,
    shareSearchResults: [],
    shareSearchValue: "",
    sharedWith: [],
    tripperList: [],
    copyToClipboardText: "Copy shareable link to keyboard",
    showCopyToClipboardDropdown: true,
    askToJoinTripText: "Ask to Join Trip",
    showShareTripModal: false,
  };

  constructor(props) {
    super(props);
    // ref for clicking outside to close dropdown
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    const tempTripId = localStorage.getItem("temp-trip-id");
    if (tempTripId) {
      this.setState({ askToJoinTripText: "Sign up to save Trip"});
    }

    if (auth.currentUser) {
      this.getTripperList();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  /**
   * Set the wrapper ref for click outside detection
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showCopyToClipboardDropdown: false });
    }
  }

  handleShareClicked = () => {
    this.setState({ sharing: true });
  };

  handleShareSearchChange = (event) => {
    this.setState({ shareSearchValue: event.target.value });
    if (event.target.value) {
      // TODO: Change this, we shouldn't be pulling an entire collection every time the user types a letter
      // We should eventually have a back-end handler that takes the search and gives us back results
      // But even getting the collection once and storing it instead of fetching every time would be better
      // Also there should be a delay on any of this so autocomplete results aren't fetched until the user has momentarily stopped typing
      event.persist();
      const userProfilesRef = firebase.firestore().collection("userProfiles");
      userProfilesRef
        .get()
        .then((querySnapshot) => {
          let searchResults = [];
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            // If search applies to this user, add to search Results
            if (
              (doc.data().username &&
                doc
                  .data()
                  .username.toLowerCase()
                  .includes(event.target.value.toLowerCase())) ||
              doc.id.includes(event.target.value)
            ) {
              searchResults.push({
                uid: doc.id,
                username: doc.data().username,
                photoURL: doc.data().photoURL,
              });
            }
          });
          this.setState({ shareSearchResults: searchResults });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      this.setState({ shareSearchResults: [] });
    }
  };

  handleShareWithClick = (uid) => {
    // if uid is already in sharedWith, remove it, otherwise add it
    if (this.state.sharedWith.includes(uid)) {
      let newArray = this.state.sharedWith;
      newArray.splice(newArray.indexOf(uid), 1);
      this.setState({ sharedWith: newArray });
    } else {
      this.setState({ sharedWith: [...this.state.sharedWith, uid] });
    }
  };

  shareSubmitClicked = () => {
    if (this.state.sharedWith && this.props.trip) {
      this.state.sharedWith.map((addedUser) => {
        if (!this.props.trip.users.includes(addedUser)) {
          var tripRef = doc(firebase.firestore(), "trips", this.props.trip.key);
          tripRef.update({
            users: FieldValue.arrayUnion(addedUser),
          });

          const notificationData = {
            text:
              auth.currentUser.displayName +
              " added you to a trip to " +
              this.props.trip.location +
              "!",
            link: "/trip/" + this.props.trip.key,
            seen: false,
          };

          addNotificationToUser(addedUser, notificationData);
        }
      });
    }
    this.setState({ sharing: false });
  };

  copyLink = () => {
    var textarea = document.createElement("textarea");
    textarea.value = "tripclub.ai/trip/" + this.props.trip.key;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.setState({ copyToClipboardText: "Copied!" });
  };

  getUserProfile = (uid) => {
    const userProfilesRef = firebase.firestore().collection("userProfiles");
    return userProfilesRef
      .doc(uid)
      .get()
      .then((collection) => {
        return collection.data();
      });
  };

  getTripperList = () => {
    this.props.trip.users.forEach((tripperUid) => {
      this.getUserProfile(tripperUid).then((user) => {
        if (user) {
          user["uid"] = tripperUid;
          this.setState((prevState) => ({
            tripperList: [...prevState.tripperList, user],
          }));
        }
      });
    });
  };

  getProfileCards = () => {
    const listItems = this.state.tripperList.map((user) => (
      <ProfileCard user={user} />
    ));
    return <div>{listItems}</div>;
  };

  sendAskToJoinTripNotification = (refreshPage = false) => {
    auth.currentUser.getIdToken(true)
        .then((token) => {
          var askToJoinTrip = httpsCallable(functions, "askToJoinTrip");
          askToJoinTrip({
            link: "/trip/" + this.props.trip.key,
            tripName: this.props.trip.location,
            askerUsername: this.props.currentUsername,
            askerUid: auth.currentUser.uid,
            tripCreator: this.props.trip.creatorId,
            token: token,
            tripId: this.props.trip.key,
          }).then((result) => {
            console.log("Ask to join trip status: ", result.data.status);
            console.log("full result: ", result);
            if (result.data.status == "Successfully asked to join trip.") {
              this.setState({ askToJoinTripText: "Request Sent!" });
            }
          });
        })
        .catch(function (err) {
          console.error(err);
        });
  };

  likeTrip = () => {
    const tripRef = doc(firebase.firestore(), "trips", this.props.trip.key);
    tripRef.update({
      likes: FieldValue.arrayUnion(auth.currentUser.uid),
    });
  };

  unlikeTrip = () => {
    const tripRef = doc(firebase.firestore(), "trips", this.props.trip.key);
    tripRef.update({
      likes: FieldValue.arrayRemove(auth.currentUser.uid),
    });
  };

  render() {
    return (
      <div className={!this.props.loadingDays ? "TripHeader" : ""}>
        <FollowListModal
          show={this.state.showFollowListModal}
          onHide={() =>
            this.setState({
              showFollowListModal: false,
            })
          }
          type={"Trippers"}
          children={this.getProfileCards()}
        />
        <ShareTripModal
          show={this.state.showShareTripModal}
          onHide={() =>
            this.setState({
              showShareTripModal: false,
            })
          }
          name={"Share Trip"}
          trip={this.props.trip}
        />
        <h5 className="TripHeader--tripTitle">{this.props.trip.location}</h5>
        <div className="TripHeader--subtitle">
          by{" "}
          {this.state.tripperList.map((user, index) => (
            <>
              <SmallUserLink userId={user.uid} username={user.username} />{" "}
              {index < this.state.tripperList.length - 1 && <>, </>}
            </>
          ))}
        </div>
        {!this.props.loadingDays && this.props.notSearchPage && (
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <LikesItem
              likes={this.props.trip?.likes}
              likeTrip={this.likeTrip}
              unlikeTrip={this.unlikeTrip}
            />
            {!this.props.isCurrentUserOnTrip && (
              <div>
                <button
                  className="btn btn-outline-primary"
                  style={{ marginLeft: "15px" }}
                  onClick={
                    !auth.currentUser
                      ? this.props.showAuthModal
                      : this.sendAskToJoinTripNotification
                  }
                >
                  {this.state.askToJoinTripText}
                </button>
              </div>
            )}
            <ShareTripButton
              buttonClicked={() => this.setState({ showShareTripModal: true })}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TripHeader);
