import React, { Component } from "react";
import "./SearchDropdownProfileCard.css";
// import { addFollower, removeFollower } from "../../util";
import { NavLink } from "react-router-dom";
import { getProfileImage, getUserProfile } from "../login/util";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import { addNotificationToUser } from "../../apiRequests/firestoreRequests/addNotificationToUser";

class SearchDropdownProfileCard extends Component {
  state = {
    profileImage: defaultProfPic,
    currentUser: null,
  };

  setStateCallback = (url) => {
    this.setState({ profileImage: url });
  };

  componentDidMount() {
    getUserProfile(this.props.user.uid).then((currentUser) => {
      getProfileImage(currentUser, this.setStateCallback);
      this.setState({ currentUser: currentUser });
    });
  }

  render() {
    return (
      <NavLink
        className="search-profile-card-wrapper"
        to={"/user/" + this.props.user.uid}
      >
        <div style={{ paddingRight: "4%" }}>
          <img
            alt="profile picture"
            src={this.state.profileImage}
            style={{ height: "50px" }}
            className="rounded-circle"
          />
        </div>

        <div
          style={{
            color: "black",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {this.props.user.username}
        </div>
      </NavLink>
    );
  }
}

export default SearchDropdownProfileCard;
