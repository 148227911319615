import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import "bootstrap/dist/css/bootstrap.css";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyDlb4BnUlLE0s9U73n_YPKOP3An0eKkeiA",
  authDomain: "tripclub11.firebaseapp.com",
  databaseURL: "https://tripclub11.firebaseio.com",
  projectId: "tripclub11",
  storageBucket: "tripclub11.appspot.com",
  messagingSenderId: "896536479937",
  appId: "1:896536479937:web:828eb088f7b1e068252c40",
  measurementId: "G-Z7KHG2EDT3",
};
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(
  process.env.REACT_APP_ENVIRONMENT === "development" ? undefined : app,
);
var db = getFirestore(app);
const storage = getStorage(app);
// Initialize Cloud Functions through Firebase
var functions = getFunctions(app);
const auth = getAuth(app);

export { db };
export { storage };
export { analytics };
export { app };
export { functions };
export { auth };

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
