import React, { Component } from "react";
import {
  googlePlaceDetailsRequest,
  googlePlaceSearchRequest,
} from "../../apiRequests/googlePlaceDetailsRequest";
import { getBestAspectPhoto } from "../../utils/photo-utils";
import defaultTripImage from "../../assets/defaultTripImage.jpeg";

const PHOTO_ASPECT_RATIO = 1.3;

class SearchPlaceCell extends Component {
  state = {
    queryTries: 0,
  };

  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    // place.latlong should only exist for foursquare api places, so for these we need to get extra data from google
    if (this.props.place.latlong) {
      var request = {
        query: this.props.name,
        fields: ["place_id"],
      };
      if (this.props.place.latlong) {
        request.locationBias = this.props.place.latlong;
      }
      googlePlaceSearchRequest(request, (results, status) => {
        if (results) {
          var placeId = results[0].place_id;
          googlePlaceDetailsRequest(placeId, this.placeDetailsCallback);
        }
      });
    }
  }

  placeDetailsCallback = (place, status) => {
    if (status == "OVER_QUERY_LIMIT" && this.state.queryTries < 4) {
      // retry after 2 seconds to see if it's just the short term query limit
      this.setState({ queryTries: this.state.queryTries + 1 });
      setTimeout(() => {
        googlePlaceDetailsRequest(
          this.props.placeId,
          this.placeDetailsCallback
        );
      }, 2000);
    } else if (place) {
      console.log(" placeCell placeDetails callback for name: " + place.name);
      console.log(place);
      this.setState({
        placeDetails: place,
        name: place.name,
        rating: place.rating,
        ratingsTotal: place.user_ratings_total,
      });
      if (place.photos) {
        this.setState({
          photoUrl: getBestAspectPhoto(
            place.photos,
            PHOTO_ASPECT_RATIO
          ).getUrl(),
        });
      }
    } else {
      console.error("place details failure status: ", status, place);
    }
  };

  onMouseEnter() {
    this.props.onMouseEnter(this.props.placeId);
  }

  onMouseLeave() {
    this.props.onMouseLeave(this.props.placeId);
  }

  onClick() {
    this.props.onClick(
      this.props.placeId,
      this.state.placeDetails,
      this.props.noGooglePlaceId,
      this.props.name,
      this.props.index,
      this.props.latlong
    );
  }
  getStars(rating) {
    var stars = "";
    if (rating == 5) {
      return " ★★★★★";
    }
    if (rating >= 4) {
      return " ★★★★☆";
    }
    if (rating >= 3) {
      return " ★★★☆☆";
    }
    if (rating >= 2) {
      return " ★★☆☆☆";
    }
    if (rating >= 1) {
      return " ★☆☆☆☆";
    }
    if (rating >= 0) {
      return " ☆☆☆☆☆";
    }
    return stars;
  }

  render() {
    return (
      <tr>
        <th
          scope="row"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          <div className="row">
            <span className="col-4">
              <img
                className="card-img"
                src={
                  this.props.photoUrl || this.state.photoUrl || defaultTripImage
                }
                alt="Card image"
                style={{ height: "100px", width: "130px", borderRadius: "8px" }}
              />
            </span>
            <span className="col-8">
              {this.props.name} <br />
              <h6>{this.props.address}</h6>
              <span style={{ fontWeight: "normal" }}>
                {this.props.rating || this.state.rating}
              </span>
              <span style={{ color: "var(--color-secondary)" }}>
                {this.getStars(this.props.rating || this.state.rating)}
              </span>
              <span style={{ fontWeight: "normal", color: "#aaa" }}>
                {this.props.ratingsTotal &&
                  " (" + this.props.ratingsTotal + ")"}
              </span>
              {this.props.place.starRating && (
                <div>
                  {parseInt(this.props.place.starRating, 10)}-star hotel
                </div>
              )}
              {this.props.place?.type && (
                <div
                  style={{ color: "grey", fontWeight: "500", fontSize: "16px" }}
                >
                  {this.props.place.type}
                </div>
              )}
              {this.props.place.price && (
                <div>Price: {this.props.place.price}</div>
              )}
            </span>
          </div>
        </th>
      </tr>
    );
  }
}

export default SearchPlaceCell;
