export const getStars = (rating) => {
  var stars = " ";
  for (var i = 1; i < 11; i++) {
    if (!rating) return "";
    if (rating * 2 >= i && i % 2 == 0) stars += "★";
    if (rating * 2 < i && i % 2 == 0) stars += "☆";
    if (rating * 2 == i && i % 2 == 1) {
      stars += "☆";
      i++;
    }
  }
  return stars;
};
