import React, { useEffect, useState } from "react";

import ReviewModal from "./reviewModal";
import "./reviewItem.css";

const ReviewItem = ({ review }) => {
  const getStars = () => {
    var stars = " ";
    for (var i = 1; i <= review["rating"]; i++) {
      stars += "★";
    }
    for (var i = 1; i <= 5 - review["rating"]; i++) {
      stars += "☆";
    }
    return stars;
  };

  return (
    <div>
      <div className="ReviewItem--container">
        <div className="ReviewItem--header">
          <img
            src={review["profile_photo_url"]}
            className="ReviewItem--author--img"
          />
          {review["author_name"]}
        </div>
        <div className="ReviewItem--subheader">
          <div className="ReviewItem--stars">{getStars()}</div>
          <div className="ReviewItem--date">
            {review["relative_time_description"]}
          </div>
        </div>
        <div style={{ margin: "10px" }}>{review["text"]}</div>
      </div>
      <div className="ReviewItem--line" />
    </div>
  );
};

export default ReviewItem;
