import React, { useEffect, useState } from "react";
import { ReactComponent as BeforeIcon } from "../../../assets/icons/navigate_before_black_48dp.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/navigate_next_black_48dp.svg";
import PhotoModal from "./photoModal";
import "./photoCarousel.css";

const PhotoCarousel = ({ photos }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [firstPhotoIndex, setFirstPhotoIndex] = useState(0);
  const [secondPhotoIndex, setSecondPhotoIndex] = useState(0);
  const [thirdPhotoIndex, setThirdPhotoIndex] = useState(0);
  const [showPhotoIndex, setShowPhotoIndex] = useState(0);
  const [showPhotoModal, setShowPhotoModal] = useState(false);

  useEffect(() => {
    setCarouselIndex(photos?.length > 3 ? 1 : 0);
    let urls = [];
    if (photos) {
      photos.forEach((photo) => {
        urls.push(photo.getUrl());
      });
      setPhotoUrls(urls);
    }
  }, [photos]);

  useEffect(() => {
    setFirstPhotoIndex((carouselIndex - 1 + photoUrls.length) % photoUrls.length);
    setSecondPhotoIndex(carouselIndex % photoUrls.length);
    setThirdPhotoIndex((carouselIndex + 1) % photoUrls.length);
  }, [carouselIndex]);

  const setPhotoIndexRight = (imageIndex) => {
    const newPhotoIndex = (imageIndex + 1) % photoUrls.length;
    setShowPhotoIndex(newPhotoIndex);
  };

  const setPhotoIndexLeft = (imageIndex) => {
    const newPhotoIndex = (imageIndex - 1 + photoUrls.length) % photoUrls.length;
    setShowPhotoIndex(newPhotoIndex);
  };

  return carouselIndex ? (
    <>
      <PhotoModal
        show={showPhotoModal}
        onHide={() => setShowPhotoModal(false)}
        images={photoUrls}
        imageIndex={showPhotoIndex}
        setIndexRight={setPhotoIndexRight}
        setIndexLeft={setPhotoIndexLeft}
      />
      <div className="PhotoCarousel--container">
        <div className="PhotoCarousel--line" />
        <div className="PhotoCarousel--title">Photos</div>
        <div className="PhotoCarousel--photo--container">
          {photoUrls.length > 0 && (
            <>
              <BeforeIcon
                onClick={() => setCarouselIndex((carouselIndex - 1 + photoUrls.length) % photoUrls.length)}
                className="PhotoCarousel--arrow"
              />
              <img
                src={photoUrls[firstPhotoIndex]}
                className="PhotoCarousel--img"
                onClick={() => {
                  setShowPhotoIndex(0);
                  setShowPhotoModal(true);
                }}
              />
            </>
          )}
          {photoUrls.length > 1 && (
            <img
              src={photoUrls[secondPhotoIndex]}
              className="PhotoCarousel--img"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              onClick={() => {
                setShowPhotoIndex(1);
                setShowPhotoModal(true);
              }}
            />
          )}
          {photoUrls.length > 2 && (
            <>
              <img
                src={photoUrls[thirdPhotoIndex]}
                className="PhotoCarousel--img"
                onClick={() => {
                  setShowPhotoIndex(2);
                  setShowPhotoModal(true);
                }}
              />
              <NextIcon
                onClick={() => setCarouselIndex((carouselIndex + 1) % photoUrls.length)}
                className="PhotoCarousel--arrow"
              />
            </>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default PhotoCarousel;
