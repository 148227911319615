/*
    Places Search Box
    Pass in "bounds" prop for the search bounds of the map
    Pass in func "directionsPlaceChanged" prop to receive search result place
*/
import React, { Component } from "react";
import "./directionsSearchBox.css";

class DirectionsSearchBox extends Component {
  state = {
    searchBox: null,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: props.initialValue,
    };
  }

  componentDidMount() {
    const input = document.getElementById("pac-input" + this.props.index);
    const searchBox = new window.google.maps.places.Autocomplete(input);
    searchBox.setBounds(this.props.bounds);

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();

      if (place.length == 0) {
        console.log("no search box place");
        return;
      }
      console.log(place);
      this.setState({ value: input.value });
      this.props.directionsPlaceChanged(place, this.props.index);
    });

    this.setState({ searchBox });
  }

  componentDidUpdate(prevProps) {
    if (this.props.bounds !== prevProps.bounds) {
      if (this.state.searchBox) {
        this.state.searchBox.setBounds(this.props.bounds);
      }
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <input
        id={"pac-input" + this.props.index}
        type="search"
        onChange={this.handleChange}
        className="PlacesSearchBox form-control"
        placeholder="Search"
        value={this.state.value}
      />
    );
  }
}

export default DirectionsSearchBox;
