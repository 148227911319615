import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import ConfirmationPill from "../../sharedComponents/confirmationPill/confirmationPill";
import { addNotificationToUser } from "../../../apiRequests/firestoreRequests/addNotificationToUser";
import ShareSearchDropdown from "./shareSearchDropdown";
import firebase from 'firebase/compat/app';
import { db, auth } from "../../../index";

const ShareTripModal = (props) => {
  const [copyToClipboardText, setCopyToClipboardText] = useState(
    "Copy shareable link to keyboard"
  );
  const [shareSearchValue, setShareSearchValue] = useState("");
  const [shareSearchResults, setShareSearchResults] = useState([]);
  const [sharedWith, setSharedWith] = useState([]);
  const [showSuccessPill, setShowSuccessPill] = useState(false);

  const copyLink = () => {
    var textarea = document.createElement("textarea");
    textarea.value = "tripclub.ai/trip/" + props.trip.key;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopyToClipboardText("Copied!");
  };
  const handleShareSearchChange = async (event) => {
    setShareSearchValue(event.target.value);
    if (event.target.value) {
      event.persist();
      let searchResults = [];
      const userProfilesRef = firebase.firestore().collection("userProfiles");
      const snapshot = await userProfilesRef
        .where("username", ">=", event.target.value.toLowerCase())
        .where("username", "<=", event.target.value.toLowerCase() + '\uf8ff')
        .get();
  
      snapshot.forEach((doc) => {
        if (doc.exists) {
          searchResults.push({
            uid: doc.id,
            username: doc.data().username,
            photoURL: doc.data().photoURL,
          });
        }
      });
      
      setShareSearchResults(searchResults);
    } else {
      setShareSearchResults([]);
    }
  };
  
  const shareSubmitClicked = () => {
    if (sharedWith && props.trip) {
      sharedWith.map((addedUser) => {
        if (!props.trip.users.includes(addedUser)) {
          var tripRef = firebase.firestore().collection("trips").doc(props.trip.key);
          tripRef
            .update({
              users: firebase.firestore.FieldValue.arrayUnion(addedUser),
            })
            .then(() => {
              setShowSuccessPill(true);
              setTimeout(() => setShowSuccessPill(false), 3000);
            });
          const notificationData = {
            text:
              auth.currentUser.displayName +
              " added you to a trip to " +
              props.trip.location +
              "!",
            link: "/trip/" + props.trip.key,
            seen: false,
          };
  
          addNotificationToUser(addedUser, notificationData);
        }
      });
    }
  };  

  const handleShareWithClick = (uid) => {
    // if uid is already in sharedWith, remove it, otherwise add it
    if (sharedWith.includes(uid)) {
      let newArray = sharedWith;
      newArray.splice(newArray.indexOf(uid), 1);
      setSharedWith(newArray);
    } else {
      setSharedWith([...sharedWith, uid]);
    }
  };

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div style={{ position: "absolute" }}>
          <ConfirmationPill
            text={"Share success!"}
            showPill={showSuccessPill}
          />
        </div>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ marginLeft: "40%" }}
        >
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody" style={{ padding: "0" }}>
        <div style={{ display: "inline-flex", margin: "0 10px" }}>
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Share"
            aria-label="Search"
            value={shareSearchValue}
            onChange={handleShareSearchChange}
          />
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="submit"
            value="Submit"
            onClick={shareSubmitClicked}
          >
            Submit
          </button>
          {shareSearchResults[0] && (
            <ShareSearchDropdown
              searchResults={shareSearchResults}
              handleShareWithClick={handleShareWithClick}
              sharedWith={sharedWith}
            />
          )}
        </div>
        <div
          style={{
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            padding: "12px 8px",
            textAlign: "center",
            zIndex: "1000",
            backgroundColor: "white",
            marginTop: "38px",
          }}
        >
          <div className="dropdown-item" onClick={copyLink}>
            {copyToClipboardText}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareTripModal;
