import React, { Component } from "react";
import WaypointsBox from "./waypointsBox";
import RouteCard from "./routeCard";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";

class RoutesView extends Component {
  state = {};

  routeDetailsClicked = (route, index) => {
    this.props.routeDetailsClicked(route, index);
  };

  render() {
    return (
      <div>
        <DefaultButton onClick={this.props.backButtonPressed}>
          <div className="SavedPlacesList--backButton">← Back to trip</div>
        </DefaultButton>
        <WaypointsBox
          waypoints={this.props.waypoints}
          waypointNames={this.props.waypointNames}
          bounds={this.props.bounds}
          directionsPlaceChanged={this.props.directionsPlaceChanged}
        />
        {this.props.routes.map((route, index) => (
          <>
            <RouteCard
              route={route}
              index={index}
              isActiveRoute={this.props.activeRouteIndex === index}
              routeCardClicked={(route, index) =>
                this.props.routeCardClicked(route, index)
              }
              routeDetailsClicked={this.props.routeDetailsClicked}
              isDetailsPressed={this.props.routeDetailsIndex == index}
            />
            <div id={"detailsPanel" + index}></div>
          </>
        ))}
      </div>
    );
  }
}

export default RoutesView;
