/*
    Places Search Box
    Pass in "bounds" prop for the search bounds of the map
    Pass in func "searchResultsReceived" prop to receive search result place(s)
*/
import React, { Component } from "react";
import "./searchBox/placesSearchBox.css";

class PlacesAutocomplete extends Component {
  state = {
    searchBox: null,
  };

  componentDidMount() {
    const input = document.getElementById("pac-input");
    const searchBox = new window.google.maps.places.Autocomplete(input);
    searchBox.setBounds(this.props.bounds);
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        console.log("no search box places");
        return;
      }
      console.log(places);
      this.props.searchResultsReceived(places);
    });
    searchBox.addListener("place_changed", () =>
      this.handlePlaceSelect(searchBox.getPlace())
    );

    this.setState({ searchBox });
  }

  componentDidUpdate(prevProps) {
    if (this.props.bounds !== prevProps.bounds) {
      if (this.state.searchBox) {
        this.state.searchBox.setBounds(this.props.bounds);
      }
    }
  }

  handlePlaceSelect = (place) => {
    console.log(place.formatted_address);
    this.props.searchValueChanged(place.formatted_address);
    this.props.searchValueSelected(place.formatted_address);
  };

  onChange = (e) => {
    this.props.searchValueChanged(e.target.value);
  };

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("THE KEY IS ENTER ", this.props.searchBoxValue);
      this.props.searchValueSelected(this.props.searchBoxValue);
    }
  };

  render() {
    return (
      <div className="PlacesSearchBox" style={{ position: "relative" }}>
        <input
          id="pac-input"
          type="search"
          className="PlacesSearchBox form-control"
          placeholder="Search Places"
          value={this.props.searchBoxValue}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
        {this.props.hasClearButton && (
          <button
            onClick={() => this.props.searchValueChanged("")}
            className="PlacesSearchBox-clearButton"
          >
            ✕
          </button>
        )}
      </div>
    );
  }
}

export default PlacesAutocomplete;
