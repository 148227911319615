import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getUserProfile } from "../../login/util";

const SmallUserLink = (props) => {
  return (
    <NavLink
      to={"/user/" + props.userId}
      className="defaultButton"
      style={{ color: "white" }}
    >
      {props.username}
    </NavLink>
  );
};

export default SmallUserLink;
