import React, { Component } from "react";
import TripCard from "./tripCard";
import { isMobile } from "../../utils/mobile-check";

class Trips extends Component {
  state = {};
  render() {
    const trips = this.props.trips;
    const tripStyle = !isMobile()
      ? { display: "grid", gridTemplateColumns: "33% 33% 33%" }
      : {};

    return (
      <div class="grid-container" style={tripStyle}>
        {trips.map((trip) => (
          <TripCard
            key={trip.key}
            id={trip.key}
            userId={trip.userId}
            location={trip.location}
            startDate={trip.startDate}
            endDate={trip.endDate}
            days={trip.days}
            geoLoc={trip.geoLoc}
            photoUrl={trip.photoUrl}
            placeId={trip.placeId}
            likes={trip.likes}
            creatorId={trip.creatorId}
          />
        ))}
      </div>
    );
  }
}

export default Trips;
