import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NavSearchDropdown from "./navSearchDropdown";
import {
  navSearchBarUserProfileFilter,
  navSearchBarExistingTripsFilter,
} from "./navHeaderUtility/navSearchBarDataFetch";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './navSearchBar.css';
import { db } from "../../index";

class NavSearchBar extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // ref for clicking outside to close dropdown
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    value: "",
    userSearchResults: [],
    locationSearchResults: [],
    isDropdownOpen: false,
    hasReceivedResults: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  /**
   * Set the wrapper ref for click outside detection
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    if (event.target.value) {
      event.persist();
      getDocs(collection(db, 'userProfiles'))
        .then((querySnapshot) => {
          let userSearchResults = navSearchBarUserProfileFilter(
            querySnapshot,
            event.target.value
          );
          this.setState({
            userSearchResults: userSearchResults,
            hasReceivedResults: true,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });

      getDocs(collection(db, 'trips'))
        .then((querySnapshot) => {
          let locationSearchResults = navSearchBarExistingTripsFilter(
            querySnapshot,
            event.target.value
          );
          this.setState({
            locationSearchResults: locationSearchResults,
            hasReceivedResults: true,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      this.setState({
        userSearchResults: [],
        locationSearchResults: [],
        hasReceivedResults: false,
      });
    }
  }

  searchBarClicked = () => {
    this.setState({ isDropdownOpen: true });
  };

  handleResultClick = () => {
    this.setState({
      value: "",
      userSearchResults: [],
      locationSearchResults: [],
      hasReceivedResults: false,
    });
  };

  handleSubmit() {
    console.log("A name was submitted: " + this.state.value);
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ display: "block" }}
        ref={this.setWrapperRef}
      >
        <input
          className="navSearchBar"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={this.state.value}
          onChange={this.handleChange}
          onClick={this.searchBarClicked}
        />
        {this.state.isDropdownOpen && (
          <NavSearchDropdown
            userSearchResults={this.state.userSearchResults}
            locationSearchResults={this.state.locationSearchResults}
            handleResultClick={this.handleResultClick}
            searchValue={this.state.value}
            hasReceivedResults={this.state.hasReceivedResults}
          />
        )}
      </form>
    );
  }
}

export default NavSearchBar;
