import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  SingleDatePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
} from "react-dates";

class AddToCalDatePicker extends Component {
  state = {
    date: null,
    focused: null,
  };

  constructor(props) {
    super(props);
  }

  onDateChange = (date) => {
    this.setState({ date });
    this.props.onDateChange(date);
  };

  isOutsideRange = (day) => {
    return (
      isInclusivelyAfterDay(
        day,
        moment.unix(this.props.endDate?.seconds).add(1, "days")
      ) ||
      isInclusivelyBeforeDay(
        day,
        moment.unix(this.props.startDate?.seconds).subtract(1, "days")
      )
    );
  };

  render() {
    return (
      <div>
        <SingleDatePicker
          date={this.state.date} // momentPropTypes.momentObj or null
          onDateChange={(date) => this.onDateChange(date)} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
          isOutsideRange={this.isOutsideRange}
          initialVisibleMonth={() => moment.unix(this.props.startDate?.seconds)}
          numberOfMonths={1}
          openDirection={
            this.props.openDirection ? this.props.openDirection : "up"
          }
          small={this.props.small}
        />
      </div>
    );
  }
}

export default AddToCalDatePicker;
