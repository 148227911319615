import React, { Component } from "react";
import { ReactComponent as ShareSVG } from "./../../../assets/icons/ios_share_black_24dp.svg";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import "./shareTripButton.css";

class ShareTripButton extends Component {
  state = {};
  render() {
    return (
      <DefaultButton >
        <div onClick={this.props.buttonClicked} className="ShareTripButton" >
          <span>
            <span>
              <ShareSVG className="LikesItemSVG" />
            </span>
            <span className="ShareTripButton--shareText">{" Share"}</span>
          </span>
        </div>
      </DefaultButton>
    );
  }
}

export default ShareTripButton;
