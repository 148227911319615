import firebase from 'firebase/compat/app';

export const addFollower = (followedUid) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (token) {
      var addFollower = firebase.functions().httpsCallable("addFollower");
      addFollower({ follower: token, followed: followedUid }).then(function (
        result
      ) {
        console.log("Follow Status: ", result.data.status);
        return result.data.status;
      });
    })
    .catch(function (err) {
      console.error(err);
    });
};

export const removeFollower = (followedUid) => {
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (token) {
      var removeFollower = firebase.functions().httpsCallable("removeFollower");
      removeFollower({ follower: token, followed: followedUid }).then(function (
        result
      ) {
        console.log("Follow Status: ", result.data.status);
      });
    })
    .catch(function (err) {
      console.error(err);
    });
};
