/*
    Places Search Box
    Pass in "bounds" prop for the search bounds of the map
    Pass in func "searchResultsReceived" prop to receive search result place(s)
*/
import React, { Component } from "react";
import {
  googlePlacesNearbySearchRequest,
  googlePlacesTextSearchRequest,
} from "../../../../apiRequests/googlePlaceDetailsRequest";
import { ReactComponent as BeforeIcon } from "../../../../assets/icons/navigate_before_black_48dp.svg";
import { foursquarePlaceSearch } from "../../../../apiRequests/foursquareApiRequests";
import "./placesSearchBox.css";

const hotelTerms = [
  "hotels",
  "hotel",
  "hotels near me",
  "lodging",
  "motel",
  "motels",
];

class PlacesSearchBox extends Component {
  state = {
    searchBox: null,
  };

  componentDidMount() {
    const input = document.getElementById("pac-input");
    const searchBox = new window.google.maps.places.SearchBox(input);
    searchBox.setBounds(this.props.bounds);
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      this.props.searchValueChanged(document.getElementById("pac-input").value);

      // if it's a hotel term we don't want the google places search
      if (false) {
        // hotelTerms.includes(document.getElementById("pac-input").value.toLowerCase())
        console.log("search With Lodging Term");
        this.props.searchWithLodgingTerm();
      } else {
        if (places.length == 0) {
          console.log(
            "bounds: ",
            this.props.bounds,
            ". Search box bounds: ",
            searchBox.getBounds(),
          );
          console.log("no search box places");

          console.log(
            "center: ",
            this.props.center,
            ". keyword: ",
            this.props.searchBoxValue,
          );
          var request = {
            keyword: this.props.searchBoxValue,
            fields: ["place_id", "name", "formatted_address"],
            location: {
              lat: this.props.center._lat,
              lng: this.props.center._long,
            },
            rankBy: window.google.maps.places.RankBy.DISTANCE,
          };
          // if nothing returns let's try our own google place nearby search
          googlePlacesNearbySearchRequest(request, (results, status) => {
            if (results.length !== 0) {
              console.log(
                "custom place nearbySearch request, results: ",
                results,
              );
              this.props.searchResultsReceived(results);
            } else {
              console.log("custom place nearbySearch returned NO results");
              console.log("STATUS: ", status);

              // if this still doesn't work, let's fall back to a foursquare API search
              foursquarePlaceSearch(
                this.props.searchBoxValue,
                "" + this.props.center._lat + "," + this.props.center._long,
                (err, res, body) => {
                  if (err) {
                    console.error(err);
                  } else {
                    console.log(JSON.parse(body));
                    const response = JSON.parse(body);
                    this.props.foursquareSearchResultsReceived(
                      response.response,
                    );
                  }
                },
              );
            }
          });

          return;
        }
        console.log(places);
        this.props.searchResultsReceived(places);
      }
    });

    this.setState({ searchBox });
  }

  componentDidUpdate(prevProps) {
    if (this.props.bounds !== prevProps.bounds) {
      if (this.state.searchBox) {
        this.state.searchBox.setBounds(this.props.bounds);
      }
    }
  }

  onChange = (e) => {
    this.props.searchValueChanged(e.target.value);
  };

  onSubmit = (e) => {
    console.log("onSumbit: ", e);
  };

  getClassName = () => {
    if (this.props.floatingSearchBox) {
      return "FloatingPlacesSearchBox";
    } else {
      return "PlacesSearchBox";
    }
  };

  render() {
    return (
      <div
        className={this.getClassName()}
        style={{
          position: this.props.floatingSearchBox ? "absolute" : "relative",
          zIndex: "4",
        }}
      >
        <input
          id="pac-input"
          type="search"
          className="PlacesSearchBox form-control"
          placeholder="Search Places"
          value={this.props.searchBoxValue}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onClick={this.onClick}
        />
        {this.props.hasClearButton && (
          <button
            onClick={() => this.props.searchValueChanged("")}
            className="PlacesSearchBox-clearButton"
          >
            ✕
          </button>
        )}
        {this.props.hasClearButton && (
          <div
            onClick={
              this.props.placeDetailsViewId
                ? this.props.backToListResults
                : () => this.props.searchValueChanged("")
            }
            className="FloatingPlacesSearchBox--backButton"
          >
            {this.props.isOnSearch && this.props.placeDetailsViewId
              ? "← Back to results"
              : "← Back to trip"}
          </div>
        )}
      </div>
    );
  }
}

export default PlacesSearchBox;
