import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NoTripExistsError extends Component {
  state = {};
  render() {
    return (
      <div>
        <h2>
          Sorry, looks like this trip doesn't exist. Come back{" "}
          <NavLink to="/">Home</NavLink>
        </h2>
      </div>
    );
  }
}

export default NoTripExistsError;
