import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { auth, functions, app, db, storage } from "../../index";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import Cropper from "react-easy-crop";

import { getCroppedImg } from "./cropImage";
import FollowListModal from "../profileContainer/followListModal";
import {
  isInputAlreadyTaken,
  formatImageFileName,
  getUserProfile,
  getProfileImage,
} from "../login/util";
import ConfirmationPill from "../sharedComponents/confirmationPill/confirmationPill";
import "./editProfileView.css";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import { isMobile } from "../../utils/mobile-check";

class EditProfileView extends Component {
  state = {
    isUsernameInUseState: false,
    username: null,
    image: null,
    file: null,
    currentProfileImage: null,
    profileUrl: defaultProfPic,
    profilePictureUuid: null,
    currentProfileImage: defaultProfPic,
    imageSrc: null,
    crop: { x: 0, y: 0 },
    rotation: 0,
    croppedAreaPixels: null,
    croppedImage: null,
    showSavingPill: false,
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img),
        file: event.target.files[0],
      });
    }
    this.onFileChange(event);
  };

  setStateCallback = (url) => {
    this.setState({ currentProfileImage: url });
  };

  handlerUsernameInput = (username) => {
    this.setState({ username: username });
    isInputAlreadyTaken("username", username, (inUse) =>
      this.setState({ isUsernameInUseState: inUse }),
    );
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        getUserProfile(user.uid).then((user) => {
          getProfileImage(user, this.setStateCallback);
          if (user?.profilePictureUuid) {
            this.setState({ profilePictureUuid: user?.profilePictureUuid });
          }
          if (user?.username) {
            this.setState({ username: user.username });
          }
        });
      }
    });
  }

  updateUserField = (userField, refreshPage = false) => {
    auth.currentUser
      .getIdToken(true)
      .then(function (token) {
        const updateUserField = httpsCallable(functions, "updateUserField");
        updateUserField({ uid: token, userField: userField }).then(
          function (result) {
            console.log("Update profile status: ", result.data.status);
            if (refreshPage) {
              window.location.reload();
            }
          },
        );
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  saveProfilePage = async () => {
    if (this.state.croppedImage) {
      const newProfileUid = uuidv4();
      const imageFileName = formatImageFileName(newProfileUid);

      // Create a reference to 'images/mountains.jpg'
      var profileImageRef = ref(storage, imageFileName);

      let response = await fetch(this.state.croppedImage);
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);

      // var file = ... // use the Blob or File API
      uploadBytes(profileImageRef, file).then((snapshot) => {
        console.log("Uploaded new profile photo!");
      });
      this.updateUserField({ profilePictureUuid: newProfileUid });
      // Create a reference to the file to delete
      const oldImageFileName = formatImageFileName(
        this.state.profilePictureUuid,
      );
      var oldProfilePictureRef = ref(storage, oldImageFileName);

      // Delete the old profile picture.
      deleteObject(oldProfilePictureRef)
        .then(() => {
          console.log("Successfully deleted old profile picture.");
          this.setState({ showSavingPill: true });
          setTimeout(() => this.setState({ showSavingPill: false }), 3000);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    if (this.state.username) {
      this.updateUserField({ username: this.state.username });
    }
  };

  triggerInputFile = () => this.fileInput.click();

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);
      this.setState({ imageSrc: imageDataUrl });
    }
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        null,
      );
      this.setState({ croppedImage: croppedImage });
      this.saveProfilePage();
      this.setState({ imageSrc: null });
    } catch (e) {
      console.error(e);
    }
  };

  onClose = () => {
    this.setState({ croppedImage: null });
  };

  setCrop = (crop) => {
    this.setState({ crop: crop });
  };

  setZoom = (zoom) => {
    this.setState({ zoom: zoom });
  };

  getCropper = () => (
    <div>
      <Cropper
        image={this.state.imageSrc}
        crop={this.state.crop}
        rotation={0}
        zoom={this.state.zoom}
        aspect={3 / 3}
        onCropChange={this.setCrop}
        onRotationChange={() => null}
        onCropComplete={this.onCropComplete}
        onZoomChange={this.setZoom}
      />
      <button
        className="picture-save-button"
        onClick={this.showCroppedImage}
        variant="contained"
        color="primary"
      >
        Save
      </button>
    </div>
  );

  render() {
    return (
      <>
        <FollowListModal
          show={this.state.imageSrc}
          onHide={() =>
            this.setState({
              imageSrc: null,
            })
          }
          type={"Save your picture"}
          children={this.getCropper()}
        />
        <div className="page-container">
          <div className="edit-container">
            <div
              className={isMobile() ? "edit-header--isMobile" : "edit-header"}
            >
              <img
                src={
                  this.state.croppedImage
                    ? this.state.croppedImage
                    : this.state.currentProfileImage
                }
                alt="profile picture"
                style={{ height: "150px", margin: "10%" }}
                className="rounded-circle"
              />
              <div>
                <h2>{this.state.username}</h2>
                <div>
                  <input
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.onImageChange}
                  />
                  <p
                    onClick={this.triggerInputFile}
                    className="profile-photo-text"
                  >
                    Change Profile Photo
                  </p>
                </div>
              </div>
            </div>
            <div
              className={isMobile() ? "edit-header--isMobile" : "edit-header"}
            >
              <p
                className="profile-text"
                style={{
                  marginLeft: "15%",
                  marginRight: "16%",
                  marginBottom: "0",
                }}
              >
                Username
              </p>
              <div style={{ maxWidth: "100px" }}>
                <input
                  onChange={(event) =>
                    this.handlerUsernameInput(event.target.value)
                  }
                  style={{ width: "200px" }}
                  placeholder={this.state.username}
                />
                {this.state.isUsernameInUseState && (
                  <div>This is already in use.</div>
                )}
                <button
                  onClick={() => this.saveProfilePage()}
                  className="EditProfileView--button"
                  disabled={this.state.isUsernameInUseState}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="EditProfileView--save--pill--container">
            <ConfirmationPill
              text={"Saved profile picture!"}
              showPill={this.state.showSavingPill}
            />
          </div>
        </div>
      </>
    );
  }
}

export default EditProfileView;
