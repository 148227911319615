import React, { useState, useEffect } from "react";
import LoginModal from "./LoginModal";
import "./AuthModal.css";

const AuthModal = (props) => {
  if (props.isSignedIn || !props.show) {
    return null;
  }

  return (
    <div className="auth-modal">
      <LoginModal onHide={props.onHide} />
    </div>
  );
};

export default AuthModal;
