import React, { Component } from "react";
import { googlePlaceDetailsRequest } from "../../apiRequests/googlePlaceDetailsRequest";
import "./placeCell/placeCell.css";
import { NavLink } from "react-router-dom";

import "./savedPlaceCell.css";
import { getBestAspectPhoto } from "../../utils/photo-utils";

const PHOTO_ASPECT_RATIO = 1.3;

class SavedPlaceCell extends Component {
  state = {
    photoURL: undefined,
    placeDetails: undefined,
    name: undefined,
    queryTries: 0,
  };

  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.placeId);
  };

  onMouseLeave = () => {
    this.props.onMouseLeave(this.props.placeId);
  };

  onClick = () => {
    this.props.onClick(this.props.placeId);
  };

  placeDetailsCallback = (place, status) => {
    console.log(" Error: No place for callback " + status);
    if (status == "OVER_QUERY_LIMIT" && this.state.queryTries < 4) {
      // retry after 2 seconds to see if it's just the short term query limit
      this.setState({ queryTries: this.state.queryTries + 1 });
      setTimeout(() => {
        googlePlaceDetailsRequest(
          this.props.placeId,
          this.placeDetailsCallback
        );
      }, 2000);
    } else if (place) {
      console.log(" placeCell placeDetails callback for name: " + place.name);
      this.setState({ placeDetails: place, name: place.name });
      if (place.photos) {
        this.setState({
          photoURL: getBestAspectPhoto(
            place.photos,
            PHOTO_ASPECT_RATIO
          ).getUrl(),
        });
      }
    }
  };

  getStars() {
    var stars = "";
    if (this.state.placeDetails.rating == 5) {
      return " ★★★★★";
    }
    if (this.state.placeDetails.rating >= 4) {
      return " ★★★★☆";
    }
    if (this.state.placeDetails.rating >= 3) {
      return " ★★★☆☆";
    }
    if (this.state.placeDetails.rating >= 2) {
      return " ★★☆☆☆";
    }
    if (this.state.placeDetails.rating >= 1) {
      return " ★☆☆☆☆";
    }
    if (this.state.placeDetails.rating >= 0) {
      return " ☆☆☆☆☆";
    }
    return stars;
  }

  componentDidMount() {
    if (this.props.placeId) {
      googlePlaceDetailsRequest(this.props.placeId, this.placeDetailsCallback);
    }
  }

  render() {
    return (
      <th
        className={
          this.props.hoveredCell == this.props.placeId
            ? "placeCell placeCell-hover"
            : "placeCell"
        }
        scope="row"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="row no-gutters">
          <span className="col-4" onClick={this.onClick}>
            <div style={{ width: "200px" }}>
              <img
                className="card-img"
                src={this.state.photoURL}
                alt="Card image"
                style={{ height: "100px", width: "130px" }}
              />
            </div>
          </span>
          <span className="col-6" onClick={this.onClick}>
            {this.state.name} <br />
            <h6>{this.props.address}</h6>
            {this.state.placeDetails && (
              <>
                <span style={{ fontWeight: "normal" }}>
                  {this.state.placeDetails.rating}
                </span>
                <span style={{ color: "var(--color-secondary)" }}>{this.getStars()}</span>
              </>
            )}
          </span>
          <span className="col-2" style={{ fontSize: "14px" }}></span>
          <NavLink
            to={"/user/" + this.props.creatorId}
            style={{ paddingTop: "8px" }}
          >
            <div className="searchTripCardSubtitle">
              added by {this.props.creatorName}
            </div>
          </NavLink>
        </div>
      </th>
    );
  }
}

export default SavedPlaceCell;
