import React, { Component } from "react";
import PlaceCell from "../placeCell/placeCell";
import { Droppable } from "react-beautiful-dnd";
import "./dayComp.css";
import MiniPlaceCell from "../placeCell/miniPlaceCell";
import AddPlacesComponent from "./addPlacesComponent";
import PlaceRouteConnection from "./placeRouteConnection";

const DAYS_OF_WEEK = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const MONTHS_OF_YEAR = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

class DayComp extends Component {
  state = {
    address: "",
    searchOptions: {},
    places: [],
    routesInfo: {},
  };

  constructor(props) {
    super(props);
  }

  setPlaceTime = (placeIndex, time) => {
    this.props.setPlaceTime(this.props.index, placeIndex, time);
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  addButton = () => {
    this.setState({ addButtonClicked: true });
  };

  cancelAddButton = () => {
    this.setState({ addButtonClicked: false });
  };

  componentWillReceiveProps(props) {
    if (props.bounds) {
      this.setState({ searchOptions: { bounds: props.bounds } });
    }
  }

  // componentDidUpdate = (prevProps) => {
  //   if (this.props.day.places !== prevProps.day.places) {
  //     for (var i = this.props.day.places.length - 2; i >= 0; i--) {
  //       this.calculateRoute(i);
  //     }
  //   }
  // };

  // calculateRoute = (startingIndex) => {
  //   const directionsService = new window.google.maps.DirectionsService();

  //   const originGeoLoc = {
  //     lat: this.props.day.places[startingIndex].locationData.geoLoc._lat,
  //     lng: this.props.day.places[startingIndex].locationData.geoLoc._long,
  //   };
  //   const destinationGeoLoc = {
  //     lat: this.props.day.places[startingIndex + 1].locationData.geoLoc._lat,
  //     lng: this.props.day.places[startingIndex + 1].locationData.geoLoc._long,
  //   };
  //   directionsService.route(
  //     {
  //       origin: originGeoLoc,
  //       destination: destinationGeoLoc,
  //       // Note that Javascript allows us to access the constant
  //       // using square brackets and a string value as its
  //       // "property."
  //       // @ts-ignore
  //       travelMode: "DRIVING",
  //       provideRouteAlternatives: true,
  //     },
  //     (response, status) => {
  //       if (status == "OK") {
  //         console.log("getting directions, response: ", response);
  //         var routesInfo = this.state.routesInfo;
  //         routesInfo[startingIndex] = response?.routes?.[0].legs[0];
  //         this.setState({ routesInfo: routesInfo });
  //       } else {
  //         console.error("Directions request failed due to " + status);
  //       }
  //     },
  //   );
  // };

  // componentDidMount() {
  //   for (var i = this.props.day.places.length - 2; i >= 0; i--) {
  //     this.calculateRoute(i);
  //   }
  // }

  render() {
    return (
      <div className="container" style={{ minHeight: "102px" }}>
        <h5>
          {DAYS_OF_WEEK[this.props.day.date.toDate().getDay()] + ", "}
          {MONTHS_OF_YEAR[this.props.day.date.toDate().getMonth()] + " "}
          {this.props.day.date.toDate().getDate()}
        </h5>
        <table
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "60px",
          }}
          className="table table-hover"
        >
          <Droppable droppableId={this.props.index}>
            {(provided) => (
              <tbody
                style={{ flexGrow: 1 }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.props.day.places?.map((place, index) => (
                  <div key={place.locationData.placeId}>
                    {place.hotelDaySignifier == 2 ? (
                      <MiniPlaceCell
                        key={place.key}
                        locationData={place.locationData}
                      />
                    ) : (
                      <PlaceCell
                        key={place.key}
                        hash={place.key}
                        placeId={place.locationData?.placeId}
                        index={index}
                        time={place.time}
                        notes={place.notes}
                        type={place.type}
                        name={place.locationData?.name}
                        description={place.description}
                        placePhoto={place.placePhoto}
                        className="container"
                        address={place.locationData.address}
                        onMouseEnter={this.props.onCellMouseEnter}
                        onMouseLeave={this.props.onCellMouseLeave}
                        onClick={this.props.onCellClick}
                        setPlaceTime={this.setPlaceTime}
                        hoveredCell={this.props.hoveredCell}
                        creatorName={place.creatorName}
                        creatorId={place.creatorId}
                        deletePlaceClicked={this.props.deletePlaceClicked}
                        updatePlaceClicked={this.props.updatePlaceClicked}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        date={this.props.day.date.toDate()}
                        currentAuthUserId={this.props.currentAuthUserId}
                        isCurrentUserOnTrip={this.props.isCurrentUserOnTrip}
                      />
                    )}
                    {index < this.props.day.places.length - 1 && (
                      <PlaceRouteConnection
                        routeLineClicked={() =>
                          this.props.routeBetweenPlacesClicked(
                            this.props.index,
                            index,
                          )
                        }
                      />
                    )}
                  </div>
                ))}
                {this.props.isCurrentUserOnTrip && (
                  <AddPlacesComponent
                    trip={this.props.trip}
                    date={this.props.day.date.toDate()}
                    addLocationToDate={this.props.addLocationToDate}
                  />
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </div>
    );
  }
}

export default DayComp;
