import React from "react";
import DefaultButton from "../sharedComponents/defaultButton/defaultButton";
import PlaceCell from "./placeCell/placeCell";
import SavedPlaceCell from "./savedPlaceCell";
import './savedPlacesList.css';

const SavedPlacesList = (props) => {
  const onMouseEnter = (placeId) => {
    props.onCellMouseEnter(placeId);
  };

  const onMouseLeave = (placeId) => {
    props.onCellMouseLeave(placeId);
  };

  const onCellClick = (placeId) => {
    props.onCellClick(placeId);
  };

  return (
    <div className="SavedPlacesList">
      <DefaultButton onClick={props.backButtonPressed}>
        <div className="SavedPlacesList--backButton">
          ← Back to trip
        </div>
      </DefaultButton>
      <h4>Saved Places</h4>
      {props.savedPlaces.map((place, index) => (
        <div key={place.locationData.placeId}>
          <SavedPlaceCell
            key={place.locationData.placeId}
            placeId={place.locationData.placeId}
            index={index}
            className="container"
            address={place.locationData.address}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onCellClick}
            hoveredCell={props.hoveredCell}
            creatorName={place.creatorName}
            creatorId={place.creatorId}
          ></SavedPlaceCell>
        </div>
      ))}
    </div>
  );
};

export default SavedPlacesList;
