const CLIENT_ID = "OFE0YVZBLRTFJO5PLIZRKOS5ZAG1RAPSZBI1GUGSU5CW5BWM";
const CLIENT_SECRET = "WDJDC2TSBDWADST2EOHZBOIKDZYCNTWTVZ3E3FTBZTZVVPUS";

export function foursquarePlaceSearch(query, location, callback) {
  const request = require("request");

  request(
    {
      url: "https://api.foursquare.com/v2/venues/explore",
      method: "GET",
      qs: {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        ll: location,
        query: query,
        v: "20180323",
        limit: 20,
      },
    },
    callback
  );
}
