import { db } from "../../../index";
export function navSearchBarUserProfileFilter(querySnapshot, inputValue) {
  let searchResults = [];
  querySnapshot.forEach(function (profile) {
    // profile.data() is never undefined for query doc snapshots
    // If search applies to this user, add to search Results
    if (
      (profile.data().username &&
        profile
          .data()
          .username.toLowerCase()
          .includes(inputValue.toLowerCase())) ||
      profile.id.includes(inputValue)
    ) {
      searchResults.push({
        uid: profile.id,
        photoURL: profile.data().photoURL,
        username: profile.data().username,
      });
    }
  });
  return searchResults;
}

export function navSearchBarExistingTripsFilter(querySnapshot, inputValue) {
  let searchResults = [];
  querySnapshot.forEach(function (trip) {
    if (
      trip.data().location &&
      trip.data().location.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      searchResults.push({
        key: trip.data().key,
        displayName: trip.data().location,
        photoUrl: trip.data().photoUrl,
        creatorId: trip.data().creatorId,
        placeId: trip.data().placeId
      });
    }
  });
  return searchResults;
}
