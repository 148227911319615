import React, { Component } from "react";
import firebase from "firebase/compat/app";
import { db, auth } from "../../index";
import { NavLink } from "react-router-dom";
import NavSearchBar from "./navSearchBar";
import NotificationsItem from "./notificationsItem";
import "./navbar.css";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import {
  getUserProfile,
  downloadImage,
  formatImageFileName,
} from "../login/util";
import { addNotificationToUser } from "../../apiRequests/firestoreRequests/addNotificationToUser";
import { isMobile } from "../../utils/mobile-check";
import {
  collection,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import AuthModal from "../login/AuthModal";

class NavBar extends Component {
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  state = {
    isNotificationsDropdownOpen: false,
    isAccountDropdownOpen: false,
    profileUrl: null,
    showAuthModal: false,
  };

  handleNotificationsMouseEntered = () => {
    this.setState({
      isNotificationsDropdownOpen: !this.state.isNotificationsDropdownOpen,
    });
    var userRef = doc(db, "userProfiles", this.props.currentAuthUser.uid);
    updateDoc(userRef, {
      unseenNotificationCount: 0,
    });
  };

  handleNotificationsMouseExit = () => {
    this.setState({
      isNotificationsDropdownOpen: false,
    });
  };

  handleNotificationClicked = () => {
    this.setState({ isNotificationsDropdownOpen: false });
  };

  notificationAccepted = (notification = {}) => {
    if (notification.type == "askToJoinTrip") {
      var tripRef = doc(db, "trips", notification.trip);
      updateDoc(tripRef, {
        users: arrayUnion(notification.askerUid),
      });

      const notificationData = {
        text: auth.currentUser.displayName + " added you to a trip!",
        link: "/trip/" + notification.trip,
        seen: false,
      };

      addNotificationToUser(notification.askerUid, notificationData);
    }
  };

  notificationDeleted = (notification = {}) => {
    var userRef = doc(db, "userProfiles", auth.currentUser.uid);
    console.log("deleting notification for ", auth.currentUser.uid);

    updateDoc(userRef, {
      notifications: arrayRemove(notification),
    });
  };

  accountDropdownHovered = () => {
    this.setState({ isAccountDropdownOpen: true });
  };

  accountDropdownExit = () => {
    this.setState({ isAccountDropdownOpen: false });
  };

  setStateCallback = (url) => {
    this.setState({ profileUrl: url });
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        getUserProfile(user.uid).then((user) => {
          if (user) {
            const imageFileName = formatImageFileName(user.profilePictureUuid);
            downloadImage(imageFileName, this.setStateCallback);
          }
        });
      }
    });
  }

  render() {
    return (
      <nav
        className={
          isMobile()
            ? "navHeader--isMobile navbar sticky-top navbar-light bg-white"
            : "navHeader navbar sticky-top navbar-light bg-white"
        }
      >
        <header>
          <NavLink
            to="/"
            className="navbar-brand"
            href="#"
            style={{ color: "black" }}
            activeStyle={{
              color: "var(--color-primary)",
              fontWeight: "bold",
            }}
          >
            TripClub{" "}
          </NavLink>
        </header>
        {!isMobile() && <NavSearchBar />}
        <div>
          {this.props.isSignedIn &&
          this.props.currentUserData?.notifications ? (
            <div>
              <span>
                <NotificationsItem
                  isOpen={this.state.isNotificationsDropdownOpen}
                  onMouseEnter={this.handleNotificationsMouseEntered}
                  onMouseLeave={this.handleNotificationsMouseExit}
                  currentUserData={this.props.currentUserData}
                  notificationClicked={this.handleNotificationClicked}
                  notificationAccepted={this.notificationAccepted}
                  notificationDeleted={this.notificationDeleted}
                />
              </span>
              <span
                onMouseEnter={this.accountDropdownHovered}
                onMouseLeave={this.accountDropdownExit}
                style={{ minHeight: "60px", margin: "0px 5px" }}
              >
                <NavLink
                  className="rounded-circle"
                  height="45"
                  to={"/user/" + auth.currentUser.uid}
                >
                  <img
                    alt="profile picture"
                    src={
                      this.state.profileUrl ||
                      auth.currentUser.photoURL ||
                      defaultProfPic
                    }
                    className="rounded-circle"
                    height="45"
                    style={{ margin: "0px 15px" }}
                  />
                </NavLink>
                {this.state.isAccountDropdownOpen && (
                  <div className="accountDropdown">
                    <NavLink
                      className="dropdown-item"
                      to={"/user/" + auth.currentUser.uid}
                    >
                      My Account
                    </NavLink>
                    <button
                      className="dropdown-item"
                      onClick={() => auth.signOut()}
                      style={{ color: "red" }}
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </span>
            </div>
          ) : (
            <span>
              <button
                className="navbar-login-button"
                onClick={() =>
                  this.setState({
                    showAuthModal: true,
                  })
                }
              >
                Login/Sign Up
              </button>
            </span>
          )}
        </div>
        {isMobile() && <NavSearchBar />}
        <AuthModal
          isSignedIn={this.props.isSignedIn}
          show={this.state.showAuthModal}
          onHide={() => this.setState({ showAuthModal: false })}
        />
      </nav>
    );
  }
}

export default NavBar;
