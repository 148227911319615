import React, { Component } from "react";
import DayComp from "../dayComp/dayComp";
import UnscheduledList from "./UnscheduledList";
import TripHeader from "./tripHeader";

class Days extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div>
          <UnscheduledList
            savedPlaces={this.props.savedPlaces}
            onCellMouseEnter={this.props.onCellMouseEnter}
            onCellMouseLeave={this.props.onCellMouseLeave}
            onCellClick={this.props.onCellClick}
            deletePlaceClicked={this.props.deletePlaceClicked}
            updatePlaceClicked={this.props.updatePlaceClicked}
            currentAuthUserId={this.props.currentAuthUserId}
            isCurrentUserOnTrip={this.props.isCurrentUserOnTrip}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
          />
          {this.props.days?.map((day, index) => (
            <DayComp
              key={day.key}
              id={day.key}
              index={index.toString()}
              day={day}
              addButtonClicked={false}
              bounds={this.props.bounds}
              onCellMouseEnter={this.props.onCellMouseEnter}
              onCellMouseLeave={this.props.onCellMouseLeave}
              onCellClick={this.props.onCellClick}
              setPlaceTime={this.props.setPlaceTime}
              hoveredCell={this.props.hoveredCell}
              routeBetweenPlacesClicked={this.props.routeBetweenPlacesClicked}
              deletePlaceClicked={this.props.deletePlaceClicked}
              updatePlaceClicked={this.props.updatePlaceClicked}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              currentAuthUserId={this.props.currentAuthUserId}
              isCurrentUserOnTrip={this.props.isCurrentUserOnTrip}
              addPlaceIconClicked={this.props.addPlaceIconClicked}
              trip={this.props.trip}
              addLocationToDate={this.props.addLocationToDate}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Days;
