import { NODE_URL} from "../openAIRequests/openAIDataManager";

export function addNotificationToUser(userId = "", notificationData = {}) {
  fetch(NODE_URL + "/addNotification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      notificationData,
    }),
  })
    .then((response) => {
      console.log("Adding notification response: ", response);
    })
    .catch((error) => {
      console.log("Error adding notification: ", error);
    });
}
