import React, { Component } from "react";
import AddToCalDatePicker from "../addToCalDatePicker";
import DatePicker from "./../../datePicker";
import "./placeDetailsView.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import defaultTripImage from "../../../assets/defaultTripImage.jpeg";
import { ReactComponent as FilledStar } from "../../../assets/icons/star_black_36dp.svg";
import { ReactComponent as UnfilledStar } from "../../../assets/icons/star_border_black_36dp.svg";
import { getStars } from "../../../utils/star-rating-utils";
import { ReactComponent as BeforeIcon } from "../../../assets/icons/navigate_before_black_48dp.svg";
import PhotoCarousel from "./photoCarousel";
import Reviews from "./reviews/reviews.js";
import { toMomentObject } from "react-dates";
import { v4 as uuidv4 } from "uuid";

class PlaceDetailsView extends Component {
  state = {
    isStarred: false,
    time: moment.utc().hour(0).minute(0),
    date: undefined,
    hotelSearchStartDate: null,
    hotelSearchEndDate: null,
  };

  constructor(props) {
    super(props);
    this.addToCalClicked = this.addToCalClicked.bind(this);
  }

  datePickerDateChanged = (date) => {
    this.setState({ date: date });
  };

  datePickerDatesChanged = (hotelSearchStartDate, hotelSearchEndDate) => {
    this.setState({ hotelSearchStartDate, hotelSearchEndDate });
  };

  starLocationClicked = () => {
    if (this.state.isStarred) {
      this.props.unStarLocation(this.props.placeId);
    } else {
      var location = this.props.placeDetails.geometry.location;
      this.props.starLocation(
        this.props.placeId,
        location,
        this.props.placeDetails.name,
        this.props.placeDetails.types?.[0],
      );
    }
    this.setState({ isStarred: !this.state.isStarred });
  };

  addToCalClicked = () => {
    // TODO: Change this later
    if (this.props.placeDetails.types?.[0] == "lodging" && false) {
      console.log(
        "gonna addlocation to dates. hotelSearchStartDate: ",
        this.state.hotelSearchStartDate,
        ". hotelSearchEndDate: ",
        this.state.hotelSearchEndDate,
      );
      var location = this.props.placeDetails.geometry.location;
      this.props.addLocationToDates(
        this.state.hotelSearchStartDate,
        this.state.hotelSearchEndDate,
        this.props.placeId,
        location,
        this.props.placeDetails.name,
      );
      this.props.backButtonPressed();
    } else {
      if (this.state.date) {
        console.log('this.props.photoURL: ', this.props.photoURL);
        this.props.addLocation(
          this.state.date,
          this.props.placeId,
          this.props.placeDetails.geometry.location,
          this.props.placeDetails.name,
          this.props.placeDetails.types?.[0],
          this.state.time,
          0,
          uuidv4(),
          '',
          this.props.photoURL,
        );
      } else {
        // if we didn't put a date, we essentially want to star the place to add it to unscheduled list
        if (!this.state.isStarred) {
          this.starLocationClicked();
        }
      }

      this.props.backButtonPressed();
    }
  };

  onTimeChange = (time) => {
    console.log("on time change: ", time);
    this.setState({ time: moment.utc(time) });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.placeId != this.props.placeId) {
      // TODO: this is a pretty bad way to do this to say the least, but works for now
      this.setState({ isStarred: false });
      this.props.starredPlaces.forEach((place) => {
        if (place.locationData.placeId == this.props.placeId) {
          this.setState({ isStarred: true });
        }
      });
    }
    if (
      this.props.trip?.startDate != prevProps.trip?.startDate ||
      this.props.trip?.endDate != prevProps.trip?.endDate
    ) {
      var startDate = this.props.trip.startDate.toDate();
      var endDate = this.props.trip.endDate.toDate();
      var startMoment = toMomentObject(startDate);
      var endMoment = toMomentObject(endDate);
      this.setState({
        hotelSearchStartDate: startDate,
        hotelSearchEndDate: endDate,
      });
    }
  };

  getStarComponent = () => (
    <div
      className="PlaceDetailsView--star"
      style={{}}
      onClick={this.starLocationClicked}
    >
      {this.state.isStarred ? (
        <FilledStar className="FavoriteStar" />
      ) : (
        <UnfilledStar className="FavoriteStar" />
      )}
    </div>
  );

  componentDidMount() {
    // TODO: this is a pretty bad way to do this to say the least, but works for now
    this.props.starredPlaces.forEach((place) => {
      if (place.locationData.placeId == this.props.placeId) {
        this.setState({ isStarred: true });
      }
    });
    if (this.props.trip) {
      var startDate = this.props.trip.startDate.toDate();
      var endDate = this.props.trip.endDate.toDate();
      var startMoment = toMomentObject(startDate);
      var endMoment = toMomentObject(endDate);
      this.setState({
        hotelSearchStartDate: startDate,
        hotelSearchEndDate: endDate,
      });
    }
  }

  render() {
    if (this.props.placeDetails)
      return (
        <div className="PlaceDetailsView">
          {!this.props.isCurrentUserOnTrip && (
            <div
              onClick={this.props.backButtonPressed}
              className="PlaceDetailsView--backButton"
            >
              ← Back to trip
            </div>
          )}
          <div className="PlaceDetailsView--img-card">
            <img
              className="card-img"
              src={this.props.photoURL || defaultTripImage}
              alt="Card image"
              style={{ height: "300px", width: "100%" }}
            />
            <h5 className="PlaceDetailsView--heading">
              {this.props.placeDetails.name}
            </h5>
            <br />
            <div className="PlaceDetailsView--subtitle">
              <div style={{ color: "white" }}>
                {this.props.placeDetails.vicinity}
              </div>
            </div>
          </div>

          <div className="PlaceDetailsView--contents">
            <div className="PlaceDetailsView--flexbox">
              <div>
                {this.props.placeDetails.rating && (
                  <>
                    <span style={{}}>{this.props.placeDetails.rating}</span>
                    <span style={{ color: "dodgerblue" }}>
                      {getStars(this.props.placeDetails.rating)}{" "}
                      {" (" +
                        this.props.placeDetails["user_ratings_total"] +
                        ")"}
                    </span>
                  </>
                )}

                <div style={{ color: "grey" }}>
                  {this.props.placeDetails.types &&
                  this.props.placeDetails.types[0]
                    ? this.props.placeDetails.types[0].replace(/_/g, " ")
                    : ""}
                </div>
              </div>

              {this.props.placeDetails.rating &&
                this.props.isCurrentUserOnTrip &&
                this.getStarComponent()}
            </div>

            {this.props.hotelSearchData?.price && (
              <div>Price: {this.props.hotelSearchData.price}</div>
            )}
            <div style={{ marginTop: "20px", display: "flex" }}>
              {this.props.isCurrentUserOnTrip &&
                (this.props.placeDetails.types?.[0] == "lodging" && false ? (
                  <DatePicker
                    onDatesChange={this.datePickerDatesChanged}
                    startDate={moment.unix(this.props.trip?.startDate?.seconds)}
                    endDate={moment.unix(this.props.trip?.endDate?.seconds)}
                    datePickerProps={{ keepOpenOnDateSelect: true }}
                    small={true}
                  />
                ) : (
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <AddToCalDatePicker
                        onDateChange={this.datePickerDateChanged}
                        startDate={this.props.trip.startDate}
                        endDate={this.props.trip.endDate}
                        small={true}
                      />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <TimePicker
                        showSecond={false}
                        defaultValue={this.state.time}
                        value={this.state.time}
                        className="xxx"
                        onChange={this.onTimeChange}
                        format="h:mm a"
                        use12Hours
                        inputReadOnly
                        allowEmpty={false}
                        style={{ width: "99px" }}
                      />
                    </div>
                  </div>
                ))}
              {!this.props.placeDetails.rating && this.getStarComponent()}
            </div>
            <div style={{ display: "flex" }}>
              {this.props.isCurrentUserOnTrip && (
                <button
                  className="PlaceDetailsView--button"
                  onClick={this.addToCalClicked}
                >
                  Add to trip
                </button>
              )}
            </div>
          </div>
          <PhotoCarousel photos={this.props.placeDetails.photos} />
          <Reviews
            reviews={this.props.placeDetails.reviews}
            averageRating={this.props.placeDetails.rating}
          />
        </div>
      );

    return (
      <span className="col col-1">
        <button onClick={this.props.backButtonPressed}>X</button>
      </span>
    );
  }
}

export default PlaceDetailsView;
