import React, { Component, useContext } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import Trips from "../homepage/trips";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../userContext";
import { addNotificationToUser } from "../../apiRequests/firestoreRequests/addNotificationToUser";
import { getUserProfile, getProfileImage } from "../login/util";
import { addFollower, removeFollower } from "./util";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import "./profileContainer.css";
import FollowListModal from "./followListModal";
import ProfileCard from "./profileCard";
import { db } from "../../index";
import { isMobile } from "../../utils/mobile-check";

class ProfileContainer extends Component {
  static contextType = UserContext;

  state = {
    user: {},
    trips: [],
    currentUser: {
      following: [],
    },
    uid: "",
    followerCount: "",
    followingCount: "",
    showModal: false,
    followListType: "",
    followerList: [],
    followingList: [],
    followList: [],
    followers: [],
    following: [],
    profileUrl: defaultProfPic,
  };

  componentWillMount() {
    const { uid } = this.props.match.params;
    this.fetchProfileData(uid);
  }

  setStateCallback = (url) => {
    this.setState({ profileUrl: url });
  };

  componentDidMount() {
    const { uid } = this.props.match.params;
    if (uid) {
      getUserProfile(uid).then((user) => {
        getProfileImage(user, this.setStateCallback);
      });
    }
    this.getFollowerCount(uid);
    this.getFollowingCount(uid);
    if (this.props.currentAuthUser) {
      this.fetchCurrentUser(this.props.currentAuthUser.uid);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentAuthUser != this.props.currentAuthUser &&
      this.props.currentAuthUser
    ) {
      this.fetchCurrentUser(this.props.currentAuthUser.uid);
    }
    const { uid } = this.props.match.params;
    if (uid != this.state.uid) {
      this.setState({
        showModal: false,
        followers: [],
        following: [],
      });
      this.fetchProfileData(uid);
      this.fetchCurrentUser(this.props.currentAuthUser.uid);
      this.getFollowerCount(uid);
      this.getFollowingCount(uid);
      if (uid) {
        getUserProfile(uid).then((user) => {
          getProfileImage(user, this.setStateCallback);
        });
      }
    }
  }

  fetchProfileData = async (uid) => {
    this.setState({
      uid,
    });

    // Fetch user
    const docRef = doc(db, "userProfiles", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      this.setState({ user: docSnap.data() });
    } else {
      console.log("No such document!");
    }

    // Fetch trips
    const q = query(
      collection(db, "trips"),
      where("users", "array-contains", uid),
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());
    this.setState({ trips: data });
  };

  fetchCurrentUser = async (uid) => {
    const docRef = doc(db, "userProfiles", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      this.setState({ currentUser: docSnap.data() });
    } else {
      console.log("No such document!");
    }
  };

  followButtonText = () => {
    if (this.props.currentAuthUser) {
      if (
        this.state.currentUser.following &&
        this.state.currentUser.following.includes(this.state.uid)
      ) {
        return "Unfollow";
      }
    }
    return "Follow";
  };

  followButtonClicked = async () => {
    if (this.props.currentAuthUser) {
      // then unfollow
      if (
        this.state.currentUser.following &&
        this.state.currentUser.following.includes(this.state.uid)
      ) {
        const index = this.state.currentUser.following.indexOf(this.state.uid);
        let newFollowingArray = this.state.currentUser.following;
        newFollowingArray.splice(index, 1);

        await updateDoc(
          doc(db, "userProfiles", this.props.currentAuthUser.uid),
          {
            ...this.state.currentUser,
            following: newFollowingArray,
          },
        );

        // removes uid from follower array
        removeFollower(this.state.uid);
      } else {
        // follow them
        // add the uid to the currentUser's following list
        let newFollowingArray = [];
        if (this.state.currentUser.following) {
          newFollowingArray = [
            ...this.state.currentUser.following,
            this.state.uid,
          ];
        } else {
          newFollowingArray = [this.state.uid];
        }

        await updateDoc(
          doc(db, "userProfiles", this.props.currentAuthUser.uid),
          {
            ...this.state.currentUser,
            following: newFollowingArray,
          },
        );

        // adds uid to follower array
        addFollower(this.state.uid);

        // send notification to followed user
        const notificationData = {
          text: this.props.currentUserData.username + " followed you",
          link: "/user/" + this.props.currentAuthUser.uid,
          seen: false,
        };
        addNotificationToUser(this.state.uid, notificationData);
      }
    } else {
      // TODO: Prompt user to sign in/sign up
    }
  };

  getFollowerCount = (uid) => {
    getUserProfile(uid).then((user) => {
      this.setState({
        followerCount: user.followers.length.toString(),
        followerList: user.followers,
      });
      this.state.followerList.forEach((followerUid) => {
        getUserProfile(followerUid).then((user) => {
          user["uid"] = followerUid;
          this.setState((prevState) => ({
            followers: [...prevState.followers, user],
          }));
        });
      });
    });
  };

  getFollowingCount = (uid) => {
    getUserProfile(uid).then((user) => {
      this.setState({
        followingCount: user.following.length.toString(),
        followingList: user.following,
      });
      this.state.followingList.forEach((followingUid) => {
        getUserProfile(followingUid).then((user) => {
          user["uid"] = followingUid;
          this.setState((prevState) => ({
            following: [...prevState.following, user],
          }));
        });
      });
    });
  };

  getProfileCards = () => {
    const listItems =
      this.state.followListType === "following"
        ? this.state.following.map((user) => <ProfileCard user={user} />)
        : this.state.followers.map((user) => <ProfileCard user={user} />);
    return <div>{listItems}</div>;
  };

  render() {
    return (
      <>
        <FollowListModal
          show={this.state.showModal}
          onHide={() =>
            this.setState({
              showModal: false,
              followListType: "",
              followList: [],
            })
          }
          type={this.state.followListType}
          children={this.getProfileCards()}
        />
        <header
          className={
            isMobile()
              ? "profileHeader profileHeader--isMobile"
              : "profileHeader"
          }
        >
          <img
            alt="profile picture"
            src={this.state.profileUrl}
            style={{
              height: "150px",
              ...(isMobile() && { padding: "15px" }),
              ...(!isMobile() && { margin: "0 8% 0 25%" }),
            }}
            className="rounded-circle"
          />

          <div className="headingText">
            <h2>{this.state.user.username}</h2>
            <div className={isMobile() ? "followDiv--isMobile" : "followDiv"}>
              <p
                className="followers"
                onClick={() =>
                  this.setState({
                    followListType: "followers",
                    followList: this.state.followerList,
                    showModal: true,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                {this.state.followerCount} followers
              </p>
              <p
                className="following"
                onClick={() =>
                  this.setState({
                    followListType: "following",
                    followList: this.state.followingList,
                    showModal: true,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                {this.state.followingCount} following
              </p>
            </div>

            {this.props.currentAuthUser &&
            this.state.uid == this.props.currentAuthUser.uid ? (
              <NavLink
                to={"/editProfile/" + this.props.currentAuthUser.uid}
                className="profile-container-btn"
              >
                Edit Profile
              </NavLink>
            ) : (
              <button
                onClick={() => this.followButtonClicked()}
                className="profile-container-btn"
              >
                {this.followButtonText()}
              </button>
            )}
          </div>
        </header>
        <hr class="horizontal-line" />
        <div style={{ width: "75%", margin: "0 auto" }}>
          <Trips trips={this.state.trips} />
        </div>
      </>
    );
  }
}

export default ProfileContainer;
