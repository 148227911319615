import React, { Component, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./addPlacesModal";

function AddPlacesModal(props) {
  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.type}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="addPlacesModal" style={{ padding: "0" }}>
        <div>{props.children}</div>
      </Modal.Body>
    </Modal>
  );
}

export default AddPlacesModal;
