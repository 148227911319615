import React from "react";
import { NavLink } from "react-router-dom";
import SearchDropdownProfileCard from "./SearchDropdownProfileCard";
import clsx from "clsx";
import SearchDropdownTripCard from "./searchDropdownTripCard";

import "./navSearchDropDown.css";

const NavSearchDropdown = (props) => {
  return (
    <div
      className={clsx(
        "searchDropdown",
        !props.locationSearchResults[0] &&
          !props.userSearchResults[0] &&
          !props.searchValue &&
          "searchDropdown--noSearchTerm"
      )}
    >
      {props.userSearchResults?.[0] && (
        <div className="searchDropdownCategory">Users</div>
      )}
      <div className="searchDropdown--content">
        {props.userSearchResults.map((user) => (
          <div onClick={props.handleResultClick}>
            <SearchDropdownProfileCard user={user} />
          </div>
        ))}
      </div>

      {props.locationSearchResults?.[0] && (
        <div className="searchDropdownCategory">Existing Trips</div>
      )}
      <div className="searchDropdown--content">
        {props.locationSearchResults.map((trip) => (
          <SearchDropdownTripCard trip={trip} />
        ))}
      </div>
      {!props.locationSearchResults?.[0] &&
        !props.userSearchResults?.[0] &&
        props.hasReceivedResults && <div>No results found.</div>}
    </div>
  );
};
export default NavSearchDropdown;
