import React, { useEffect, useState } from "react";
import defaultProfPic from "../../../assets/default_prof_pic.jpg";
import { getUserProfile } from "../../login/util";
import "./notesBlock.css";

const NotesBlock = ({ userId, note }) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    getUserData();
  }, [userId]);

  const getUserData = async () => {
    const user = await getUserProfile(userId);
    if (user) {
      setUsername(user.username);
      setPhotoUrl(user.photoURL);
      console.log("set the username: ", user.username);
    }
  };

  return (
    <div>
      <img
        alt="profile picture"
        src={photoUrl || defaultProfPic}
        className="rounded-circle"
        height="30"
        style={{ margin: "0px 15px" }}
      />
      <span>{username + " "}</span>
      <span className="NotesBlock--note">{note}</span>
    </div>
  );
};

export default NotesBlock;
