import React, { Component } from "react";
import { db, auth } from "../../index";
import { doc, updateDoc, arrayUnion, onSnapshot } from "firebase/firestore";
import Days from "./days/days";
import firebase from "firebase/compat/app";
import ReactMap from "./reactMap";
import PlaceDetailsView from "./placeDetailsView/placeDetailsView";
import SearchList from "./searchList";
import TripHeader from "./days/tripHeader";
import { DragDropContext } from "react-beautiful-dnd";
import RoutesView from "./routesView/routesView";
import PlacesSearchBox from "./shared/searchBox/placesSearchBox";
import SavedPlacesList from "./savedPlacesList";
import {
  googlePlaceDetailsRequest,
  googlePlaceDetailsRequestForPhotos,
  googlePlaceSearchRequest,
  googlePlacesNearbySearchRequest,
} from "../../apiRequests/googlePlaceDetailsRequest";
import { getBestAspectPhoto } from "../../utils/photo-utils";
import axios from "axios";
import { toMomentObject } from "react-dates";
import "./tripView.css";
import PlacesAutocomplete from "./shared/placesAutocomplete";
import { v4 as uuidv4 } from "uuid";
import DefaultButton from "../sharedComponents/defaultButton/defaultButton";
import defaultTripImage from "../../assets/defaultTripImage.jpeg";
import { isMobile } from "../../utils/mobile-check";
import clsx from "clsx";
import MapToggle from "./mapToggle";
import AuthModal from "../login/AuthModal";
import SignUpToolTip from "./shared/signUpToolTip/signUpToolTip";

const PHOTO_ASPECT_RATIO = 1.466;
const SEARCH_PHOTO_ASPECT_RATIO = 1.3;

class TripView extends Component {
  state = {
    trip: undefined,
    location: undefined,
    days: [],
    places: [],
    searchMarkers: [],
    resultPlaces: [],
    bounds: undefined,
    hoveredCell: undefined,
    placeDetailsViewId: undefined,
    map: undefined,
    id: "",
    directionsResponse: {},
    waypointNames: [],
    routeDetailsIndex: undefined,
    activeRouteIndex: 0,
    isSearchingForLodging: false,
    searchBoxValue: "",
    hotelSearchLocation: "",
    placeDetails: {},
    placeDetailsViewPhotoURL: "",
    ratingsFilter: undefined,
    priceFilter: undefined,
    hotelSearchMinPrice: "",
    hotelSearchMaxPrice: "",
    numGuestsForSearch: "1",
    showSavedPlaces: false,
    hotelSearchStartMoment: null,
    hotelSearchEndMoment: null,
    hotelSearchClickedIndex: null,
    tripViewImagePhotoURL: "",
    categorySearched: "",
    queryTries: 0,
    updateMapCount: 0,
    mapToggled: false,
    showAuthModal: false,
    showSignUpToolTip: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    console.log("WINDOW MOBILE CHECK: ", isMobile());

    if (!id.includes("temp_trip")) {
      this.fetchTripData(id);
    } else {
      this.setState({ trip: this.props.location?.state?.trip });
    }
    const tempTripId = localStorage.getItem("temp-trip-id");
    if (tempTripId && tempTripId == id) {
      this.setState({ isTempTrip: true });
    }
    setTimeout(
      () => this.setState({ showSignUpToolTip: !auth.currentUser && tempTripId && tempTripId == id && !isMobile() }),
      // wait 4 seconds before showing log in tool tip
      4000,
    );
  }

  componentDidUpdate(prevProps) {
    if (this.state.trip && !this.state.location) {
      this.setState((state) => ({
        location: state.trip.geoLoc,
      }));
    }
    const { id } = this.props.match.params;
    if (id != this.state.id) {
      this.fetchTripData(id);
    }
  }

  mapTogglePressed = () => {
    this.setState({ mapToggled: !this.state.mapToggled });
  };

  showTripViewColumn = () => {
    return !isMobile() || !this.state.mapToggled;
  };

  showMap = () => {
    return !isMobile() || this.state.mapToggled;
  };

  fetchTripData = (id) => {
    this.setState({ id });
    this.fetchTripDataUnsubscribe = onSnapshot(doc(db, "trips", id), (doc) => {
      let trip = doc.data();
      // sort each days' places by time
      if (trip && trip.days) {
        trip.days.forEach((day) => {
          day.places.sort((a, b) => {
            if (!a.time || !b.time) {
              return 0;
            }
            return a.time.seconds - b.time.seconds;
          });
        });
      }
      this.setState({ trip: trip });
      if (trip?.placeId && !this.state.tripViewImagePhotoURL) {
        this.getTripViewImage(trip.placeId);
      }
    });
  };

  addLocationToDates = async (
    startMoment,
    endMoment,
    placeId,
    location,
    name,
  ) => {
    const key = uuidv4();
    var endDate = new Date(endMoment);
    for (
      var firstDay = true, dateToPlace = new Date(startMoment);
      dateToPlace.toISOString().slice(0, 10) <=
      endMoment.toISOString().slice(0, 10);
      firstDay = false
    ) {
      dateToPlace.setUTCHours(12, 0, 0, 0);
      this.addLocationToDate(
        new Date(dateToPlace),
        placeId,
        location,
        name,
        "hotel",
        null,
        firstDay ? 1 : 2,
        key,
      );
      dateToPlace.setUTCDate(dateToPlace.getUTCDate() + 1);
    }
  };

  // hotelDaySignifier: 0 == not on multiple days, 1 == first of multiple days, 2 == not first day
  addLocationToDate = async (
    date,
    placeId,
    location,
    name,
    type = "",
    time,
    hotelDaySignifier = 0,
    key = uuidv4(),
    description = "",
    photoURL = "",
  ) => {
    var dateToPlace = new Date(date);
    console.log("adding location to date");

    dateToPlace.setUTCHours(dateToPlace.getUTCHours() - 1); // set default time to 11am
    if (hotelDaySignifier == 2) {
      dateToPlace.setUTCHours(dateToPlace.getUTCHours() - 11); // set default time to 0 for consecutive hotel days
    }
    //get day based on date
    for (var i = 0; i < this.state.trip.days.length; i++) {
      const d1 = new Date(this.state.trip.days[i].date.seconds * 1000);
      console.log("dateToPlace: ", dateToPlace, " . d1: ", d1);
      if (
        d1.getUTCFullYear() === dateToPlace.getUTCFullYear() &&
        d1.getUTCMonth() === dateToPlace.getUTCMonth() &&
        d1.getUTCDate() === dateToPlace.getUTCDate()
      ) {
        var day = this.state.trip.days[i];
        var dayIndex = i;
      }
    }
    // if we have a time, adjust the time of the date object
    if (time) {
      var timeDate = time.toDate();
      dateToPlace.setUTCHours(timeDate.getUTCHours());
      dateToPlace.setUTCMinutes(timeDate.getUTCMinutes());
    }

    // if we have a photoURL, create a placePhoto object with the photoURL and an expiration date in 10 days
    var placePhoto = {};
    console.log("photoURL to upload: ", photoURL);
    if (photoURL) {
      placePhoto = {
        photoURL: photoURL,
        expirationDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      };
    }

    if (day) {
      const { id } = this.props.match.params; //ID in URL
      const tripRef = doc(db, "trips", id);

      var places = day.places;

      places.push({
        key,
        time: dateToPlace,
        hotelDaySignifier: hotelDaySignifier,
        locationData: {
          placeId: placeId,
          geoLoc: new firebase.firestore.GeoPoint(
            location.lat(),
            location.lng(),
          ),
          name: name,
        },
        type: type,
        creatorId: auth.currentUser?.uid || "",
        creatorName: this.props.currentUserData?.username || "",
        notes: {},
        placePhoto: placePhoto,
        description: description,
      });

      var updatedDays = this.state.trip.days;
      updatedDays[dayIndex].places = places;

      // update the trip document with the new days array
      updateDoc(tripRef, {
        days: updatedDays,
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else {
      console.log("Error: Day not found!");
    }
  };

  deletePlaceClicked = (deleteHash) => {
    console.log("delete Place Clicked");

    this.state.trip.days.forEach((day, dayIndex) => {
      day.places.forEach((place) => {
        if (place.key === deleteHash) {
          var updatedDays = this.state.trip.days;
          updatedDays[dayIndex].places = day.places.filter(
            (place) => place.key !== deleteHash,
          );

          const { id } = this.props.match.params; // ID in URL
          var tripRef = doc(db, "trips", id);

          // update the trip document with the new days array
          updateDoc(tripRef, {
            days: updatedDays,
          })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        }
      });
    });
    // check to see if the hash is in unscheduled places
    this.state.trip.starredPlaces.forEach((place) => {
      if (place.key === deleteHash) {
        const { id } = this.props.match.params; // ID in URL
        updateDoc(doc(db, "trips", id), {
          starredPlaces: this.state.trip.starredPlaces.filter(
            (place) => place.key !== deleteHash,
          ),
        })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
    });
  };

  updatePlaceClicked = (hash, notes = {}, newDateTime, photoURL) => {
    this.state.trip.days.forEach((day, dayIndex) => {
      day.places.forEach((place, index) => {
        if (place.key === hash) {
          var tempPlace = place;
          if (notes) {
            tempPlace.notes = notes;
          }
          if (photoURL) {
            tempPlace.placePhoto = {
              photoURL: photoURL,
              expirationDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
            };
          }
          const { id } = this.props.match.params; // ID in URL

          if (newDateTime) {
            // calculate difference in days between new Date and current
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const currentDate = new Date(place.time.seconds * 1000);
            const newDate = newDateTime.toDate();

            const diffDays = Math.round((newDate - currentDate) / oneDay);
            // find new day
            //get day based on date
            for (var i = 0; i < this.state.trip.days.length; i++) {
              const d1 = new Date(this.state.trip.days[i].date.seconds * 1000);
              if (
                d1.getFullYear() === newDate.getFullYear() &&
                d1.getMonth() === newDate.getMonth() &&
                d1.getDate() === newDate.getDate()
              ) {
                var newDay = this.state.trip.days[i];
                var newDayIndex = i;
              }
            }
            if (newDay) {
              currentDate.setDate(currentDate.getDate() + diffDays);
              tempPlace.time = currentDate;

              let updatedDays = this.state.trip.days;

              // remove place from current day
              updatedDays[dayIndex].places.splice(index, 1);

              // add place to new Day
              updatedDays[newDayIndex].places.push(tempPlace);

              updateDoc(doc(db, "trips", id), {
                days: updatedDays,
              });
            }
          } else {
            let updatedDays = this.state.trip.days;

            // update place within days then upload to server
            updatedDays[dayIndex].places.splice(index, 1, tempPlace);
            updateDoc(doc(db, "trips", id), {
              days: updatedDays,
            });
          }
        }
      });
    });
  };

  savedPlacesClicked = () => {
    this.setState({
      showSavedPlaces: true,
    });
  };

  addStarredLocation = async (placeId, location, name, type) => {
    const { id } = this.props.match.params; // ID in URL
    var docRef = doc(db, "trips", id);

    var places = this.state.trip?.starredPlaces;
    if (!places) places = [];

    places.push({
      locationData: {
        //address: address,
        placeId: placeId,
        geoLoc: new firebase.firestore.GeoPoint(location.lat(), location.lng()),
        name: name,
      },
      creatorId: auth.currentUser?.uid || "",
      creatorName: this.props.currentUserData?.username || "",
      notes: {},
      type: type,
      key: uuidv4(),
    });

    try {
      await updateDoc(docRef, {
        starredPlaces: arrayUnion({
          locationData: {
            placeId: placeId,
            geoLoc: new firebase.firestore.GeoPoint(
              location.lat(),
              location.lng(),
            ),
            name: name,
          },
          creatorId: auth.currentUser?.uid || "",
          creatorName: this.props.currentUserData?.username || "",
          notes: {},
          type: type,
          key: uuidv4(),
        }),
      });
    } catch {
      console.log("Error adding starred location!");
    }

    var trip = this.state.trip;
    trip.starredPlaces = places;
    this.setState({
      trip,
    });
  };

  removeStarredLocation = async (placeId) => {
    const { id } = this.props.match.params; //ID in URL

    var places = this.state.trip?.starredPlaces;

    if (!places) places = [];

    places = places.filter((place) => place.locationData.placeId !== placeId);

    var tripRef = doc(db, "trips", id);
    // update the trip document with the new value for starredPlaces
    updateDoc(tripRef, {
      starredPlaces: places,
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });

    var updatedTrip = { ...this.state.trip };
    updatedTrip.starredPlaces = places;
    this.setState({ trip: updatedTrip });
  };

  calculateAndDisplayRoute = (
    directionsService,
    directionsRenderer,
    origin,
    destination,
    originName,
    destinationName,
  ) => {
    console.log(
      "trying to route from origin: ",
      origin,
      " to destination: ",
      destination,
    );
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        // Note that Javascript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        // @ts-ignore
        travelMode: "DRIVING",
        provideRouteAlternatives: true,
      },
      (response, status) => {
        if (status == "OK") {
          directionsRenderer.setDirections(response);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            draggable: true,
          });
          this.setState({
            directionsResponse: response,
            waypointNames: [originName, destinationName],
          });
          console.log("setting directions, response: ", response);
          console.log(
            "originName: ",
            originName,
            " destination Name:",
            destinationName,
          );
        } else {
          window.alert("Directions request failed due to " + status);
        }
      },
    );
  };

  // Place Details Callback for placeDetailsView
  placeDetailsCallback = (place, status) => {
    if (status == "OVER_QUERY_LIMIT" && this.state.queryTries < 4) {
      // retry after 2 seconds to see if it's just the short term query limit
      this.setState({ queryTries: this.state.queryTries + 1 });
      setTimeout(() => {
        console.log("retrying placeDetailsRequest in placeDetailsView");
        googlePlaceDetailsRequest(
          this.state.placeDetailsViewId,
          this.placeDetailsCallback,
        );
      }, 2000);
    } else if (place) {
      const prevSearchBoxValue = this.state.searchBoxValue;
      this.setState({
        placeDetails: place,
        searchBoxValue: place?.name,
        categorySearched: prevSearchBoxValue,
      });
      if (place.photos) {
        this.setState({
          placeDetailsViewPhotoURL: getBestAspectPhoto(
            place.photos,
            PHOTO_ASPECT_RATIO,
          ).getUrl(),
        });
      }
    }
    console.error(
      "Didnt receive place from placeDetailsCallback, status: ",
      status,
    );
  };

  tripViewImageCallback = (place, status) => {
    if (place) {
      if (place.photos) {
        this.setState({
          tripViewImagePhotoURL: getBestAspectPhoto(
            place.photos,
            PHOTO_ASPECT_RATIO,
          ).getUrl(),
        });
      }
    }
  };

  isCurrentUserOnTrip = () => {
    if (!Array.isArray(this.state.trip.users)) {
      return false;
    }
    if (this.state.trip?.users?.includes(this.props.currentAuthUserId)) {
      return true;
    }
    return false;
  };

  // piping functions

  updateBounds = (bounds) => {
    this.setState({ bounds: bounds });
  };

  updateMap = (map) => {
    this.setState((prevState) => ({
      map: map,
      updateMapCount: prevState.updateMapCount + 1,
    }));
  };

  onCellMouseEnter = (placeId) => {
    this.setState({ hoveredCell: placeId });
  };

  onCellMouseLeave = (placeId) => {
    if (this.state.hoveredCell == placeId) {
      this.setState({ hoveredCell: undefined });
    }
  };

  onCellClick = (
    placeId,
    placeDetails,
    noGooglePlaceId,
    name,
    index,
    latlong,
  ) => {
    if (noGooglePlaceId) {
      console.error("noGooglePlaceId onCellClick");
      var request = {
        query: name,
        fields: ["place_id"],
      };
      if (latlong) {
        request.locationBias = latlong;
      }
      googlePlaceSearchRequest(request, (results, status) => {
        if (results) {
          var placeId = results[0].place_id;
          this.setState({
            placeDetailsViewId: placeId,
            hotelSearchClickedIndex: index,
          });
          googlePlaceDetailsRequest(placeId, this.placeDetailsCallback);
        }
      });
    } else {
      this.setState({ placeDetailsViewId: placeId });
      if (placeDetails) {
        const prevSearchBoxValue = this.state.searchBoxValue;
        this.setState({
          placeDetails: placeDetails,
          searchBoxValue: placeDetails?.name,
          categorySearched: prevSearchBoxValue,
        });
        if (placeDetails.photos) {
          this.setState({
            placeDetailsViewPhotoURL: getBestAspectPhoto(
              placeDetails.photos,
              PHOTO_ASPECT_RATIO,
            ).getUrl(),
          });
        }
      } else {
        googlePlaceDetailsRequest(placeId, this.placeDetailsCallback);
      }
    }
  };

  onMarkerMouseEnter = (placeId) => {
    this.setState({ hoveredCell: placeId });
  };

  onMarkerMouseLeave = (placeId) => {
    if (this.state.hoveredCell == placeId) {
      this.setState({ hoveredCell: undefined });
    }
  };

  onMarkerClick = (placeId, noGooglePlaceId, name, index) => {
    if (noGooglePlaceId) {
      console.error("noGooglePlaceId onMarkerClick");
      var request = {
        query: name,
        fields: ["place_id"],
      };
      googlePlaceSearchRequest(request, (results, status) => {
        if (results) {
          var placeId = results[0].place_id;
          this.setState({
            placeDetailsViewId: placeId,
            hotelSearchClickedIndex: index,
          });
          googlePlaceDetailsRequest(placeId, this.placeDetailsCallback);
        }
      });
    } else {
      this.setState({ placeDetailsViewId: placeId });
      googlePlaceDetailsRequest(placeId, this.placeDetailsCallback);
    }
  };

  backButtonPressed = (
    searchBoxValue = "",
    resultPlaces = [],
    searchMarkers = [],
  ) => {
    this.setState({
      placeDetailsViewId: undefined,
      hotelSearchClickedIndex: undefined,
      placeDetails: {},
      placeDetailsViewPhotoURL: "",
      isSearchingForLodging: false,
      showSavedPlaces: false,
      searchBoxValue: searchBoxValue,
      searchMarkers: searchMarkers,
      resultPlaces: resultPlaces,
    });
  };

  routeBackButtonPressed = () => {
    if (this.state.directionsRenderer) {
      this.state.directionsRenderer.setMap(null);
    }
    this.setState({
      directionsResponse: {},
      routeDetailsIndex: undefined,
    });
  };

  routeBetweenPlacesClicked = (dayIndex, placeIndex) => {
    if (this.state.map) {
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      const directionsService = new window.google.maps.DirectionsService();
      this.setState({ directionsRenderer, directionsService });

      directionsRenderer.setMap(this.state.map);

      const dayPlaces = this.state.trip.days[dayIndex].places;

      const originGeoLoc = {
        lat: dayPlaces[placeIndex].locationData.geoLoc._lat,
        lng: dayPlaces[placeIndex].locationData.geoLoc._long,
      };
      const destinationGeoLoc = {
        lat: dayPlaces[placeIndex + 1].locationData.geoLoc._lat,
        lng: dayPlaces[placeIndex + 1].locationData.geoLoc._long,
      };

      this.calculateAndDisplayRoute(
        directionsService,
        directionsRenderer,
        originGeoLoc,
        destinationGeoLoc,
        dayPlaces[placeIndex].locationData.name,
        dayPlaces[placeIndex + 1].locationData.name,
      );
    }
  };

  directionsPlaceChanged = (place, index) => {
    const placeGeoLoc = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    console.log("directionsPlaceChanged place: ", place);
    if (index == 0) {
      this.calculateAndDisplayRoute(
        this.state.directionsService,
        this.state.directionsRenderer,
        placeGeoLoc,
        this.state.directionsResponse.request.destination,
      );
    } else {
      this.calculateAndDisplayRoute(
        this.state.directionsService,
        this.state.directionsRenderer,
        this.state.directionsResponse.request.origin,
        placeGeoLoc,
      );
    }
  };

  routeCardClicked = (route, index) => {
    this.setState({
      activeRouteIndex: index,
    });
    this.state.directionsRenderer.setDirections(this.state.directionsResponse);
    this.state.directionsRenderer.setOptions({
      suppressMarkers: true,
      routeIndex: index,
    });
  };

  routeDetailsClicked = (route, index) => {
    this.state.directionsRenderer.setPanel(
      document.getElementById("detailsPanel" + index),
    );
    this.state.directionsRenderer.setDirections(this.state.directionsResponse);
    this.state.directionsRenderer.setOptions({
      suppressMarkers: true,
      routeIndex: index,
    });
    this.setState({ routeDetailsIndex: index });
  };

  searchWithLodgingTerm = () => {
    var startDate = this.state.trip.startDate.toDate();
    var endDate = this.state.trip.endDate.toDate();
    var startMoment = toMomentObject(startDate);
    var endMoment = toMomentObject(endDate);
    this.setState({
      hotelSearchStartMoment: startMoment,
      hotelSearchEndMoment: endMoment,
      numGuestsForSearch: this.state.trip.users.length,
      hotelSearchLocation: this.state.trip.location,
      isSearchingForLodging: true,
    });
    this.lodgingSearch(
      this.state.trip.location,
      this.state.numGuestsForSearch,
      "2",
      this.state.trip.destinationId,
      startMoment,
      endMoment,
    );
  };

  placesSearchResultsReceived = (results) => {
    if (results) {
      var markers = [];
      var resultPlaces = [];

      //******** */TODO make it so place info and photo URLs from place search stored in state also

      var allLodging = true;
      for (var i = 0; i < results.length; i++) {
        if (results[i].types && !results[i].types.includes("lodging")) {
          allLodging = false;
        }
        if (results[i].photos) {
          if (results[i].photos[0]) {
            resultPlaces[i] = {
              placeId: results[i].place_id,
              photoUrl: getBestAspectPhoto(
                results[i].photos,
                SEARCH_PHOTO_ASPECT_RATIO,
              ).getUrl(),
              name: results[i].name,
              rating: results[i].rating,
              ratingsTotal: results[i].user_ratings_total,
              type: results[i].types[0],
            };
          }
        } else {
          resultPlaces[i] = {
            placeId: results[i].place_id,
            name: results[i].name,
            rating: results[i].rating,
            ratingsTotal: results[i].user_ratings_total,
          };
        }

        markers[i] = {
          key: i,
          id: i,
          placeId: results[i].place_id,
          lat: results[i].geometry.location.lat(),
          lng: results[i].geometry.location.lng(),
        };
      }
      if (results.length == 1) {
        this.setState({
          searchMarkers: markers,
          placeDetailsViewId: results[0].place_id,
          isSearchingForLodging: false,
        });
        googlePlaceDetailsRequest(
          results[0].place_id,
          this.placeDetailsCallback,
        );
      } else {
        this.setState({
          searchMarkers: markers,
          resultPlaces,
          isSearchingForLodging: false, // isSearchingForLodging: allLodging
        });
        if (false) {
          // (allLodging)
          var startDate = this.state.trip.startDate.toDate();
          var endDate = this.state.trip.endDate.toDate();
          var startMoment = toMomentObject(startDate);
          var endMoment = toMomentObject(endDate);
          this.setState({
            hotelSearchStartMoment: startMoment,
            hotelSearchEndMoment: endMoment,
            numGuestsForSearch: this.state.trip.users.length,
            hotelSearchLocation: this.state.trip.location,
          });
          this.lodgingSearch(
            this.state.trip.location,
            this.state.numGuestsForSearch,
            "2",
            this.state.trip.destinationId,
          );
        } else {
          this.setState({
            hotelSearchStartMoment: null,
            hotelSearchEndMoment: null,
          });
        }
      }
    }
  };

  formatMomentString = (moment) => {
    var date = moment.toDate();
    var month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  lodgingSearch = (
    location,
    numGuests = this.state.numGuestsForSearch,
    minRating = "2",
    destinationId,
    startMoment,
    endMoment,
  ) => {
    console.log("Lodging search, destinationId: ", destinationId);
    if (!destinationId) {
      const hotelsApiOptions = {
        method: "GET",
        url: "https://hotels4.p.rapidapi.com/locations/search",
        params: { query: location, locale: "en_US" },
        headers: {
          "x-rapidapi-key":
            "d438685712msh3f1f47209db99aap1ea279jsnb8d7c9e71e44",
          "x-rapidapi-host": "hotels4.p.rapidapi.com",
        },
      };
      axios
        .request(hotelsApiOptions)
        .then((response) => {
          if (response.data.suggestions[0].entities) {
            if (response.data.suggestions[0].entities[0].destinationId) {
              console.log(
                "destinationID: ",
                response.data.suggestions[0].entities[0].destinationId,
              );
              destinationId =
                response.data.suggestions[0].entities[0].destinationId;
              this.lodgingSearchApiCall(
                numGuests,
                minRating,
                destinationId,
                startMoment,
                endMoment,
              );
            }
          } else {
            console.error("No axios results hotels api");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      this.lodgingSearchApiCall(
        numGuests,
        minRating,
        destinationId,
        startMoment,
        endMoment,
      );
    }
  };

  lodgingSearchApiCall = (
    numGuests = this.state.numGuestsForSearch,
    minRating = "2",
    destinationId,
    startMoment,
    endMoment,
  ) => {
    const options = {
      method: "GET",
      url: "https://hotels4.p.rapidapi.com/properties/list",
      params: {
        destinationId: destinationId,
        pageNumber: "2",
        checkIn: this.formatMomentString(
          startMoment || this.state.hotelSearchStartMoment,
        ),
        checkOut: this.formatMomentString(
          endMoment || this.state.hotelSearchEndMoment,
        ),
        pageSize: "25",
        adults1: numGuests,
        currency: "USD",
        locale: "en_US",
        sortOrder: "DISTANCE_FROM_LANDMARK",
        guestRatingMin: minRating,
        priceMin: this.state.hotelSearchMinPrice || "1",
        // need a max value for min to work <shrug emoji>
        priceMax: this.state.hotelSearchMaxPrice
          ? this.state.hotelSearchMaxPrice
          : "1000000",
      },
      headers: {
        "x-rapidapi-key": "d438685712msh3f1f47209db99aap1ea279jsnb8d7c9e71e44",
        "x-rapidapi-host": "hotels4.p.rapidapi.com",
      },
    };

    axios
      .request(options)
      .then((response) => {
        console.log(response.data.data.body);
        var resultPlaces = [];
        var markers = [];

        response.data.data.body.searchResults.results.map((result, i) => {
          resultPlaces[i] = {
            placeId: result.id,
            name: result.name,
            photoUrl: result.optimizedThumbUrls?.srpDesktop,
            rating:
              (result.guestReviews.unformattedRating * 5.0) /
              result.guestReviews.scale,
            ratingsTotal: result.guestReviews.total,
            price: result.ratePlan ? result.ratePlan.price.current : "",
            starRating: result.starRating,
            noGooglePlaceId: true,
          };

          markers[i] = {
            key: i,
            id: i,
            placeId: result.id,
            lat: result.coordinate.lat,
            lng: result.coordinate.lon,
            noGooglePlaceId: true,
            name: result.name,
          };
        });
        this.setState({
          searchMarkers: markers,
          resultPlaces,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  hotelSearchDatesChanged = (hotelSearchStartMoment, hotelSearchEndMoment) => {
    this.setState({ hotelSearchStartMoment, hotelSearchEndMoment });
    this.lodgingSearch(
      this.state.hotelSearchLocation,
      this.state.numGuestsForSearch,
      this.state.ratingsFilter * 2.0 + "",
    );
  };

  filterByRatings = (value) => {
    this.setState({ ratingsFilter: value });
    if (this.state.isSearchingForLodging) {
      // Hotels API uses ratings out of 10 but we use ratings out of 5 so convert, also uses strings not ints
      this.lodgingSearch(
        this.state.hotelSearchLocation,
        this.state.numGuestsForSearch,
        value * 2.0 + "",
      );
    }
  };

  filterByPrice = (value) => {
    this.setState({ priceFilter: value });
    if (this.state.isSearchingForLodging) {
      // Hotels API uses ratings out of 10 but we use ratings out of 5 so convert, also uses strings not ints
      this.lodgingSearch(
        this.state.hotelSearchLocation,
        this.state.numGuestsForSearch,
        this.state.ratingsFilter * 2.0 + "",
        value + "",
      );
    }
  };

  hotelSearchMinPriceChanged = (price) => {
    if (price === "0") {
      this.setState({ hotelSearchMinPrice: "" });
    } else {
      this.setState({ hotelSearchMinPrice: price });
    }
    this.lodgingSearch(
      this.state.hotelSearchLocation,
      this.state.numGuestsForSearch,
      this.state.ratingsFilter * 2.0 + "",
    );
  };

  hotelSearchMaxPriceChanged = (price) => {
    if (price.slice(-1) === "+") {
      this.setState({ hotelSearchMaxPrice: "" });
    } else {
      this.setState({ hotelSearchMaxPrice: price });
    }
    this.lodgingSearch(
      this.state.hotelSearchLocation,
      this.state.numGuestsForSearch,
      this.state.ratingsFilter * 2.0 + "",
    );
  };

  numGuestsSelectedForSearch = (value) => {
    this.setState({ numGuestsForSearch: value + "" });
    if (this.state.isSearchingForLodging) {
      this.lodgingSearch(
        this.state.hotelSearchLocation,
        value + "",
        this.state.ratingsFilter * 2.0 + "",
      );
    }
  };

  foursquareSearchResultsReceived = (results) => {
    var resultPlaces = [];
    var markers = [];

    results.groups[0].items.map((result, i) => {
      resultPlaces[i] = {
        placeId: result.venue.id,
        name: result.venue.name,
        noGooglePlaceId: true,
        latlong: new window.google.maps.LatLng(
          result.venue.location.lat,
          result.venue.location.lng,
        ),
      };

      markers[i] = {
        key: i,
        id: i,
        lat: result.venue.location.lat,
        lng: result.venue.location.lng,
        noGooglePlaceId: true,
        name: result.venue.name,
      };
    });
    this.setState({
      searchMarkers: markers,
      resultPlaces,
    });
  };

  searchValueChanged = (value) => {
    if (value == "") {
      this.setState({
        searchBoxValue: value,
        placeDetailsViewId: null,
        hotelSearchClickedIndex: undefined,
        placeDetails: {},
        placeDetailsViewPhotoURL: "",
        searchMarkers: [],
        resultPlaces: [],
        isSearchingForLodging: false,
      });
    } else {
      this.setState({
        searchBoxValue: value,
      });
    }
    if (value == "JSON now") {
      console.log(JSON.stringify(this.state.trip));
    }
  };

  hotelSearchLocationValueChanged = (value) => {
    this.setState({
      hotelSearchLocation: value,
    });
  };

  hotelSearchLocationChanged = (value) => {
    this.lodgingSearch(
      value,
      this.state.numGuestsForSearch,
      this.state.ratingsFilter * 2.0 + "",
    );
  };

  searchBackButtonPressed = () => {
    this.setState({ searchMarkers: [], resultPlaces: [] });
  };

  setPlaceTime = (dayIndex, placeIndex, time) => {
    let dayPlaces = this.state.trip.days[dayIndex].places;
    // the time we get is not on the correct day, so let's take our date and just set the hours and minutes from the time
    var currentPlaceDate = dayPlaces[placeIndex].time.toDate();
    currentPlaceDate.setUTCHours(time.toDate().getUTCHours());
    currentPlaceDate.setUTCMinutes(time.toDate().getUTCMinutes());
    currentPlaceDate.setUTCSeconds(time.toDate().getUTCSeconds());

    dayPlaces[placeIndex].time = currentPlaceDate;
    const { id } = this.props.match.params; //ID in URL

    let updatedDays = this.state.trip.days;
    updatedDays[dayIndex].places = dayPlaces;

    var tripRef = doc(db, "trips", id);
    // update the trip document with the new days array
    updateDoc(tripRef, {
      days: updatedDays,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    this.setState({
      trip: { ...this.state.trip, days: updatedDays },
    });
  };

  onPlaceCellDragEnd = (result) => {
    const { destination, source, draggableId } = result; // draggableId === placeId

    if (!destination) {
      return;
    }
    // if the drag didn't move the object, do nothing
    if (
      (destination.droppableId === source.droppableId &&
        destination.index === source.index) ||
      (destination.droppableId == "Unscheduled" &&
        source.droppableId == "Unscheduled")
    ) {
      return;
    }

    if (destination.droppableId == "Unscheduled") {
      let days = this.state.trip.days;
      let sourceDayPlaces = days[source.droppableId].places;
      let destinationPlaces = this.state.trip.starredPlaces;

      destinationPlaces.splice(
        destination.index,
        0,
        sourceDayPlaces[source.index],
      );
      sourceDayPlaces.splice(source.index, 1);

      // set in local state also so there's no bounce
      days[source.droppableId].places = sourceDayPlaces;

      // unset time for starredPlaces
      destinationPlaces[destination.index].time = null;

      var updatedTrip = { ...this.state.trip };
      updatedTrip.starredPlaces = destinationPlaces;

      this.setState({ days, trip: updatedTrip });

      // upload to database
      const { id } = this.props.match.params; // ID in URL

      var tripRef = doc(db, "trips", id);
      // update the trip document with the new days array and starredPlaces
      updateDoc(tripRef, {
        days: days,
        starredPlaces: destinationPlaces,
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      return;
    } else if (source.droppableId == "Unscheduled") {
      let days = this.state.trip.days;
      let sourcePlaces = this.state.trip.starredPlaces;
      let destinationPlaces = days[destination.droppableId].places;

      destinationPlaces.splice(
        destination.index,
        0,
        sourcePlaces[source.index],
      );
      sourcePlaces.splice(source.index, 1);

      // set the time
      destinationPlaces[destination.index].time =
        new firebase.firestore.Timestamp();
      // if there was previously a place at destination.index, this will be before that
      if (destinationPlaces[destination.index + 1]) {
        if (destinationPlaces[destination.index - 1]) {
          destinationPlaces[destination.index].time.seconds =
            ((destinationPlaces[destination.index + 1].time.seconds +
              destinationPlaces[destination.index - 1].time.seconds) /
              2) |
            0;
        } else {
          destinationPlaces[destination.index].time.seconds =
            destinationPlaces[destination.index + 1].time.seconds - 1;
        }
      } else {
        if (destinationPlaces[destination.index - 1]) {
          destinationPlaces[destination.index].time.seconds =
            destinationPlaces[destination.index - 1].time.seconds + 1;
        } else {
          // this will be the only place on this day
          var timeToPlace = days[destination.droppableId].date;
          timeToPlace.seconds = timeToPlace.seconds + 39600; // add 11 hours to set time to 11am as default

          console.log(
            "THIS will be only place on this day, timeToPlace: ",
            timeToPlace,
          );
          destinationPlaces[destination.index].time = timeToPlace;
        }
      }

      // set in local state also so there's no bounce
      days[destination.droppableId].places = destinationPlaces;

      var updatedTrip = { ...this.state.trip };
      updatedTrip.starredPlaces = sourcePlaces;
      updatedTrip.days = days;
      this.setState({ trip: updatedTrip });

      // upload to database
      const { id } = this.props.match.params; // ID in URL

      var tripRef = doc(db, "trips", id);
      // update the trip document with the new days array and starredPlaces
      updateDoc(tripRef, {
        days: days,
        starredPlaces: sourcePlaces,
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      return;
    }

    // if placed on the same day
    if (destination.droppableId === source.droppableId) {
      let dayPlaces = this.state.trip.days[destination.droppableId].places;
      // take the average of the times of the places the place is placed between,
      // if there is no place on one side, just add or subtract a second
      if (source.index < destination.index) {
        dayPlaces[source.index].time.seconds = dayPlaces[destination.index + 1]
          ? ((dayPlaces[destination.index + 1].time.seconds +
              dayPlaces[destination.index].time.seconds) /
              2) |
            0
          : dayPlaces[destination.index].time.seconds + 1;
      } else {
        dayPlaces[source.index].time.seconds = dayPlaces[destination.index - 1]
          ? ((dayPlaces[destination.index - 1].time.seconds +
              dayPlaces[destination.index].time.seconds) /
              2) |
            0
          : dayPlaces[destination.index].time.seconds - 1;
      }

      // construct the updated days array
      var updatedDays = this.state.trip.days;
      updatedDays[destination.droppableId].places = dayPlaces;

      const { id } = this.props.match.params; // ID in URL

      var tripRef = doc(db, "trips", id);
      // update the trip document with the new value for days
      updateDoc(tripRef, {
        days: updatedDays,
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      this.setState({ days: updatedDays });
    } else {
      let days = this.state.trip.days;
      let destinationDayPlaces = days[destination.droppableId].places;
      let sourceDayPlaces = days[source.droppableId].places;
      destinationDayPlaces.splice(
        destination.index,
        0,
        sourceDayPlaces[source.index],
      );
      sourceDayPlaces.splice(source.index, 1);

      // if there was previously a place at destination.index, this will be before that
      if (destinationDayPlaces[destination.index + 1]) {
        if (destinationDayPlaces[destination.index - 1]) {
          destinationDayPlaces[destination.index].time.seconds =
            ((destinationDayPlaces[destination.index + 1].time.seconds +
              destinationDayPlaces[destination.index - 1].time.seconds) /
              2) |
            0;
        } else {
          destinationDayPlaces[destination.index].time.seconds =
            destinationDayPlaces[destination.index + 1].time.seconds - 1;
        }
      } else {
        if (destinationDayPlaces[destination.index - 1]) {
          destinationDayPlaces[destination.index].time.seconds =
            destinationDayPlaces[destination.index - 1].time.seconds + 1;
        } else {
          // this will be the only place on this day
          var timeToPlace = days[destination.droppableId].date;
          timeToPlace.seconds = timeToPlace.seconds + 39600; // add 11 hours to set time to 11am as default

          console.log(
            "THIS will be only place on this day, timeToPlace: ",
            timeToPlace,
          );
          destinationDayPlaces[destination.index].time = timeToPlace;
        }
      }
      // set in local state also so there's no bounce
      days[source.droppableId].places = sourceDayPlaces;
      days[destination.droppableId].places = destinationDayPlaces;
      this.setState({ days });

      const { id } = this.props.match.params; // ID in URL

      var tripRef = doc(db, "trips", id);
      // update the trip document with the new value for days
      updateDoc(tripRef, {
        days: days,
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    }
  };

  addPlaceIconClicked = (searchType) => {
    // Focus on the search bar
    document.getElementById("pac-input").focus();

    this.setState({ searchBoxValue: searchType });

    if (searchType !== "") {
      if (false) {
        // commented out (searchType === "Hotels")
        this.searchWithLodgingTerm();
      } else {
        this.setState({
          hotelSearchStartMoment: null,
          hotelSearchEndMoment: null,
        });
        var request = {
          keyword: searchType,
          fields: ["place_id", "name", "formatted_address"],
          location: {
            lat: this.state.map.getCenter().lat(),
            lng: this.state.map.getCenter().lng(),
          },
          rankBy: window.google.maps.places.RankBy.DISTANCE,
        };
        // if nothing returns let's try our own google place nearby search
        googlePlacesNearbySearchRequest(request, (results, status) => {
          if (results.length !== 0) {
            console.log(
              "custom place nearbySearch request, results: ",
              results,
            );
            this.placesSearchResultsReceived(results);
          }
        });
      }
    }
  };

  getTripViewImage = (placeId) => {
    googlePlaceDetailsRequestForPhotos(placeId, this.tripViewImageCallback);
  };

  componentWillUnmount() {
    if (this.fetchTripDataUnsubscribe) {
      this.fetchTripDataUnsubscribe();
    }
  }

  renderMap() {
    return (
      <>
        {this.showMap() && (
          <div
            className={clsx(
              "MapContainer",
              isMobile() && "MapContainer--isMobile",
            )}
          >
            <ReactMap
              onMarkerClick={this.onMarkerClick}
              onMarkerMouseEnter={this.onMarkerMouseEnter}
              onMarkerMouseLeave={this.onMarkerMouseLeave}
              location={this.state.location}
              days={this.state.trip?.days}
              starredPlaces={this.state.trip?.starredPlaces}
              searchMarkers={this.state.searchMarkers}
              trip={this.state.trip}
              placeId={this.state.trip?.placeId}
              updateBounds={this.updateBounds}
              updateMap={this.updateMap}
              hoveredCell={this.state.hoveredCell}
            />
          </div>
        )}
        {isMobile() && (
          <MapToggle
            togglePressed={this.mapTogglePressed}
            mapToggled={this.state.mapToggled}
          />
        )}
      </>
    );
  }

  render() {
    const { id } = this.props.match.params;
    if (this.state.trip)
      return (
        <div>
          <div className={ isMobile() ? "SignUpToolTip--container--isMobile" : "SignUpToolTip--container" }>
            <SignUpToolTip isSignedIn={this.props.currentAuthUserId} show={this.state.showSignUpToolTip} onHide={() => this.setState({showSignUpToolTip: false})} signUp={() => this.setState({showAuthModal: true})}/>
          </div>
          <AuthModal isSignedIn={this.props.currentAuthUserId} show={this.state.showAuthModal} onHide={() => this.setState({showAuthModal: false})}/>
          <div className="flex-container">
            {this.showTripViewColumn() &&
              (!id.includes("temp_trip") ? (
                <div
                  className={clsx(
                    "TripViewColumn",
                    isMobile() && "TripViewColumn--isMobile",
                  )}
                >
                  {!this.state.isSearchingForLodging &&
                    (this.isCurrentUserOnTrip() || this.state.isTempTrip) && (
                      <PlacesSearchBox
                        bounds={this.state.bounds}
                        searchResultsReceived={this.placesSearchResultsReceived}
                        searchBoxValue={this.state.searchBoxValue}
                        searchValueChanged={this.searchValueChanged}
                        searchWithLodgingTerm={this.searchWithLodgingTerm}
                        hasClearButton={
                          this.state.placeDetailsViewId ||
                          this.state.resultPlaces[0]
                        }
                        center={this.state.location}
                        location={this.state.trip.location}
                        foursquareSearchResultsReceived={
                          this.foursquareSearchResultsReceived
                        }
                        floatingSearchBox={true}
                        backToListResults={() =>
                          this.backButtonPressed(
                            this.state.categorySearched,
                            this.state.resultPlaces,
                            this.state.searchMarkers,
                          )
                        }
                        isOnSearch={
                          this.state.categorySearched ||
                          this.state.resultPlaces?.length > 0
                        }
                        placeDetailsViewId={this.state.placeDetailsViewId}
                      />
                    )}

                  {!this.state.placeDetailsViewId && (
                    <div style={{ position: "relative" }}>
                      <img
                        className="card-img"
                        src={
                          this.state.tripViewImagePhotoURL || defaultTripImage
                        }
                        alt="Card image"
                        style={{ height: "300px", width: "100%" }}
                      />
                      <TripHeader
                        trip={this.state.trip}
                        loadingDays={!this.state.trip.days}
                        savedPlacesClicked={this.savedPlacesClicked}
                        currentUsername={this.props.currentUserData?.username}
                        savedPlaces={this.state.trip?.starredPlaces}
                        isCurrentUserOnTrip={this.isCurrentUserOnTrip()}
                        notSearchPage={!this.state.resultPlaces[0]}
                        showAuthModal={() => this.setState({showAuthModal: true})}
                      />
                    </div>
                  )}

                  {!this.state.directionsResponse.routes &&
                    !this.state.showSavedPlaces && (
                      <div>
                        {this.state.isSearchingForLodging && (
                          <>
                            <DefaultButton onClick={this.backButtonPressed}>
                              <div className="SavedPlacesList--backButton">
                                ← Back to trip
                              </div>
                            </DefaultButton>
                            <PlacesAutocomplete
                              bounds={this.state.bounds}
                              searchBoxValue={this.state.hotelSearchLocation}
                              searchValueChanged={
                                this.hotelSearchLocationValueChanged
                              }
                              searchValueSelected={
                                this.hotelSearchLocationChanged
                              }
                              hasClearButton={
                                this.state.placeDetailsViewId ||
                                this.state.resultPlaces[0]
                              }
                            />
                          </>
                        )}
                      </div>
                    )}
                  {!this.state.trip.days ? (
                    <div className="flex-container">
                      <div className="left-panel">
                        <div className="loading-screen">
                          <h2 className="loading-message">
                            Generating your trip...
                          </h2>
                          <div className="loading-subtext">
                            This will take 1-2 minutes at most.
                          </div>
                          <div className="spinner"></div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.placeDetailsViewId ? (
                    <PlaceDetailsView
                      placeId={this.state.placeDetailsViewId}
                      placeDetails={this.state.placeDetails}
                      photoURL={this.state.placeDetailsViewPhotoURL}
                      starredPlaces={this.state.trip?.starredPlaces}
                      trip={this.state.trip}
                      addLocation={this.addLocationToDate}
                      addLocationToDates={this.addLocationToDates}
                      starLocation={this.addStarredLocation}
                      unStarLocation={this.removeStarredLocation}
                      datePickerDatesChanged={this.hotelSearchDatesChanged}
                      hotelSearchStartDate={this.state.hotelSearchStartMoment}
                      hotelSearchEndDate={this.state.hotelSearchEndMoment}
                      hotelSearchData={
                        this.state.hotelSearchClickedIndex &&
                        this.state.resultPlaces[
                          this.state.hotelSearchClickedIndex
                        ]
                      }
                      isCurrentUserOnTrip={
                        this.isCurrentUserOnTrip() || this.state.isTempTrip
                      }
                      backButtonPressed={this.backButtonPressed}
                      backToListResults={() =>
                        this.backButtonPressed(
                          this.state.categorySearched,
                          this.state.resultPlaces,
                          this.state.searchMarkers,
                        )
                      }
                      prevCategorySearched={this.state.categorySearched}
                    />
                  ) : this.state.showSavedPlaces ? (
                    <SavedPlacesList
                      savedPlaces={this.state.trip?.starredPlaces}
                      onCellMouseEnter={this.onCellMouseEnter}
                      onCellMouseLeave={this.onCellMouseLeave}
                      onCellClick={this.onCellClick}
                      backButtonPressed={this.backButtonPressed}
                    />
                  ) : this.state.directionsResponse.routes ? (
                    <RoutesView
                      waypoints={
                        this.state.directionsResponse.geocoded_waypoints
                      }
                      request={this.state.directionsResponse.request}
                      routes={this.state.directionsResponse.routes}
                      bounds={this.state.bounds}
                      waypointNames={this.state.waypointNames}
                      backButtonPressed={this.routeBackButtonPressed}
                      directionsPlaceChanged={this.directionsPlaceChanged}
                      routeDetailsClicked={this.routeDetailsClicked}
                      routeDetailsIndex={this.state.routeDetailsIndex}
                      routeCardClicked={this.routeCardClicked}
                      activeRouteIndex={this.state.activeRouteIndex}
                    />
                  ) : this.state.resultPlaces[0] ? (
                    <SearchList
                      places={this.state.resultPlaces}
                      onCellMouseEnter={this.onCellMouseEnter}
                      onCellMouseLeave={this.onCellMouseLeave}
                      onCellClick={this.onCellClick}
                      backButtonPressed={this.searchBackButtonPressed}
                    />
                  ) : (
                    <DragDropContext onDragEnd={this.onPlaceCellDragEnd}>
                      <Days
                        days={this.state.trip.days}
                        addLocation={this.addLocation}
                        bounds={this.state.bounds}
                        onCellMouseEnter={this.onCellMouseEnter}
                        onCellMouseLeave={this.onCellMouseLeave}
                        onCellClick={this.onCellClick}
                        setPlaceTime={this.setPlaceTime}
                        hoveredCell={this.state.hoveredCell}
                        routeBetweenPlacesClicked={
                          this.routeBetweenPlacesClicked
                        }
                        Submitted={this.placesSearchResultsReceived}
                        deletePlaceClicked={this.deletePlaceClicked}
                        updatePlaceClicked={this.updatePlaceClicked}
                        startDate={this.state.trip.startDate}
                        endDate={this.state.trip.endDate}
                        currentAuthUserId={this.props.currentAuthUserId}
                        isCurrentUserOnTrip={
                          this.isCurrentUserOnTrip() || this.state.isTempTrip
                        }
                        addPlaceIconClicked={this.addPlaceIconClicked}
                        trip={this.state.trip}
                        savedPlacesClicked={this.savedPlacesClicked}
                        currentUsername={this.props.currentUserData?.username}
                        savedPlaces={this.state.trip?.starredPlaces}
                        addLocationToDate={this.addLocationToDate}
                      />
                    </DragDropContext>
                  )}
                </div>
              ) : (
                <div className="FixedHeightContainer col-sm-4 no-gutters">
                  <div className="signed-out-column">
                    <div className="signed-out-text">
                      Looks like you don't have a logged in account...
                    </div>
                    <div className="signed-out-text">
                      Login to add places to your trip!
                    </div>
                    <button
                      className="login-button"
                      onClick={() => this.setState({ showAuthModal: true })}
                    >
                      Login/Sign up
                    </button>
                  </div>
                </div>
              ))}
            {this.renderMap()}
          </div>
        </div>
      );
    return <div></div>;
  }
}

export default TripView;
