import React, { Component } from "react";
import "./marker.css";
import clsx from "clsx";

class Marker extends Component {
  state = {
    hover: false,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.hoveredCell != prevProps.hoveredCell) {
      if (this.props.hoveredCell == this.props.placeId) {
        this.setState({ hover: true });
      } else {
        this.setState({ hover: false });
      }
    }
  };

  onClick() {
    this.props.onClick(
      this.props.placeId,
      this.props.noGooglePlaceId,
      this.props.name,
      this.props.index
    );
  }

  onMarkerMouseEnter = () => {
    this.props.onMarkerMouseEnter(this.props.placeId);
  };

  onMarkerMouseLeave = () => {
    this.props.onMarkerMouseLeave(this.props.placeId);
  };

  render() {
    return (
      <div
        className={clsx(
          "marker",
          this.props.hoveredCell == this.props.placeId && "marker-hover",
          this.props.isStarred && "marker-isStarred"
        )}
        onClick={this.onClick}
        onMouseEnter={this.onMarkerMouseEnter}
        onMouseLeave={this.onMarkerMouseLeave}
      ></div>
    );
  }
}

export default Marker;
