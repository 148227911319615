import React, { useState } from "react";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import { ReactComponent as HollowHeartSVG } from "./../../../assets/icons/favorite_border_black_24dp.svg";
import { ReactComponent as FilledHeartSVG } from "./../../../assets/icons/favorite_black_24dp.svg";
import "./likesItem.css";
import './shareTripButton.css'
import firebase from 'firebase/compat/app';
import FollowListModal from "../../profileContainer/followListModal";
import ProfileCard from "../../profileContainer/profileCard";
import { auth } from "../../../index";

const LikesItem = ({
  likes = [],
  likeTrip = () => {},
  unlikeTrip = () => {},
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleLikeClicked = () => {
    if (!auth.currentUser) {
      return;
    }
    if (likes.includes(auth.currentUser?.uid)) {
      unlikeTrip();
    } else {
      likeTrip();
    }
  };

  const getProfileCards = () => {
    const listItems = likes.map((userId) => <ProfileCard userId={userId} />);
    return <div>{listItems}</div>;
  };

  return (
    <div>
      <FollowListModal
        show={showModal}
        onHide={() => setShowModal(false)}
        type={"Likes"}
        children={getProfileCards()}
      />
      <DefaultButton>
        <div className="ShareTripButton">
          <span>
            <span>
            {likes.includes(auth.currentUser?.uid) ? (
              <FilledHeartSVG
                className="LikesItemSVG"
                onClick={handleLikeClicked}
              />
            ) : (
              <HollowHeartSVG
                className="LikesItemSVG"
                onClick={handleLikeClicked}
              />
            )}
            </span>
            <span
              className="ShareTripButton--shareText"
              style={{position:'relative'}}
              onClick={() => setShowModal(!showModal)}
            >
              {likes.length} Likes
            </span>
          </span>
        </div>
      </DefaultButton>
    </div>
  );
};

export default LikesItem;
