import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import defaultTripImage from "../../assets/defaultTripImage.jpeg";
import FollowListModal from "../profileContainer/followListModal";
import ProfileCard from "../profileContainer/profileCard";
import { getUserProfile } from "../login/util";

import "./tripCard.css";

class TripCard extends Component {
  state = {
    photoURL: undefined,
    showModal: false,
    creatorUsername: null,
  };

  startDate = () => (
    <>
      {this.props.startDate.toDate().getMonth() + 1}/
      {this.props.startDate.toDate().getDate()}
    </>
  );
  endDate = () => (
    <>
      {this.props.endDate.toDate().getMonth() + 1}/
      {this.props.endDate.toDate().getDate()}
    </>
  );

  getPlaceCardImage = (photos) => {
    const tripCardAspectRatio = 0.85714285714;
    let bestAspectRatioDiff = 10000;
    let bestPhoto;
    photos.forEach((photo) => {
      const currAspectRatioDiff = Math.abs(
        tripCardAspectRatio - photo.width / photo.height
      );
      if (currAspectRatioDiff <= bestAspectRatioDiff) {
        bestPhoto = photo;
        bestAspectRatioDiff = currAspectRatioDiff;
      }
    });
    this.setState({
      photoURL: bestPhoto.getUrl(),
    });
  };

  callback = (place, status) => {
    this.setState({ placeDetails: place });
    if (place && place.photos) {
      this.getPlaceCardImage(place.photos);
    }
  };

  googlePlaceDetailsRequest(placeID) {
    var request = {
      placeId: placeID,
      fields: [
        "photos",
      ],
    };

    if (!window.google) {
      throw new Error(
        "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    var service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails(request, this.callback);
  }

  componentDidMount() {
    this.googlePlaceDetailsRequest(this.props.placeId);
  }

  componentDidUpdate() {
    if (this.props.creatorId && !this.state.creatorUsername) {
      getUserProfile(this.props.creatorId).then((user) => {
        this.setState({
          creatorUsername: user?.username,
        });
      });
    }
  }

  getProfileCards = () => {
    const listItems = this.props.likes.map((userId) => (
      <ProfileCard userId={userId} />
    ));
    return <div>{listItems}</div>;
  };

  render() {
    return (
      <div style={{ padding: "5px", maxWidth: "400px" }}>
        <FollowListModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          type={"Likes"}
          children={this.getProfileCards()}
        />
        <NavLink
          to={"/trip/" + this.props.id}
          style={{ color: "white", padding: "5px" }}
          activeStyle={{ color: "white" }}
        >
          <div
            className="card bg-light text-white"
            style={{ height: "350px", maxWidth: "400px" }}
          >
            <img
              className="card-img"
              src={this.state.photoURL ? this.state.photoURL : defaultTripImage}
              alt="Card image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </NavLink>
        <div className="TripCard--title" style={{ marginTop: "-15px" }}>
          <b>{this.props.location}</b> - ({this.startDate()}, {this.endDate()})
        </div>
        <NavLink to={"/user/" + this.props.creatorId}>
          <div className="TripCard--creator">
            created by {this.state.creatorUsername}
          </div>
        </NavLink>
        <div
          className="TripCard--likes"
          onClick={() => this.setState({ showModal: true })}
        >
          {this.props.likes.length === 1
            ? "1 like"
            : `${this.props.likes.length} likes`}
        </div>
      </div>
    );
  }
}

export default TripCard;
