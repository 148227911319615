import React from "react";
import "./confirmationPill.css";

const ConfirmationPill = ({ text, showPill }) => {
  return showPill ? (
    <div className="ConfirmationPill--save--pill">{text}</div>
  ) : null;
};

export default ConfirmationPill;
