import React, { Component } from "react";
import {
  getDocs,
  collection,
  where,
  query,
  orderBy,
  onSnapshot,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import { db, analytics, auth } from "../../index";
import { logEvent } from "firebase/analytics";
import firebase from "firebase/compat/app";
import Trips from "./trips";
import { v4 as uuidv4 } from "uuid";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Redirect } from "react-router-dom";
import { getBestAspectPhoto } from "../../utils/photo-utils";
import { getTrip } from "../../utils/trip-util";
import {
  enrichJsonWithPlaceIds,
  createRecommendedTrip,
} from "../../apiRequests/openAIRequests/openAIDataManager";
import "./AiHome.css";
import hawaii from "../../assets/hawaii.jpg";
import italy from "../../assets/italy.jpg";
import oldwest from "../../assets/old-west.jpg";
import GenerateTripForm from "./generateTripForm";
import { isMobile } from "../../utils/mobile-check";

const PHOTO_ASPECT_RATIO = 1;
const FEATURED_TRIP_IDS = [];

class AiHome extends Component {
  state = {
    error: undefined,
    myTrips: [],
    feedTrips: [],
    geoLoc: undefined,
    placeDetails: undefined,
    tripRef: "",
    heroImage: undefined,
    featuredTrips: [],
  };

  constructor(props) {
    super(props);
    this.googlePlaceDetailsRequestForTripPhoto = this.googlePlaceDetailsRequestForTripPhoto.bind(this);
    this.callback = this.callback.bind(this);
  }

  createTrip = async (e) => {
    e.preventDefault();

    logEvent(analytics, "create_trip", {
      user: auth.currentUser?.uid,
    });
    const location = e.target.elements.locationSearch.value;
    const startDate = e.target.elements.startDate.value;
    const endDate = e.target.elements.endDate.value;
    const notes = e.target.elements.notes.value;
    var placeId;

    if (location && startDate && endDate) {
      // perform call to firebase and update State
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);

      await geocodeByAddress(location)
        .then((results) => {
          placeId = results[0].place_id;
          return getLatLng(results[0]);
        })
        .then((latLng) => this.setState({ geoLoc: latLng }))
        .catch((error) => console.error("Error", error));

      let newTripRefId;
      let creatorId;
      var newTripRef = doc(db, "trips", uuidv4());
      newTripRefId = newTripRef.id;
      if (auth.currentUser) {
        creatorId = auth.currentUser.uid;
      } else {
        creatorId = "temp-creator-id";
        localStorage.setItem("temp-trip-id", newTripRefId);
      }

      const tripGeoLoc = new firebase.firestore.GeoPoint(
        this.state.geoLoc.lat,
        this.state.geoLoc.lng,
      );

      const tripData = {
        key: newTripRefId,
        creatorId: creatorId,
        location: location,
        name: "Trip to " + location,
        startDate: sDate,
        endDate: eDate,
        geoLoc: tripGeoLoc,
        placeId: placeId,
        starredPlaces: [],
        users: [creatorId],
        likes: [],
        description: "",
        ...(!auth.currentUser && { tempTrip: true }),
      };

      createRecommendedTrip(
        location,
        { startDate: sDate, endDate: eDate },
        notes,
        newTripRefId,
      ).then((recommendedDays) => {
        logEvent(analytics, "create_trip_JSON_responded", {
          user: auth.currentUser?.uid,
          recommendedDays: recommendedDays,
        });
      });
      this.setState({ tripRef: newTripRef.id });

      // Get place details with placeId
      this.googlePlaceDetailsRequestForTripPhoto(placeId);

      setDoc(newTripRef, tripData, { merge: true });
    } else {
      this.setState({
        error: "Please enter the values.",
      });
      console.log("there was an error");
    }
  };

  componentDidMount() {
    const heroImages = [italy, hawaii, oldwest];
    const index = Math.floor(Math.random() * heroImages.length);
    this.setState({ heroImage: heroImages[index] });
  }

  componentWillMount() {
    FEATURED_TRIP_IDS.forEach((id) => {
      getTrip(id).then((trip) => {
        if (trip) {
          this.setState({
            featuredTrips: [...this.state.featuredTrips, trip],
          });
        }
      });
    });

    if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      const q = query(
        collection(db, "trips"),
        where("users", "array-contains", uid),
      );

      getDocs(q)
        .then((querySnapshot) => {
          const myTrips = querySnapshot.docs.map((doc) => doc.data());
          this.setState({ myTrips });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    if (
      this.props.currentUserData &&
      this.props.currentUserData &&
      this.props.currentUserData.following &&
      this.props.currentUserData.following[0]
    ) {
      const q = query(
        collection(db, "trips"),
        where("creatorId", "in", this.props.currentUserData.following),
        orderBy("startDate"),
      );

      onSnapshot(q, (querySnapshot) => {
        const feedTrips = [];
        querySnapshot.forEach((doc) => {
          feedTrips.push(doc.data());
        });
        this.setState({ feedTrips });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentAuthUser !== this.props.currentAuthUser &&
      this.props.currentAuthUser
    ) {
      const uid = this.props.currentAuthUser.uid;
      const q = query(
        collection(db, "trips"),
        where("users", "array-contains", uid),
      );

      getDocs(q)
        .then((querySnapshot) => {
          const myTrips = querySnapshot.docs.map((doc) => doc.data());
          this.setState({ myTrips });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
    if (
      prevProps.currentUserData !== this.props.currentUserData &&
      this.props.currentUserData &&
      this.props.currentUserData.following &&
      this.props.currentUserData.following[0]
    ) {
      const q = query(
        collection(db, "trips"),
        where("creatorId", "in", this.props.currentUserData.following),
        orderBy("startDate"),
      );

      onSnapshot(q, (querySnapshot) => {
        const feedTrips = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ feedTrips });
      });
    }
  }

  callback = (place, status) => {
    this.setState({ placeDetails: place });
    if (place.photos) {
      if (!this.state.tripRef) {
        console.log("no trip ref");
      }
      const tripRef = doc(db, "trips", this.state.tripRef);
      updateDoc(tripRef, {
        photoUrl: getBestAspectPhoto(place.photos, PHOTO_ASPECT_RATIO).getUrl(),
      });
    }

    //route to trip
    this.setState({ redirectToTrip: this.state.tripRef });
  };

  googlePlaceDetailsRequestForTripPhoto(placeID) {
    var request = {
      placeId: placeID,
      fields: [
        "photos",
      ],
    };

    if (!window.google) {
      throw new Error(
        "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library",
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library",
      );
    }

    var service = new window.google.maps.places.PlacesService(
      document.createElement("div"),
    );
    service.getDetails(request, this.callback);
  }

  render() {
    if (this.state.redirectToTrip) {
      return <Redirect to={"/trip/" + this.state.redirectToTrip} />;
    }
    return (
      <>
        <div className="Aihero-container">
          <div
            className={
              isMobile() ? "MobileHero-form-container" : "Aitrip-form-container"
            }
          >
            <GenerateTripForm createTrip={this.createTrip} />
          </div>
          {!isMobile() && (
            <div className="Aiimage-container">
              <img style={{ width: "100%" }} src={this.state.heroImage} />
            </div>
          )}
        </div>
        <div className="Aicontent-container">
          {this.state.featuredTrips[0] && (
            <h5 className="Aitrip-heading">Featured Trips</h5>
          )}
          <div className="Aitrip-carousel-container">
            <Trips trips={this.state.featuredTrips} />
          </div>

          {this.state.myTrips[0] && (
            <>
              <h5 className="Aitrip-heading">My Trips</h5>
              <div className="Aitrip-carousel-container">
                <Trips trips={this.state.myTrips} />
              </div>
            </>
          )}
          {this.state.feedTrips[0] && (
            <>
              <h5 className="Aitrip-heading">Trip Feed</h5>
              <div className="Aitrip-carousel-container">
                <Trips trips={this.state.feedTrips} />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default AiHome;
