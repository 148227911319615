import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as NextIcon } from "../../../assets/icons/navigate_next_black_48dp.svg";
import { ReactComponent as BeforeIcon } from "../../../assets/icons/navigate_before_black_48dp.svg";

function PhotoModal(props) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 37:
          // left arrow key
          props.setIndexLeft(props.imageIndex);
          break;
        case 39:
          // right arrow key
          props.setIndexRight(props.imageIndex);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.imageIndex, props.setIndexLeft, props.setIndexRight]);

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ padding: "0" }}>
        <BeforeIcon
          onClick={() => props.setIndexLeft(props.imageIndex)}
          className="PhotoCarousel--arrow"
          style={{ position: "absolute", left: "-51px" }}
        />
        <img style={{ maxWidth: "498px" }} src={props.images[props.imageIndex]} alt="carousel" />
        <NextIcon
          onClick={() => props.setIndexRight(props.imageIndex)}
          className="PhotoCarousel--arrow"
          style={{ position: "absolute" }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default PhotoModal;
