import React from "react";
import "./waypointsBox.css";
import DirectionsSearchBox from "./directionsSearchBox";

const WaypointsBox = ({
  waypoints = [],
  waypointNames = [],
  bounds,
  directionsPlaceChanged,
}) => {
  return (
    <div className="waypointsBox">
      {waypoints.map((waypoint, index) => (
        <DirectionsSearchBox
          key={index}
          index={index}
          className="waypointInput"
          initialValue={waypointNames[index]}
          bounds={bounds}
          directionsPlaceChanged={directionsPlaceChanged}
        />
      ))}
    </div>
  );
};

export default WaypointsBox;
