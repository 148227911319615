import React, { useEffect, useState } from "react";

import { getStars } from "../../../../utils/star-rating-utils";
import ProgressMeter from "../../shared/progressMeter/progressMeter";
import ReviewModal from "./reviewModal";
import "./reviews.css";

const Reviews = ({ reviews, averageRating }) => {
  const [showModal, setShowModal] = useState(false);
  const [reviewMapping, setReviewMapping] = useState({});

  useEffect(() => {
    let avgRating = 0;
    let mapping = {};
    if (reviews) {
      reviews.forEach((review) => {
        const rating = review["rating"];
        const roundedRating = Math.round(rating);
        avgRating += rating;

        if (!mapping[roundedRating]) {
          mapping[roundedRating] = 1;
        } else {
          mapping[roundedRating] += 1;
        }
      });
      setReviewMapping(mapping);
    }
  }, []);

  return reviews ? (
    <>
      <ReviewModal
        reviews={reviews}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div className="Reviews--container">
        <div className="Reviews--line" />
        <div className="Reviews--title">Review summary</div>
        <div className="Reviews--summary--container">
          <div>
            {[5, 4, 3, 2, 1].map((starRating) => (
              <div>
                <ProgressMeter
                  star={starRating}
                  percentFilled={
                    reviewMapping[starRating]
                      ? reviewMapping[starRating] / reviews.length
                      : 0.1
                  }
                />
              </div>
            ))}
          </div>
          <div style={{ paddingLeft: "80px", textAlign: "center" }}>
            <h1 className="Reviews--avg--rating">{averageRating}</h1>
            <div className="Reviews--star--rating">
              {getStars(averageRating)}
            </div>
          </div>
        </div>

        {reviews.slice(0, 3).map((review) => (
          <div className="Review--container">
            <img
              src={review["profile_photo_url"]}
              className="Reviews--author--img"
            />
            {review["text"]}
          </div>
        ))}
        <div
          className="Reviews--modal--button"
          onClick={() => setShowModal(true)}
        >
          See more reviews
        </div>
      </div>
    </>
  ) : null;
};

export default Reviews;
