import React, { Component } from "react";
import "./App.css";
import NavBar from "./components/navHeader/navbar";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from "./components/login/login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Error from "./components/error";
import TripView from "./components/tripView/tripView";
import SearchResultsPage from "./components/searchResultsPage";
import NoTripExistsError from "./components/tripView/noTripExistsError";
import ProfileContainer from "./components/profileContainer/profileContainer";
import EditProfileView from "./components/profilePage/editProfileView";
import { UserContext } from "./userContext";
import { db } from "./index";
import { doc, getDoc } from "firebase/firestore";
import AiHome from "./components/homepage/AiHome";
import { setUserProperties } from "@firebase/analytics";
import { analytics } from "./index";

class App extends Component {
  state = {
    isSignedIn: false,
    currentAuthUser: null, // firebase auth() version of user
    currentUserData: null, // Our stored user data in firestore database
  };

  constructor() {
    super();
  }

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.setState({
        isSignedIn: !!user, // true if user exists
        currentAuthUser: user,
      });
      if (user) {
        getDoc(doc(db, "userProfiles", user.uid))
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              this.setState({ currentUserData: docSnapshot.data() });
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document", error);
          });
          setUserProperties(analytics, {
            signed_in: 'true'
          });
      }
    });
  }

  render() {
    return (
      <BrowserRouter>
        <UserContext.Provider value={this.state.currentAuthUser}>
          <NavBar
            isSignedIn={this.state.isSignedIn}
            currentUserData={this.state.currentUserData}
            currentAuthUser={this.state.currentAuthUser}
          />
          <Switch>
            <Route
              path="/"
              render={(props) => (
                <AiHome
                  {...props}
                  currentAuthUser={this.state.currentAuthUser}
                  currentUserData={this.state.currentUserData}
                  isSignedIn={this.state.isSignedIn}
                />
              )}
              exact
            />
            <Route
              path="/home"
              render={(props) => (
                <AiHome
                  {...props}
                  currentAuthUser={this.state.currentAuthUser}
                  currentUserData={this.state.currentUserData}
                  isSignedIn={this.state.isSignedIn}
                />
              )}
            />
            <Route path="/login" component={Login} />
            <Route
              path="/trip/:id"
              render={(props) => (
                <TripView
                  {...props}
                  currentAuthUserId={this.state.currentAuthUser?.uid}
                  currentUserData={this.state.currentUserData}
                />
              )}
            />
            <Route
              path="/searchResults/:searchValue"
              component={SearchResultsPage}
            />
            <Route
              path="/user/:uid"
              render={(props) => (
                <ProfileContainer
                  {...props}
                  currentAuthUser={this.state.currentAuthUser}
                  currentUserData={this.state.currentUserData}
                />
              )}
            />
            <Route path="/editProfile/:uid" component={EditProfileView} />
            <Route path="/trip/" component={NoTripExistsError} />
            <Route component={Error} />
          </Switch>
        </UserContext.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
