import React, { Component, useContext } from "react";
import Modal from "react-bootstrap/Modal";

import ReviewItem from "./reviewItem";

function ReviewModal(props) {
  return (
    <Modal {...props} centered size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ padding: "0" }}>
        {props.reviews.map((review) => (
          <ReviewItem review={review} />
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default ReviewModal;
