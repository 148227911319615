import React, { Component, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getIdToken,
  sendPasswordResetEmail,
  getAdditionalUserInfo,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  collection,
  updateDoc,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import EditProfileView from "./../profilePage/editProfileView"; // KEEP THIS Line, not sure why but without this it breaks!
import LoadingSpinner from "../sharedComponents/loadingSpinner";
import { isInputAlreadyTaken, isInputAlreadyTakenAsync } from "./util";
import { v4 as uuidv4 } from "uuid";
import "./LoginModal.css";
import googleLogo from "../../assets/btn_google_light_normal_ios@2x.png";
import { analytics, app, auth, db, functions } from "../../index";
import { logEvent } from "@firebase/analytics";
class LoginModal extends Component {
  state = {
    isEmailInUseState: false,
    isUsernameInUseState: false,
    isThirdPartyUsernameInUseState: false,
    email: "",
    password: "",
    username: "",
    thirdPartyUsername: "",
    signup: true,
    signInError: "",
    loading: false,
    notCalledThirdPartyAuth: true,
    resetPassword: false,
  };

  constructor(props) {
    super(props);
  }

  handlerUsernameInput = (username) => {
    this.setState({ username: username });
    isInputAlreadyTaken("username", username, (inUse) =>
      this.setState({ isUsernameInUseState: inUse }),
    );
  };

  handlerUsernameInputThirdParty = (username) => {
    this.setState({ thirdPartyUsername: username });
    isInputAlreadyTaken("username", username, (inUse) =>
      this.setState({ isthirdPartyUsernameInUseState: inUse }),
    );
  };

  handlerEmailInput = (email) => {
    this.setState({ email: email });
    isInputAlreadyTaken("email", email, (inUse) =>
      this.setState({ isEmailInUseState: inUse }),
    );
  };

  submitNewUser = () => {
    this.setState({ loading: true });
    // handle race condition
    createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then((user) => {
        logEvent(analytics, "submit_new_user", {
          email: this.state.email,
        });
        this.attachTemporaryTripToNewUser();
        this.updateUsername(
          user.user.uid,
          user.user,
          this.state.username
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, " ", errorMessage);
        this.setState({ signInError: errorMessage });
      });
  };

  signIn = () => {
    this.setState({ loading: true });
    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then((userCredential) => {
        // Signed in
        logEvent(analytics, "sign_in", {
          email: this.state.email,
        });
        this.attachTemporaryTripToNewUser();
        this.props.history.push("/home");
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, " ", errorMessage);
        this.setState({ signInError: errorMessage });
      });
  };

  attachTemporaryTripToNewUser = () => {
    const tempTripId = localStorage.getItem("temp-trip-id");
    if (tempTripId) {
      const tripRef = doc(db, "trips", tempTripId);
      updateDoc(tripRef, {
        creatorId: auth.currentUser.uid,
        users: [auth.currentUser.uid],
        tempTrip: false,
      });
      localStorage.removeItem("temp-trip-id");
    }
  };

  redirectToEditProfilePage = (username) => {
    getDocs(
      query(collection(db, "userProfiles"), where("username", "==", username)),
    )
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.props.history.push(
            "/editProfile/".concat(auth.currentUser.uid.toString()),
          );
          this.setState({ loading: false });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error getting documents: ", error);
      });
  };

  updateUsername = (uid, user, username) => {
    onSnapshot(doc(db, 'userProfiles', uid), (doc) => {
      getIdToken(user, true)
        .then((token) => {
          var updateUsername = httpsCallable(
            functions,
            'updateUsername'
          );
          updateUsername({
            uid: token,
            username: username,
          }).then((result) => {
            console.log(
              'Update username status: ',
              result.data.status
            );
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        });
    });
  };

  signInWithProvider = async (provider) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        this.setState({ loading: true });
        var user = result.user;
        const { isNewUser } = getAdditionalUserInfo(result);
        if (isNewUser) {
          logEvent(analytics, "submit_new_user", {
            email: this.state.email,
          });
          this.updateUsername(
            user.uid,
            user,
            user.email.split('@')[0]
          );
        } else {
          logEvent(analytics, "sign_in", {
            email: this.state.email,
          });
        }
        this.attachTemporaryTripToNewUser();
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  resetPassword = () => {
    this.setState({ loading: true });
    sendPasswordResetEmail(auth, this.state.email)
      .then(() => {
        // Password reset email sent!
        this.setState({ loading: false, resetPassword: true });
      })
      .catch((error) => {
        this.setState({ loading: false });
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, " ", errorMessage);
        this.setState({ signInError: errorMessage });
      });
  };

  render() {
    const tempTripId = localStorage.getItem("temp-trip-id");
    return (
      <LoadingSpinner loading={this.state.loading}>
        <div className="LoginModal--form--flex--box">
          <div className="LoginModal--exit" onClick={this.props.onHide}>
            X
          </div>
          <div className="LoginModal--signup--container">
            <div className="LoginModal--header--container">
              {tempTripId
                ? "Create an account while we generate your trip!"
                : "Sign up and start planning your next adventure!"}
            </div>
            {this.state.signup && (
              <>
                <input
                  onChange={(event) =>
                    this.handlerUsernameInput(event.target.value)
                  }
                  placeholder={"Username"}
                  className="LoginModal--input"
                ></input>
              </>
            )}
            {this.state.isUsernameInUseState && (
              <div>This is already in use.</div>
            )}
            <input
              onChange={(event) => this.handlerEmailInput(event.target.value)}
              placeholder={"Email"}
              className="LoginModal--input"
              type="email"
            ></input>
            {this.state.signup && this.state.isEmailInUseState && (
              <div>This is already in use.</div>
            )}
            <input
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              placeholder={"Password"}
              className="LoginModal--input"
              type="password"
            ></input>
            {this.state.signInError ? (
              <div>{this.state.signInError}</div>
            ) : null}
            {this.state.resetPassword ? (
              <div>Password reset email sent! Please check your email.</div>
            ) : null}
            <button
              className="LoginModal--login--buttons"
              onClick={this.state.signup ? this.submitNewUser : this.signIn}
              disabled={
                this.state.signup &&
                (this.state.isEmailInUseState ||
                  this.state.isUsernameInUseState ||
                  !this.state.username)
              }
            >
              {this.state.signup ? <div>Sign up</div> : <div>Sign in</div>}
            </button>
            <button
              className="google-auth-button"
              onClick={() => this.signInWithProvider(new GoogleAuthProvider())}
            >
              <img src={googleLogo} alt="Google logo" className="google-logo" />
              {this.state.signup ? (
                <div>Sign up with Google</div>
              ) : (
                <div>Sign in with Google</div>
              )}
            </button>
            <div className="LoginModal--prompt--container">
              <div className="LoginModal--prompt">
                {this.state.signup ? (
                  <div>Already have an account?</div>
                ) : (
                  <div>Create an account?</div>
                )}
              </div>
              <div
                className="LoginModal--prompt--button"
                onClick={() => this.setState({ signup: !this.state.signup })}
              >
                {" "}
                {this.state.signup ? <div>Log in</div> : <div>Sign up</div>}
              </div>
            </div>
            <br />
            {!this.state.signup && (
              <p
                className="LoginModal--forgot-password-link"
                onClick={this.resetPassword}
              >
                Forgot password?
              </p>
            )}
          </div>
        </div>
      </LoadingSpinner>
    );
  }
}

export default LoginModal;
