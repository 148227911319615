import React from 'react';
import loginImage from '../../../../assets/login.jpeg';
import './signUpToolTip.css';
import { isMobile } from '../../../../utils/mobile-check';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../../../../index';

const SignUpToolTip = (props) => {
  if (props.isSignedIn || !props.show) {
    return null;
  }

  return (
    <div className="SignUpToolTip">
      <div style={{ flex: 1 }}>
        <div>Create an account to save your trip!</div>
        <button
          className="SignUpToolTip--button"
          onClick={() => {
            logEvent(analytics, 'sign_up_tool_tip_click', {
              isMobile: isMobile(),
            });
            props.signUp();
          }}
        >
          Sign Up
        </button>
      </div>
      <img
        className="SignUpToolTip--login-image"
        alt="login image"
        src={loginImage}
        style={{ height: '100px', width: '150px' }}
      />

      <div
        className="SignUpToolTip--exit"
        onClick={() => {
          logEvent(analytics, 'sign_up_tool_tip_dismiss', {
            isMobile: isMobile(),
          });
          props.onHide();
        }}
      >
        X
      </div>
    </div>
  );
};

export default SignUpToolTip;
