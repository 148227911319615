import { doc, getDoc } from "firebase/firestore";
import { db } from "../index";

export const getTrip = async (tripId) => {
  const docRef = doc(db, "trips", tripId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // handle the case where the document does not exist
    console.log("No such document!");
  }
};
