import React, { Component, useContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

class LoadingSpinner extends Component {
  override = css`
    position: relative;
    top: 40vh;
    left: 45vw;
  `;

  render() {
    return !this.props.loading ? (
      <React.Fragment>{this.props.children}</React.Fragment>
    ) : (
      <ClipLoader
        color={"#000000"}
        css={this.override}
        loading={true}
        size={50}
      />
    );
  }
}

export default LoadingSpinner;
