import React, { useState, useEffect } from "react";
import { db } from "../../index";
import defaultTripImage from "../../assets/defaultTripImage.jpeg";
import "./searchDropdownTripCard.css";
import { NavLink } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const SearchDropdownTripCard = (props) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [creatorUsername, setCreatorUsername] = useState("");

  const getPlaceCardImage = (photos) => {
    const tripCardAspectRatio = 1;
    let bestAspectRatioDiff = 10000;
    let bestPhoto;
    photos.forEach((photo) => {
      const currAspectRatioDiff = Math.abs(
        tripCardAspectRatio - photo.width / photo.height
      );
      if (currAspectRatioDiff < bestAspectRatioDiff) {
        bestPhoto = photo;
        bestAspectRatioDiff = currAspectRatioDiff;
      }
    });
    console.log("about to set best photo ", bestPhoto.getUrl());
    setPhotoUrl(bestPhoto.getUrl());
  };

  const callback = (place, status) => {
    console.log("callback, place: ", place, "status: ", status);
    if (place && place.photos) {
      getPlaceCardImage(place.photos);
    }
  };

  const googlePlaceDetailsRequest = (placeID) => {
    var request = {
      placeId: placeID,
      fields: [
        "photos",
      ],
    };

    if (!window.google) {
      throw new Error(
        "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    var service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails(request, callback);
  };

  useEffect(() => {
    // get trip details for photos
    googlePlaceDetailsRequest(props.trip.placeId);
  
    // get user details for username of trip creator
    const userRef = doc(db, "userProfiles", props.trip.creatorId);
    getDoc(userRef)
      .then((user) => {
        if (user.exists()) {
          setCreatorUsername(user.data().username);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting user:", error);
      });
  }, []);

  return (
    <NavLink className="searchTripCardWrapper" to={"/trip/" + props.trip.key}>
      <div style={{ paddingRight: "4%" }}>
        <img
          className="searchTripCardImage"
          alt="profile picture"
          src={photoUrl ? photoUrl : defaultTripImage}
          style={{ height: "50px", width: "50px" }}
        />
      </div>
      <div>
        <div className="tripCardName">{props.trip.displayName}</div>
        <div className="searchTripCardSubtitle">by {creatorUsername}</div>
      </div>
    </NavLink>
  );
};

export default SearchDropdownTripCard;
