import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./marker";
import { googlePlaceDetailsRequest } from "../../apiRequests/googlePlaceDetailsRequest";

class ReactMap extends Component {
  state = {
    location: {},
    map: undefined,
  };

  static defaultProps = {
    defLocation: {
      lat: 42.3601,
      lng: -71.0589,
    },
    zoom: 12,
    days: [],
  };

  componentDidUpdate(prevProps) {
    if(
      this.props.location && 
      (!prevProps.location || this.props.location._lat !== prevProps.location._lat || this.props.location._long !== prevProps.location._long)
    ) {
      googlePlaceDetailsRequest(this.props.placeId, this.placeDetailsCallback);
    }
    if (
      this.props.location && 
      (!prevProps.location || this.props.location._lat !== prevProps.location._lat || this.props.location._long !== prevProps.location._long)
    ) {
      this.setState({
        location: {
          lat: this.props.location._lat,
          lng: this.props.location._long,
        },
      });
    }
  }

  apiIsLoaded = (map, maps) => {
    this.props.updateBounds(map.getBounds());
    this.setState({ map: map });
    this.props.updateMap(map);
  };

  calculateAndDisplayRoute = (directionsService, directionsRenderer) => {
    directionsService.route(
      {
        origin: { lat: 37.77, lng: -122.447 },
        destination: { lat: 37.768, lng: -122.511 },
        // Note that Javascript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        // @ts-ignore
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status == "OK") {
          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  };

  changeMap() {
    if (this.state.map) {
      this.props.updateBounds(this.state.map.getBounds());
      this.props.updateMap(this.state.map);
    }
  }

  placeDetailsCallback = (place, status) => {
    console.log('reactMap placeDetailsCallback, status: ',status);
    this.setState({ placeDetails: place, name: place?.name });
    if (place?.geometry) {
      this.setState({
        bounds: place.geometry.viewport,
      });
      if (place.geometry.viewport)
        if (this.state.map) this.state.map.fitBounds(this.state.bounds);
      console.log("trying to fit bounds", this.state.bounds);
    }
  };

  componentDidMount() {
    if (this.props.placeId) {
      googlePlaceDetailsRequest(this.props.placeId, this.placeDetailsCallback);
    }
    if (this.props.location) {
      this.setState({
        location: {
          lat: this.props.location._lat,
          lng: this.props.location._long,
        },
      });
    }
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB4-wq3RuMC2K62ZWFd35ykxHFgtb87oiQ" }}
          defaultCenter={this.props.defLocation}
          center={this.state.location}
          bounds={this.state.bounds}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
          onChange={({ map }) => this.changeMap(map)}
        >
          {this.props.days.map((day) =>
            day?.places?.map((place) => (
              <Marker
                key={place.locationData.placeId}
                placeId={place.locationData.placeId}
                lat={place.locationData.geoLoc._lat}
                lng={place.locationData.geoLoc._long}
                text="X"
                hoveredCell={this.props.hoveredCell}
                onClick={this.props.onMarkerClick}
                onMarkerMouseEnter={this.props.onMarkerMouseEnter}
                onMarkerMouseLeave={this.props.onMarkerMouseLeave}
              />
            ))
          )}
          {this.props.starredPlaces?.map((place) => (
            <Marker
              key={place.locationData.placeId}
              placeId={place.locationData.placeId}
              isStarred={true}
              lat={place.locationData.geoLoc._lat}
              lng={place.locationData.geoLoc._long}
              text="★"
              hoveredCell={this.props.hoveredCell}
              onClick={this.props.onMarkerClick}
              onMarkerMouseEnter={this.props.onMarkerMouseEnter}
              onMarkerMouseLeave={this.props.onMarkerMouseLeave}
            />
          ))}
          {this.props.searchMarkers?.map((place, index) => (
            <Marker
              key={place.id}
              index={index}
              placeId={place.placeId}
              lat={place.lat}
              lng={place.lng}
              noGooglePlaceId={place.noGooglePlaceId}
              name={place.name}
              text="H"
              hoveredCell={this.props.hoveredCell}
              onClick={this.props.onMarkerClick}
              onMarkerMouseEnter={this.props.onMarkerMouseEnter}
              onMarkerMouseLeave={this.props.onMarkerMouseLeave}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}
export default ReactMap;
