import React, { Component } from "react";
import { db } from "../index";
import { NavLink } from "react-router-dom";
import defaultProfPic from "../assets/default_prof_pic.jpg";
import firebase from 'firebase/compat/app';

class SearchResultsPage extends Component {
  state = {
    searchResults: [],
  };

  componentDidMount() {
    const { searchValue } = this.props.match.params;
    firebase.firestore().collection("userProfiles")
      .get()
      .then((querySnapshot) => {
        let searchResults = [];
        querySnapshot.forEach(function (doc) {
          console.log("query snap for each");
          // doc.data() is never undefined for query doc snapshots
          // If search applies to this user, add to search Results
          if (
            (doc.data().username &&
              doc
                .data()
                .username.toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            doc.id.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            searchResults.push({
              uid: doc.id,
              username: doc.data().username,
              photoURL: doc.data().photoURL,
            });
          }
        });
        this.setState({ searchResults });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
  

  render() {
    return (
      <div>
        {this.state.searchResults.map((result) => (
          <NavLink key={result.uid} to={"/user/" + result.uid}>
            <div>
              <h4>{result.username}</h4>
              <div
                className="rounded-circle"
                height="75px"
                width="75px"
                style={{ height: "75px" }}
              >
                <img
                  alt="profile picture"
                  src={[result.photoURL, defaultProfPic]}
                  style={{ height: "75px" }}
                  className="rounded-circle"
                />
              </div>
            </div>
          </NavLink>
        ))}
      </div>
    );
  }
}

export default SearchResultsPage;
