import React, { useState } from "react";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import "./placeRouteConnection.css";

const PlaceRouteConnection = ({
  routeLineClicked = () => {},
}) => {
  return (
    <div className="PlaceRouteConnection">
      <div
        className="PlaceRouteConnection--directionsButton"
        style={{ width: "100%" }}
      >
        <DefaultButton
          onClick={() => routeLineClicked()}
          style={{ width: "100%" }}
        >
          <div className="PlaceRouteConnection--routeDots"></div>
        </DefaultButton>
      </div>
    </div>
  );
};

export default PlaceRouteConnection;
