import React, { Component } from "react";
import SearchPlaceCell from "./searchPlaceCell";

class SearchList extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  onCellMouseEnter = (placeId) => {
    this.props.onCellMouseEnter(placeId);
  };

  onCellMouseLeave = (placeId) => {
    this.props.onCellMouseLeave(placeId);
  };

  render() {
    return (
      <div className="FixedHeightContainer" style={{ padding: "5px" }}>
        <div className="Content" style={{ overflow: "auto" }}>
          <table className="table table-hover">
            <tbody>
              {this.props.places.map((place, index) => (
                <SearchPlaceCell
                  key={place.placeId}
                  placeId={place.placeId}
                  name={place.name}
                  rating={place.rating}
                  ratingsTotal={place.ratingsTotal}
                  photoUrl={place.photoUrl}
                  place={place}
                  className="container"
                  onMouseEnter={this.onCellMouseEnter}
                  onMouseLeave={this.onCellMouseLeave}
                  onClick={this.props.onCellClick}
                  noGooglePlaceId={place.noGooglePlaceId}
                  index={index}
                  latlong={place.latlong}
                ></SearchPlaceCell>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SearchList;
