import { db } from "../../index";
import { collection, doc, query, where, getDocs, getDoc } from "firebase/firestore";
import defaultProfPic from "../../assets/default_prof_pic.jpg";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const isInputAlreadyTaken = (field, input, stateCallBack) => {
  const q = query(collection(db, "userProfiles"), where(field, "==", input));

  getDocs(q)
    .then((querySnapshot) => {
      stateCallBack(querySnapshot.docs.length !== 0);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
};

export const isInputAlreadyTakenAsync = async (field, input) => {
  const q = query(collection(db, "userProfiles"), where(field, "==", input));

  return getDocs(q)
    .then((querySnapshot) => {
      return querySnapshot.docs.length !== 0;
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
};

export const formatImageFileName = (uuid) => {
  const imageFilePrefix = "profile-images/";
  const imageFileSuffix = ".jpg";
  const imageFileName = imageFilePrefix.concat(uuid).concat(imageFileSuffix);
  return imageFileName;
};

export const getUserProfile = (uid) => {
  return getDoc(doc(db, "userProfiles", uid))
    .then((document) => {
      return document.data();
    });
};

export const downloadImage = async (profileImageRef, setStateCallback) => {
  const storage = getStorage();
  try {
    const url = await getDownloadURL(ref(storage, profileImageRef));
    setStateCallback(url);
  } catch (error) {
    // Handle any errors
    console.log('Error downloading image:', error);
  }
};

export const getProfileImage = (user, setStateCallback) => {
  if (user?.profilePictureUuid) {
    const imageFileName = formatImageFileName(user.profilePictureUuid);
    downloadImage(imageFileName, setStateCallback);
  } else if (user?.photoURL) {
    setStateCallback(user.photoURL);
  } else {
    setStateCallback(defaultProfPic);
  }
};
