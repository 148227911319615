import React, { useEffect, useState } from "react";

import "./progressMeter.css";

const ProgressMeter = ({ percentFilled, star }) => {
  const [averageRating, setAverageRating] = useState(0);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ color: "grey" }}>{star}</div>
      <div className="ProgressMeter--container">
        <div className="ProgressMeter--meter" />
        <div
          className="ProgressMeter--progress--line"
          style={{ width: `${percentFilled * 100}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressMeter;
