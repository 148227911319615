// google places service
var service = undefined;

var GOOGLE_MAPS_API_KEY = 'AIzaSyB4-wq3RuMC2K62ZWFd35ykxHFgtb87oiQ';

export function googlePlaceDetailsRequest(placeID, callback) {
  var request = {
    placeId: placeID,
    fields: [
      "name",
      "rating",
      "user_ratings_total",
      "geometry",
      "photos",
      "types",
      "vicinity",
      "url",
      "review",
    ],
  };

  if (!window.google) {
    throw new Error(
      "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!window.google.maps.places) {
    throw new Error(
      "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!service) {
    service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }

  return service.getDetails(request, callback);
}

export function googlePlaceDetailsRequestForPhotos(placeID, callback) {
  var request = {
    placeId: placeID,
    fields: [
      "photos",
    ],
  };

  if (!window.google) {
    throw new Error(
      "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!window.google.maps.places) {
    throw new Error(
      "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!service) {
    service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }

  return service.getDetails(request, callback);
}

export function googlePlaceSearchRequest(request, callback) {
  if (!window.google) {
    throw new Error(
      "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!window.google.maps.places) {
    throw new Error(
      "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!service) {
    service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }

  return service.findPlaceFromQuery(request, callback);
}

export function googlePlacesNearbySearchRequest(request, callback) {
  if (!window.google) {
    throw new Error(
      "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!window.google.maps.places) {
    throw new Error(
      "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!service) {
    service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }

  return service.nearbySearch(request, callback);
}

export function googlePlacesTextSearchRequest(request, callback) {
  if (!window.google) {
    throw new Error(
      "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!window.google.maps.places) {
    throw new Error(
      "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
    );
  }

  if (!service) {
    service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }

  return service.textSearch(request, callback);
}
