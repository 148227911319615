import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
} from "react-dates";

class DatePicker extends Component {
  state = {
    focusedInput: null,
    startDateEvent: null,
    endDateEvent: null,
  };

  constructor(props) {
    super(props);
  }

  onDatesChange = (startDate, endDate) => {
    this.props.onDatesChange(startDate, endDate);
    this.setState({
      startDateEvent: startDate,
      endDateEvent: endDate,
    });
  };

  isOutsideRange = (day) => {
    return (
      isInclusivelyAfterDay(day, this.props.endDate) ||
      isInclusivelyBeforeDay(day, this.props.startDate)
    );
  };

  render() {
    return (
      <div>
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={
            this.state.startDateEvent
              ? this.state.startDateEvent
              : this.props.startDate
          }
          endDate={
            this.state.endDateEvent
              ? this.state.endDateEvent
              : this.props.endDate
          }
          minDate={this.props.startDate}
          maxDate={this.props.endDate}
          isOutsideRange={this.isOutsideRange}
          onDatesChange={({ startDate, endDate }) => {
            this.onDatesChange(startDate, endDate);
          }}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => {
            this.setState({ focusedInput });
          }}
          numberOfMonths={1}
          openDirection={this.props.openDirection ?? "up"}
          {...this.props.datePickerProps}
        />
      </div>
    );
  }
}

export default DatePicker;
