import React, { useEffect, useState } from "react";

const ShareSearchDropdown = (props) => {
  const [count, setCount] = useState([]);
  useEffect(() => {}, [props.searchResults]); // similar to component did receive props

  return (
    <div
      style={{
        position: "absolute",
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
        padding: "12px 8px",
        textAlign: "center",
        zIndex: "1000",
        backgroundColor: "white",
        marginTop: "38px",
      }}
    >
      {props.searchResults.map((result) => (
        <div
          className="dropdown-item"
          onClick={() => props.handleShareWithClick(result.uid)}
          key={result.uid}
        >
          {result.username + " "}
          <input
            type="radio"
            readOnly={true}
            checked={props.sharedWith.includes(result.uid)}
            value="share"
          />
        </div>
      ))}
    </div>
  );
};
export default ShareSearchDropdown;
