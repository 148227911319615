import React from "react";
import "./routeCard.css";
import clsx from "clsx";

const RouteCard = ({
  route = {},
  index = 0,
  routeDetailsClicked,
  isDetailsPressed = false,
  isActiveRoute = false,
  routeCardClicked = () => {},
}) => {
  const detailsClicked = () => {
    if (isDetailsPressed) {
      routeDetailsClicked({}, -1);
    } else {
      routeDetailsClicked(route, index);
    }
  };

  return (
    <div
      className={clsx("RouteCard", isActiveRoute && "RouteCard-active")}
      onClick={() => routeCardClicked(route, index)}
    >
      <tr>
        <th scope="row">
          <h5>{route.legs[0].duration.text}</h5>
          <div>
            {route.legs[0].distance.text} • {route.summary}
          </div>
          <button onClick={detailsClicked} className="detailsButton">
            <span>
              Details
              <span className={isDetailsPressed && "detailsArrowDown"}> »</span>
            </span>
          </button>
        </th>
      </tr>
    </div>
  );
};

export default RouteCard;
