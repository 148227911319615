export const getBestAspectPhoto = (photos, aspectRatio) => {
  let bestAspectRatioDiff = 10000;
  let bestPhoto;
  photos.forEach((photo) => {
    const currAspectRatioDiff = Math.abs(
      aspectRatio - photo.width / photo.height
    );
    if (currAspectRatioDiff < bestAspectRatioDiff) {
      bestPhoto = photo;
      bestAspectRatioDiff = currAspectRatioDiff;
    }
  });
  return bestPhoto;
};

export default getBestAspectPhoto;
