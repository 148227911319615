import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import "./editPlaceModal.css";
import TextareaAutosize from "react-autosize-textarea";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import AddToCalDatePicker from "../addToCalDatePicker";

const EditPlaceModal = (props) => {
  const { currentAuthUserId } = props;
  const [notesValue, setNotesValue] = useState(
    props.notes && props.notes[currentAuthUserId] || ""
  );
  const [date, setDate] = useState();

  const notesValueChanged = (e) => {
    setNotesValue(e.target.value);
  };

  const handleSave = () => {
    var tempNotes = props.notes;
    tempNotes[currentAuthUserId] = notesValue;
    if (notesValue == "") delete tempNotes[currentAuthUserId];
    props.savePlace(tempNotes, date);
  };

  const dateSelected = (date) => {
    setDate(date);
  };

  return (
    <Modal
      {...props}
      size="med"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="EditPlaceModal--DatePicker">
          <AddToCalDatePicker
            startDate={props.startDate}
            endDate={props.endDate}
            onDateChange={dateSelected}
            openDirection={"down"}
          />
        </div>
        <TimePicker
          showSecond={false}
          defaultValue={
            props.time?.toDate &&
            moment.utc()
              .hour(props.time.toDate().getUTCHours())
              .minute(props.time.toDate().getUTCMinutes())
          }
          value={
            props.time?.toDate &&
            moment.utc()
              .hour(props.time.toDate().getUTCHours())
              .minute(props.time.toDate().getUTCMinutes())
          }
          className="xxx"
          onChange={props.onTimeChange}
          format="h:mm a"
          use12Hours
          inputReadOnly
          allowEmpty={false}
        />
        <div className="EditPlaceModal--notesDiv">
          <TextareaAutosize
            className="EditPlaceModal--notesTextArea"
            placeholder="Add a note"
            value={notesValue}
            onChange={notesValueChanged}
          />
        </div>
        <DefaultButton onClick={props.deletePlaceClicked}>
          <div className="EditPlaceModal--deleteButton">Delete place</div>
        </DefaultButton>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPlaceModal;
