import React, { useState } from "react";
import DefaultButton from "../sharedComponents/defaultButton/defaultButton";
import { ReactComponent as MapSVG } from "./../../assets/icons/map_white_24dp.svg";
import { ReactComponent as ListSVG } from "./../../assets/icons/list_white_24dp.svg";
import './mapToggle.css';

const MapToggle = ({togglePressed = () => {}, mapToggled = false}) => {
  return (
    <DefaultButton onClick={togglePressed}>
        <div className="MapToggle">
          {mapToggled ? 
            ('List ') : 
            ('Map ')
          }
          {mapToggled ? 
            (<ListSVG />) : 
            (<MapSVG />)
          }
        </div>
    </DefaultButton>
  );
};

export default MapToggle;
