import { geocodeByAddress } from "react-places-autocomplete";

const EXAMPLE_RESPONSE_JSON = {
    "days": [
        {
            "date": new Date(1687966800 * 1000),
            "key": 0,
            "location": {
                "_lat": 33.6188829,
                "_long": -117.9298493
            },
            "places": [
                {
                    "key": "testkey1",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.618917,
                            "_long": -117.929802
                        },
                        "name": "The Beachcomber Cafe",
                        "address": "15 Crystal Cove, Newport Beach, CA 92657"
                    },
                    "notes": {},
                    "time": new Date(1687988400 * 1000),
                    "type": "restaurant"
                },
                {
                    "key": "testkey2",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.618010,
                            "_long": -117.929615
                        },
                        "name": "Newport Beach Pier",
                        "address": "70 Newport Pier, Newport Beach, CA 92663"
                    },
                    "notes": {},
                    "time": new Date(1688006400 * 1000),
                    "type": "landmark"
                }
            ]
        },
        {
            "date": new Date(1688053200 * 1000),
            "key": 1,
            "location": {
                "_lat": 33.6188829,
                "_long": -117.9298493
            },
            "places": [
                {
                    "key": "testkey3",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.601733,
                            "_long": -117.899598
                        },
                        "name": "Fashion Island",
                        "address": "401 Newport Center Dr, Newport Beach, CA 92660"
                    },
                    "notes": {},
                    "time": new Date(1688074800 * 1000),
                    "type": "shopping"
                },
                {
                    "key": "testkey4",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.618243,
                            "_long": -117.929176
                        },
                        "name": "Blue Beet",
                        "address": "107 21st Pl, Newport Beach, CA 92663"
                    },
                    "notes": {},
                    "time": new Date(1688100000 * 1000),
                    "type": "bar"
                }
            ]
        },
        {
            "date": new Date(1688139600 * 1000),
            "key": 2,
            "location": {
                "_lat": 33.6188829,
                "_long": -117.9298493
            },
            "places": [
                {
                    "key": "testkey5",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.606867,
                            "_long": -117.929802
                        },
                        "name": "Crystal Cove State Park",
                        "address": "8471 N Coast Hwy, Laguna Beach, CA 92651"
                    },
                    "notes": {},
                    "time": new Date(1688161200 * 1000),
                    "type": "park"
                },
                {
                    "key": "testkey6",
                    "locationData": {
                        "geoLoc": {
                            "_lat": 33.615127,
                            "_long": -117.930516
                        },
                        "name": "Wild Taco",
                        "address": "407 31st St, Newport Beach, CA 92663"
                    },
                    "notes": {},
                    "time": new Date(1688182800 * 1000),
                    "type": "restaurant"
                }
            ]
        }
    ]
}



// get PlaceIds from google places API and add to openAIResponseJSON
// for each day in openAIResponseJSON go through each place and request place details from google places API

const fetch = require('node-fetch');

// Your Google Places API Key
const GOOGLE_MAPS_API_KEY = 'AIzaSyB4-wq3RuMC2K62ZWFd35ykxHFgtb87oiQ';

// google places service
var googlePlacesService = undefined;

export async function enrichJsonWithPlaceIds(jsonData) {
    if(!jsonData) {
        jsonData = EXAMPLE_RESPONSE_JSON; 
    }
    if (!googlePlacesService) {
        googlePlacesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
        );
    }

    for (let day of jsonData.days) {
        for (let place of day.places) {
            const placeName = place.locationData.name;
            const address = place.locationData.address;
            const lat = place.locationData.geoLoc._lat;
            const lng = place.locationData.geoLoc._long;
            place.notes = {}

            await new Promise((resolve, reject) => {
                googlePlacesService.findPlaceFromQuery(
                    {
                        locationBias: { lat, lng },
                        query: placeName,
                        fields: ["place_id", "geometry"],
                    },
                    (results, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            if (results && results[0]) {
                                place.locationData.placeId = results[0].place_id;
                                place.locationData.geoLoc._lat = results[0].geometry.location.lat();
                                place.locationData.geoLoc._long = results[0].geometry.location.lng();
                                console.log('placeId and geoLoc added to place: ', place.locationData.placeId, place.locationData.geoLoc);
                            }
                        } else {
                            console.error(`Failed to fetch placeId for ${placeName}: ${status}`);
                        }
                        resolve();
                    }
                );
            });
        }
    }
    console.log('jsonData enriched with placeIds and geoLocs: ', jsonData);
    return jsonData;
}

function ensureDatesAreDateObjects(jsonData) {
    // Iterate over days
    jsonData.days.forEach(day => {
        // Check if date field is not a Date object and if so, convert it
        if (!(day.date instanceof Date)) {
            day.date = new Date(day.date);
        }

        // Do the same for time in each place
        day.places.forEach(place => {
            if (!(place.time instanceof Date)) {
                place.time = new Date(place.time);
            }
        });
    });

    return jsonData;
}

const CLOUD_RUN_URL = 'https://tripclubexpress-g3wnhwuyqq-uw.a.run.app';
const LOCAL_URL = 'http://localhost:8080';

export var NODE_URL = CLOUD_RUN_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
    NODE_URL = LOCAL_URL;
}

export async function createRecommendedTrip(location, dates, notes, newTripRefId) {
    // Call express JS function /createAITrip to get the recommended trip
    const response = await fetch(NODE_URL+'/createAITrip', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            location: location,
            dates: dates,
            notes: notes,
            newTripRefId: newTripRefId,
        }),
    });
    const json = await response.json();
    console.log('Response from /createAITrip: ', json);

    console.log('post date processing json: ', json);
    return json;
}

export async function createRecommendedDay(location, date, notes) {
    const response = await fetch(NODE_URL+"/createAIDay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        location: location,
        date,
        notes: notes,
      }),
    });
    const json = await response.json();
    const enrichedData = await enrichJsonWithPlaceIds(json);
    return enrichedData;
}
