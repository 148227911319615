import React, { useState } from "react";
import "./addPlacesComponent.css";
import DefaultButton from "../../sharedComponents/defaultButton/defaultButton";
import AddPlacesModal from "./addPlacesModal";
import { createRecommendedDay, enrichJsonWithPlaceIds } from "../../../apiRequests/openAIRequests/openAIDataManager";
import PlaceCell from "../placeCell/placeCell";
import { v4 as uuidv4 } from "uuid";
import { googlePlaceDetailsRequest } from "../../../apiRequests/googlePlaceDetailsRequest";

const AddPlacesComponent = ({ trip, date, addLocationToDate }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [places, setPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [locationMap, setLocationMap] = useState({});
  const [queryTries, setQueryTries] = useState(0);
  const [loading, setLoading] = useState(false);

  const placeDetailsCallback = (place, status, placeId) => {
    if (status == "OVER_QUERY_LIMIT" && queryTries < 3) {
      // retry after 2 seconds to see if it's just the short term query limit
      setQueryTries(queryTries + 1);
      setTimeout(() => {
        console.log("retrying placeDetailsRequest in placeDetailsView");
        googlePlaceDetailsRequest(placeId, (place, status) =>
          placeDetailsCallback(place, status, placeId),
        );
      }, 2000);
    } else if (place) {
      locationMap[placeId] = place;
    }
    console.error(
      "Didnt receive place from placeDetailsCallback, status: ",
      status,
    );
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    const enrichedData = await createRecommendedDay(trip.location, date, inputValue);

    const places = enrichedData.days[0].places;
    const placeDetailsRequests = [];
    places.forEach((fetchedPlace) => {
      placeDetailsRequests.push(
        googlePlaceDetailsRequest(
          fetchedPlace.locationData.placeId,
          (place, status) =>
            placeDetailsCallback(
              place,
              status,
              fetchedPlace.locationData.placeId,
            ),
        ),
      );
    });

    await Promise.all(placeDetailsRequests);

    setPlaces(places);
    setInputValue("");
    setLoading(false);
  };

  const handleAddPlaces = async (event) => {
    event.preventDefault();
    const addLocationRequests = [];
    selectedPlaces.forEach((place) => {
      addLocationRequests.push(
        addLocationToDate(
          place.time,
          place.locationData.placeId,
          locationMap[place.locationData.placeId].geometry.location,
          place.locationData.name,
          place.type,
          null,
          0,
          uuidv4(),
          place.description,
        ),
      );
    });
    await Promise.all(addLocationRequests);
    setShowModal(false);
    resetState();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const resetState = () => {
    setPlaces([]);
    setLocationMap({});
    setSelectedPlaces([]);
  };

  const selectNewPlace = (place) => {
    if (!selectedPlaces.includes(place)) {
      setSelectedPlaces([...selectedPlaces, place]);
    } else {
      setSelectedPlaces(
        selectedPlaces.filter((selectedPlace) => selectedPlace !== place),
      );
    }
  };

  return (
    <>
      <AddPlacesModal
        show={showModal}
        onHide={() => {
          setShowModal(!showModal);
          resetState();
        }}
        type={loading ? "Finding places..." : "Add Places"}
        children={
          loading ? (
            <div className="AddPlacesModal--loading-screen">
              <div className="AddPlacesModal--spinner"></div>
            </div>
          ) : (
            <form onSubmit={places.length > 0 ? handleAddPlaces : handleSearch}>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  maxHeight: "401px",
                  overflow: "auto",
                }}
              >
                <h6 className="tr-font">
                  {places.length > 0
                    ? "Select the places you want to add."
                    : "What do you want to add to this day?"}
                </h6>

                {places.length === 0 && (
                  <textarea
                    className="AddPlacesModal--string-input"
                    name="notes"
                    placeholder="I want to go to a coffee shop and then visit a museum."
                    value={inputValue}
                    onChange={handleChange}
                  />
                )}
                {places?.map((place, index) => (
                  <div
                    key={place.locationData.placeId}
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="AddPlacesModal--checkbox-container">
                      <input
                        type="checkbox"
                        className="AddPlacesModal--checkbox-input"
                        onChange={() => selectNewPlace(place)}
                        checked={selectedPlaces.includes(place)}
                      />
                    </div>

                    <PlaceCell
                      key={place.key}
                      hash={place.key}
                      placeId={place.locationData?.placeId}
                      index={index}
                      time={place.time}
                      notes={place.notes}
                      type={place.type}
                      name={place.locationData?.name}
                      description={place.description}
                      className="container"
                      address={place.locationData.address}
                      onMouseEnter={() => null}
                      onMouseLeave={() => null}
                      onClick={(placeId) => {
                        const place = places.find(
                          (place) => place.locationData.placeId === placeId,
                        );
                        selectNewPlace(place);
                      }}
                      setPlaceTime={() => null}
                      hoveredCell={() => null}
                      creatorName={place.creatorName}
                      creatorId={place.creatorId}
                      deletePlaceClicked={() => null}
                      updatePlaceClicked={() => null}
                      startDate={new Date()}
                      endDate={new Date()}
                      date={new Date()}
                      currentAuthUserId={() => null}
                      isCurrentUserOnTrip={() => null}
                      hideEdit={true}
                    />
                  </div>
                ))}
              </div>
              <div className="AddPlacesModal--button-container">
                <button
                  className="AddPlacesModal--submit-button"
                  type="submit"
                  disabled={places.length > 0 && selectedPlaces.length === 0}
                >
                  {places.length > 0
                    ? selectedPlaces.length > 0
                      ? `Add (${selectedPlaces.length})`
                      : "Add"
                    : "Search"}
                </button>
              </div>
            </form>
          )
        }
      />
      <DefaultButton onClick={() => setShowModal(!showModal)}>
        <div className="addPlacesButton">+ Add places</div>
      </DefaultButton>
    </>
  );
};

export default AddPlacesComponent;
