import React, { Component } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  getIdToken,
  signInWithPopup,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  collection,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import EditProfileView from "./../profilePage/editProfileView";
import LoadingSpinner from "../sharedComponents/loadingSpinner";
import { isInputAlreadyTaken, isInputAlreadyTakenAsync } from "./util";
import { v4 as uuidv4 } from "uuid";
import hawaii from "../../assets/hawaii.jpg";
import italy from "../../assets/italy.jpg";
import oldwest from "../../assets/old-west.jpg";
import "./login.css";
import { analytics, app, auth, db, functions } from "../../index";
import firebase from "firebase/compat/app";
import { logEvent } from "@firebase/analytics";
class Login extends Component {
  state = {
    isEmailInUseState: false,
    isUsernameInUseState: false,
    isThirdPartyUsernameInUseState: false,
    email: "",
    password: "",
    username: "",
    thirdPartyUsername: "",
    signup: true,
    signInError: "",
    loading: false,
    notCalledThirdPartyAuth: true,
    heroImage: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const heroImages = [italy, hawaii, oldwest];
    const index = Math.floor(Math.random() * heroImages.length);
    this.setState({ heroImage: heroImages[index] });
  }

  handlerUsernameInput = (username) => {
    this.setState({ username: username });
    isInputAlreadyTaken("username", username, (inUse) =>
      this.setState({ isUsernameInUseState: inUse }),
    );
  };

  handlerUsernameInputThirdParty = (username) => {
    this.setState({ thirdPartyUsername: username });
    isInputAlreadyTaken("username", username, (inUse) =>
      this.setState({ isthirdPartyUsernameInUseState: inUse }),
    );
  };

  handlerEmailInput = (email) => {
    this.setState({ email: email });
    isInputAlreadyTaken("email", email, (inUse) =>
      this.setState({ isEmailInUseState: inUse }),
    );
  };

  submitNewUser = async () => {
    this.setState({ loading: true });
    // handle race condition
    const auth = getAuth();
    await createUserWithEmailAndPassword(
      auth,
      this.state.email,
      this.state.password,
    )
      .then((user) => {
        logEvent(analytics, "submit_new_user", {
          email: this.state.email,
        });
        this.attachTemporaryTripToNewUser();
        console.log("user: ", user);
        onSnapshot(doc(db, "userProfiles", user.user.uid), (doc) => {
          getIdToken(user.user, true)
            .then((token) => {
              var userRef = firebase
                .firestore()
                .doc(`userProfiles/${user.user.uid}`);
              userRef
                .update({
                  username: this.state.username,
                })
                .then((result) => {
                  console.log("Update username successful!");
                  this.redirectToEditProfilePage(this.state.username);
                });
            })
            .catch(function (err) {
              console.error(err);
              this.setState({ loading: false });
            });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, " ", errorMessage);
        this.setState({ signInError: errorMessage });
      });
  };

  signIn = () => {
    this.setState({ loading: true });
    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then((userCredential) => {
        // Signed in
        logEvent(analytics, "sign_in", {
          email: this.state.email,
        });
        this.attachTemporaryTripToNewUser();
        this.props.history.push("/home");
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, " ", errorMessage);
        this.setState({ signInError: errorMessage });
      });
  };

  redirectToEditProfilePage = (username) => {
    getDocs(
      query(collection(db, "userProfiles"), where("username", "==", username)),
    )
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.props.history.push(
            "/editProfile/".concat(auth.currentUser.uid.toString()),
          );
          this.setState({ loading: false });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error getting documents: ", error);
      });
  };

  attachTemporaryTripToNewUser = () => {
    const tempTripId = localStorage.getItem("temp-trip-id");
    if (tempTripId) {
      const tripRef = doc(db, "trips", tempTripId);
      updateDoc(tripRef, {
        creatorId: auth.currentUser.uid,
        users: [auth.currentUser.uid],
        tempTrip: false,
      });
      localStorage.removeItem("temp-trip-id");
    }
  };

  signInWithProvider = async (provider) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        this.setState({ loading: true });
        this.attachTemporaryTripToNewUser();
        var user = result.user;
        const isNewUser =
          Math.abs(user.metadata.createdAt - user.metadata.lastLoginAt) < 100;
        if (isNewUser) {
          logEvent(analytics, "submit_new_user", {
            email: this.state.email,
          });
        } else {
          logEvent(analytics, "sign_in", {
            email: this.state.email,
          });
        }
        onSnapshot(doc(db, "userProfiles", user.uid), async (docSnapshot) => {
          const userProfile = docSnapshot.data();
          if (this.state.notCalledThirdPartyAuth) {
            // ...
          }
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, " ", errorMessage);
      });
  };

  render() {
    return (
      <LoadingSpinner loading={this.state.loading}>
        <div className="Login--image--container">
          <img style={{ width: "100%" }} src={this.state.heroImage} />
        </div>
        <div className="Login--form--flex--box">
          <div className="Login--absolute--container">
            <div className="Login--signup--container">
              <div className="Login--header--container">
                Sign up and start planning your next adventure!
              </div>
              {this.state.signup && (
                <>
                  <input
                    onChange={(event) =>
                      this.handlerUsernameInput(event.target.value)
                    }
                    placeholder={"Username"}
                    className="Login--input"
                  ></input>
                </>
              )}
              {this.state.isUsernameInUseState && (
                <div>This is already in use.</div>
              )}
              <input
                onChange={(event) => this.handlerEmailInput(event.target.value)}
                placeholder={"Email"}
                className="Login--input"
                type="email"
              ></input>
              {this.state.signup && this.state.isEmailInUseState && (
                <div>This is already in use.</div>
              )}
              <input
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
                placeholder={"Password"}
                className="Login--input"
                type="password"
              ></input>
              {this.state.signInError ? (
                <div>{this.state.signInError}</div>
              ) : null}
              <button
                className="Login--login--buttons"
                onClick={this.state.signup ? this.submitNewUser : this.signIn}
                disabled={
                  this.state.signup &&
                  (this.state.isEmailInUseState ||
                    this.state.isUsernameInUseState ||
                    !this.state.username)
                }
              >
                {this.state.signup ? <div>Sign up</div> : <div>Sign in</div>}
              </button>
              <button
                className="Login--login--buttons"
                onClick={() =>
                  this.signInWithProvider(new GoogleAuthProvider())
                }
              >
                {this.state.signup ? (
                  <div>Sign up with Google</div>
                ) : (
                  <div>Sign in with Google</div>
                )}
              </button>
              <div className="Login--prompt--container">
                <div className="Login--prompt">
                  {this.state.signup ? (
                    <div>Already have an account?</div>
                  ) : (
                    <div>Create an account?</div>
                  )}
                </div>
                <div
                  className="Login--prompt--button"
                  onClick={() => this.setState({ signup: !this.state.signup })}
                >
                  {" "}
                  {this.state.signup ? <div>Log in</div> : <div>Sign up</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingSpinner>
    );
  }
}

export default Login;
