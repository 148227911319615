import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import DatePicker from "./../datePicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "./generateTripForm.css";
import { isMobile } from "../../utils/mobile-check";

class GenerateTripForm extends Component {
  state = {
    address: "",
    placeId: "",
    startDate: undefined,
    endDate: undefined,
  };

  constructor(props) {
    super(props);
  }

  datePickerDatesChanged = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    this.setState({ address, placeId });
  };

  render() {
    return (
      <div
        className={
          isMobile()
            ? "MobileGenerateTripForm-search-container"
            : "GenerateTripForm-search-container"
        }
      >
        <form onSubmit={this.props.createTrip} style={{ textAlign: "center" }}>
          <h2
            className={"GenerateTripForm-heading"}
            style={{ textAlign: "center", color: "black" }}
          >
            Plan your next trip <br />
            with our AI concierge.
          </h2>
          <div className="GenerateTripForm-subheading">
            <h6 className="tr-font">Location</h6>
          </div>
          <PlacesAutocomplete
            value={this.state.address}
            placeId={this.state.placeId}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    name: "locationSearch",
                    placeholder: "Where are you going?",
                    className: "location-search-input",
                  })}
                  className={
                    isMobile()
                      ? "MobileGenerateTripForm-place-input"
                      : "GenerateTripForm-place-input"
                  }
                />
                <div
                  className={
                    isMobile()
                      ? "MobileGenerateTripForm-autocomplete-dropdown-container"
                      : "GenerateTripForm-autocomplete-dropdown-container"
                  }
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#e6f9ff",
                          cursor: "pointer",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.id}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div className="GenerateTripForm-subheading-dates">
            <h6 className="tr-font">First Day</h6>
            <h6 className="tr-font" style={{ paddingLeft: "145px" }}>
              Last Day
            </h6>
          </div>
          <div style={{ textAlign: "center" }}>
            <DatePicker
              startDate={moment()}
              onDatesChange={this.datePickerDatesChanged}
              openDirection={isMobile() ? "down" : "up"}
            />
          </div>
          <div className="GenerateTripForm-subheading">
            <h6 className="tr-font">Special Requests</h6>
          </div>
          <div style={{ textAlign: "center", paddingBottom: "34px" }}>
            <textarea
              className={
                isMobile()
                  ? "MobileGenerateTripForm-additionalNotes-input"
                  : "GenerateTripForm-additionalNotes-input"
              }
              name="notes"
              placeholder="Example: Trip for a family of 3 with outdoor activities"
            />
          </div>
          <div style={{ textAlign: isMobile() ? "middle" : "right" }}>
            <button className="tr-font btn GenerateTripForm-trip-button">
              Generate Trip
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default GenerateTripForm;
