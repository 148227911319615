import React from "react";
import { ReactComponent as TripOriginSVG } from "./../../../assets/trip_origin-24px.svg";

const MiniPlaceCell = ({ index, locationData }) => {
  return (
    <div index={index}>
      <TripOriginSVG style={{ fill: "blue" }} /> {locationData.name}
    </div>
  );
};

export default MiniPlaceCell;
